import React, { useEffect, useState } from 'react';
import '../../assets/css/Calendar.css';
import { Button, Grid, Header, Icon, Loader, Segment, Tab } from 'semantic-ui-react';
import { getCalendar } from '../../services/Tvshow';
import CalendarTable from './CalendarTable';

function CalendarTab({ setSearchInput, focusSearchInput, handleTabChange, setOpenMediaScheduler }) {
  const [state, setState] = useState({
    calendarContent: { later: [], missed: [], soon: [], today: [] },
    loading: true,
    error: false,
  });

  const getCalendarContent = () => {
    setState((prevState) => ({ ...prevState, loading: true }));
    getCalendar()
      .then((response) => {
        const today = new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        );
        const nextWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 8);
        if (response.status === 200 && response.data.success)
          setState({
            calendarContent: {
              later: response.data.result.filter((i) => {
                const airDate = new Date(i.airDateUtc);
                return airDate > nextWeek;
              }),
              missed: response.data.result.filter((i) => {
                const airDate = new Date(i.airDateUtc);
                return airDate < today;
              }),
              soon: response.data.result.filter((i) => {
                const airDate = new Date(i.airDateUtc);
                return (
                  airDate.getFullYear() === today.getFullYear() &&
                  airDate.getMonth() === today.getMonth() &&
                  airDate.getDate() > today.getDate() &&
                  airDate < nextWeek
                );
              }),
              today: response.data.result.filter((i) => {
                const airDate = new Date(i.airDateUtc);
                return (
                  airDate.getFullYear() === today.getFullYear() &&
                  airDate.getMonth() === today.getMonth() &&
                  airDate.getDate() === today.getDate()
                );
              }),
            },
            loading: false,
            error: false,
          });
        else {
          setState({
            calendarContent: {
              later: [],
              missed: [],
              soon: [],
              today: [],
            },
            loading: false,
            error: true,
          });
        }
      })
      .catch(() => {
        setState({
          calendarContent: { later: [], missed: [], soon: [], today: [] },
          loading: false,
          error: true,
        });
      });
  };

  useEffect(() => {
    getCalendarContent();
  }, []);

  return (
    <>
      <Tab.Pane className="row content transparent" attached="top">
        {!state.loading && state.error && (
          <Segment textAlign="center" placeholder>
            <Header icon>
              <Icon name="exclamation triangle" color="orange" size="big" />
              Calendrier indisponible
            </Header>
            Une erreur est survenue lors du chargement du calendrier
            <br />
            <br />
            <Button onClick={() => getCalendarContent()} color="teal">
              <Icon name="refresh" />
              Réessayer
            </Button>
          </Segment>
        )}
        {state.loading && <Loader active />}
        {!state.loading && !state.error && (
          <CalendarTable
            items={[
              state.calendarContent.missed,
              state.calendarContent.today,
              state.calendarContent.soon,
              state.calendarContent.later,
            ]}
            error={state.error}
            setSearchInput={setSearchInput}
            focusSearchInput={focusSearchInput}
            handleTabChange={handleTabChange}
          />
        )}
      </Tab.Pane>

      {!state.loading && (
        <Grid padded>
          <Grid.Column textAlign="center">
            <Button
              size="small"
              color="green"
              icon="plus"
              label="Ajouter une série au calendrier"
              labelPosition="left"
              onClick={() => setOpenMediaScheduler(true)}
            />
          </Grid.Column>
        </Grid>
      )}
    </>
  );
}

export default CalendarTab;
