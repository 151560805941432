import React from 'react';
import {
  Header,
  Placeholder,
  PlaceholderLine,
  PlaceholderParagraph,
  Popup,
} from 'semantic-ui-react';
import { getSubscribers } from '../../services/Tvshow';
import { notifError } from '../Tools/NotificationToasts';

function SubscriptionIndexerPopup({ show, mobile }) {
  const [data, setData] = React.useState(null);

  const fetchSubscribers = (indexerId) => {
    getSubscribers(indexerId)
      .then((response) => {
        if (response.status === 200 && response.data.success) setData(response.data.result);
        else {
          notifError('Impossible de récupérer les abonnés');
          setData(null);
        }
      })
      .catch(() => {
        notifError('Impossible de récupérer les abonnés');
        setData(null);
      });
  };

  return (
    <Popup
      onOpen={() => {
        if (data === null && show.follower_count > 0) fetchSubscribers(show.tvdbId);
      }}
      popperDependencies={[!!data]}
      trigger={
        <span className="clickable">
          {mobile
            ? (show.follower_count > 0 ? show.follower_count : 'Aucun') +
              (show.follower_count > 1 ? ' abonnés' : ' abonné')
            : show.follower_count > 0
              ? show.follower_count
              : '-'}
        </span>
      }
      wide
      position="right center"
    >
      {data === null ? (
        <>
          <span>
            {show.follower_count} {show.follower_count > 1 ? 'Abonnés' : 'Abonné'}
          </span>
          {show.follower_count > 0 && (
            <>
              <br />
              <br />
              <Placeholder style={{ minWidth: '200px' }}>
                <PlaceholderParagraph>
                  <PlaceholderLine length="short" />
                  <PlaceholderLine length="medium" />
                  <PlaceholderLine length="short" />
                </PlaceholderParagraph>
              </Placeholder>
            </>
          )}
        </>
      ) : (
        <>
          <Header
            as="h2"
            content={`${show.follower_count} ${show.follower_count > 1 ? 'Abonnés' : 'Abonné'}`}
          />
          <p>
            {data.map((sub) => {
              return (
                <>
                  {sub}
                  <br />
                </>
              );
            })}
          </p>
        </>
      )}
    </Popup>
  );
}

export default SubscriptionIndexerPopup;
