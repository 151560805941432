import React, { useEffect } from 'react';
import { Button, Grid, Header, Segment } from 'semantic-ui-react';
import NavBarGuest from '../components/NavBar/NavBarGuest';
import BackgroundRandomizer from '../components/Tools/BackgroundRandomizer';
import { getLocalServerConfig } from '../services/Server';

function BannedPage() {
  const siteName = getLocalServerConfig().siteName || 'Ergo';
  useEffect(() => {
    document.title = `${siteName} | Accès Refusé`;
  }, []);

  return (
    <BackgroundRandomizer>
      <NavBarGuest />
      <main>
        <Grid textAlign="center" style={{ height: '100vh' }} verticalAlign="middle">
          <Grid.Column style={{ maxWidth: 900 }}>
            <Segment className="transparent-table">
              <Header as="h1" color="teal">
                Accès Refusé
              </Header>
              <hr />
              <Header as="h3">
                Votre demande d&apos;accès à {siteName} a été rejetée.
                <br />
                Si vous pensez que c&apos;est une erreur, n&apos;hésitez pas à nous le signaler via
                notre notre formulaire de contact.
                <br />
                <br />
              </Header>
            </Segment>
            <Button
              label="Accueil"
              icon="home"
              labelPosition="right"
              as="a"
              color="teal"
              href="/"
            />
            <Button
              label="Nous Contacter"
              icon="envelope"
              color="blue"
              labelPosition="right"
              as="a"
              href="/contact"
            />
          </Grid.Column>
        </Grid>
      </main>
    </BackgroundRandomizer>
  );
}

export default BannedPage;
