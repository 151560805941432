import React from 'react';
import { Icon, Segment, Table } from 'semantic-ui-react';
import CloudTableHeader from './CloudTableHeader';
import CloudTableRow from './CloudTableRow';

function CloudTable({ cloudContent }) {
  const buildTable = (() => {
    return cloudContent.map((item) => {
      return <CloudTableRow key={`row-${item.hash}`} item={item} />;
    });
  })();

  return cloudContent.length > 0 ? (
    <Table className="transparent-table" unstackable compact selectable>
      <CloudTableHeader />
      <Table.Body>{buildTable}</Table.Body>
    </Table>
  ) : (
    <Segment className="transparent-table" textAlign="center">
      <br />
      <Icon name="info" color="teal" size="big" />
      <br />
      <br />
      Aucun téléchargement en cours
      <br />
      <br />
    </Segment>
  );
}

export default CloudTable;
