import '../../assets/css/Search.css';
import React, { createRef, useMemo } from 'react';
import { useTable, useSortBy, usePagination } from 'react-table';
import { Icon, Label, Pagination, Table } from 'semantic-ui-react';
import { Tooltip } from 'react-tooltip';
import Highlighter from 'react-highlight-words';
import TimeAgo from 'react-timeago';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
import { Media } from '../Tools/AppMedia';
import { humanFileSize } from '../Tools/Utils';
import frenchStrings from '../Tools/TimeAgoFrStrings';
import SearchTableLinkButton from './SearchTableLinkButton';
import SearchTableDownloadButton from './SearchTableDownloadButton';

const formatter = buildFormatter(frenchStrings);

function STCDownloadButtonComponent(prop) {
  return <SearchTableDownloadButton item={prop.cell.row.original} />;
}

function STHResult(prop, terms) {
  return (
    <div style={{ textAlign: 'left' }} className="table-header-sortable">
      {`Résultats pour "${terms}" (${prop.rows.length})`}
      {prop.column.isSorted ? (
        prop.column.isSortedDesc ? (
          <Icon name="sort down" />
        ) : (
          <Icon name="sort up" />
        )
      ) : (
        ''
      )}
    </div>
  );
}

function STCResult(prop, terms) {
  return (
    <div
      style={{ textAlign: 'left' }}
      className={
        prop.cell.row.original.seeders === 0 ? 'text-muted font-weight-light' : 'font-weight-normal'
      }
    >
      <Highlighter
        searchWords={terms
          .normalize('NFD')
          .replace(/\p{Diacritic}/gu, '')
          .split(' ')}
        autoEscape
        textToHighlight={prop.cell.value}
      />
    </div>
  );
}

function STCLink(prop) {
  return <SearchTableLinkButton item={prop.cell.row.original} />;
}

function STHSite(prop) {
  return (
    <div style={{ textAlign: 'center' }} className="table-header-sortable">
      Site
      {prop.column.isSorted ? (
        prop.column.isSortedDesc ? (
          <Icon name="sort down" />
        ) : (
          <Icon name="sort up" />
        )
      ) : (
        ''
      )}
    </div>
  );
}

function STCSite(prop) {
  return (
    <div style={{ textAlign: 'center' }}>
      <Label
        color={prop.cell.row.original.indexerName === 'YGGtorrent' ? 'yellow' : 'teal'}
        size="tiny"
      >
        {prop.cell.value}
      </Label>
    </div>
  );
}

function STHPublication(prop) {
  return (
    <div style={{ textAlign: 'center' }} className="table-header-sortable">
      <Icon name="calendar alternate outline" />
      {prop.column.isSorted ? (
        prop.column.isSortedDesc ? (
          <Icon name="sort down" />
        ) : (
          <Icon name="sort up" />
        )
      ) : (
        ''
      )}
    </div>
  );
}

function STCPublication(prop) {
  return (
    <div style={{ textAlign: 'center' }}>
      <TimeAgo date={prop.cell.value} formatter={formatter} />
    </div>
  );
}

function STHSize(prop) {
  return (
    <div style={{ textAlign: 'right' }} className="table-header-sortable">
      Taille
      {prop.column.isSorted ? (
        prop.column.isSortedDesc ? (
          <Icon name="sort down" />
        ) : (
          <Icon name="sort up" />
        )
      ) : (
        ''
      )}
    </div>
  );
}

function STCSize(prop) {
  return (
    <div className="font-weight-bolder" style={{ textAlign: 'right' }}>
      {prop.cell.value !== 0 ? humanFileSize(prop.cell.value) : 'Inconnue'}
    </div>
  );
}

function STHSeeders(prop) {
  return (
    <div style={{ textAlign: 'center' }} className="table-header-sortable">
      Sources
      {prop.column.isSorted ? (
        prop.column.isSortedDesc ? (
          <Icon name="sort down" />
        ) : (
          <Icon name="sort up" />
        )
      ) : (
        ''
      )}
    </div>
  );
}

function STCSeeders(prop) {
  return (
    <div className="text-success font-weight-bolder" style={{ textAlign: 'center' }}>
      {prop.cell.value}
    </div>
  );
}

function STHLeechers(prop) {
  return (
    <div style={{ textAlign: 'center' }} className="table-header-sortable">
      Leechers
      {prop.column.isSorted ? (
        prop.column.isSortedDesc ? (
          <Icon name="sort down" />
        ) : (
          <Icon name="sort up" />
        )
      ) : (
        ''
      )}
    </div>
  );
}

function STCLeechers(prop) {
  return (
    <div className="text-danger" style={{ textAlign: 'center' }}>
      {prop.cell.value}
    </div>
  );
}

function SearchTable({ terms, data }) {
  const topRef = createRef();
  const columns = useMemo(
    () => [
      {
        Header: (
          <div style={{ textAlign: 'center' }}>
            <Icon name="download" />
          </div>
        ),
        accessor: 'indexerId',
        disableSortBy: true,
        Cell: STCDownloadButtonComponent,
      },
      {
        Header: (prop) => STHResult(prop, terms),
        accessor: 'title',
        sortType: 'basic',
        className: 'text-muted font-weight-light',
        Cell: (prop) => STCResult(prop, terms),
      },
      {
        Header: (
          <div style={{ textAlign: 'center' }}>
            <Icon name="linkify" />
          </div>
        ),
        accessor: 'link',
        disableSortBy: true,
        Cell: STCLink,
      },
      {
        Header: STHSite,
        accessor: 'indexerName',
        sortType: 'basic',
        Cell: STCSite,
      },
      {
        Header: STHPublication,
        accessor: 'pubDate',
        sortType: (rowA, rowB, id) => {
          const dateA = Date.parse(rowA.original[id]);
          const dateB = Date.parse(rowB.original[id]);
          if (dateA > dateB) return -1;
          if (dateB > dateA) return 1;
          return 0;
        },
        Cell: STCPublication,
      },
      {
        Header: STHSize,
        accessor: 'size',
        sortType: 'basic',
        Cell: STCSize,
      },
      {
        Header: STHSeeders,
        accessor: 'seeders',
        sortType: 'basic',
        Cell: STCSeeders,
      },
      {
        Header: STHLeechers,
        accessor: 'leechers',
        sortType: 'basic',
        Cell: STCLeechers,
      },
    ],
    [terms]
  );

  const scrollTop = () => {
    if (topRef.current) topRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const searchData = useMemo(() => data, [data]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    pageOptions,
    gotoPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data: searchData,
      initialState: {
        pageSize: 40,
        sortBy: [
          {
            id: 'seeders',
            desc: true,
          },
        ],
      },
    },
    useSortBy,
    usePagination
  );

  function tableMobile() {
    return (
      <Table
        {...getTableProps()}
        className="transparent-table"
        color="teal"
        unstackable
        compact
        selectable
      >
        <Table.Header>
          {headerGroups.map((headerGroup) => (
            <Table.Row {...headerGroup.getHeaderGroupProps()}>
              <Table.HeaderCell>
                <div className="mobile-100-relative">
                  <div className="mobile-10-relative">Trier:</div>
                  <div
                    className="mobile-55-relative"
                    {...headerGroup.headers[1].getHeaderProps(
                      headerGroup.headers[1].getSortByToggleProps()
                    )}
                  >
                    {headerGroup.headers[1].render('Header')}
                  </div>
                  <div
                    className="mobile-10-relative"
                    {...headerGroup.headers[3].getHeaderProps(
                      headerGroup.headers[3].getSortByToggleProps()
                    )}
                  >
                    {headerGroup.headers[3].render('Header')}
                  </div>
                  <div
                    className="mobile-10-relative"
                    {...headerGroup.headers[4].getHeaderProps(
                      headerGroup.headers[4].getSortByToggleProps()
                    )}
                  >
                    {headerGroup.headers[4].render('Header')}
                  </div>
                  <div
                    className="mobile-15-relative"
                    {...headerGroup.headers[6].getHeaderProps(
                      headerGroup.headers[6].getSortByToggleProps()
                    )}
                  >
                    {headerGroup.headers[6].render('Header')}
                  </div>
                </div>
              </Table.HeaderCell>
            </Table.Row>
          ))}
        </Table.Header>
        <Table.Body {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            const id = encodeURI(row.original.link);
            return (
              <Table.Row {...row.getRowProps()}>
                <Table.Cell>
                  <div
                    className={`td-left ${
                      row.cells[6].value === 0
                        ? 'text-muted font-weight-light'
                        : 'font-weight-normal'
                    }`}
                    style={{
                      float: 'left',
                      position: 'relative',
                      width: '100%',
                      wordBreak: 'break-all',
                    }}
                  >
                    <Highlighter
                      searchWords={terms
                        .normalize('NFD')
                        .replace(/\p{Diacritic}/gu, '')
                        .split(' ')}
                      autoEscape
                      textToHighlight={row.cells[1].value}
                    />
                    {row.original.link.startsWith('http') && (
                      <>
                        <a
                          id={`search-item-external-link-${id}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`https://dereferer.me/?${row.original.link}`}
                        >
                          {' '}
                          <Icon name="external" color="teal" />
                        </a>
                        <Tooltip
                          anchorId={`search-item-external-link-${id}`}
                          style={{ zIndex: 1 }}
                          place="top"
                          content={`Description du torrent sur ${row.original.indexerName} (prudence)`}
                        />
                      </>
                    )}
                  </div>
                  <div style={{ float: 'left', position: 'relative', width: '100%' }}>
                    <div style={{ float: 'left', position: 'relative', width: '80%' }}>
                      <div style={{ float: 'left', position: 'relative', width: '100%' }}>
                        <div style={{ float: 'left', position: 'relative', width: '50%' }}>
                          S:{' '}
                          <span className="text-success font-weight-bolder">
                            {row.cells[6].value}
                          </span>{' '}
                          | L: <span className="text-danger">{row.cells[7].value}</span>
                        </div>
                        <div
                          className="td-right"
                          style={{ float: 'left', position: 'relative', width: '50%' }}
                        >
                          <Label
                            color={row.cells[3].value === 'YGGtorrent' ? 'yellow' : 'teal'}
                            size="tiny"
                          >
                            {row.cells[3].value.substring(0, 15)}
                          </Label>
                        </div>
                      </div>
                      <div style={{ float: 'left', position: 'relative', width: '100%' }}>
                        <div
                          className="td-left"
                          style={{ float: 'left', position: 'relative', width: '50%' }}
                        >
                          <TimeAgo date={row.cells[4].value} formatter={formatter} />
                        </div>
                        <div
                          className="td-right font-weight-bolder"
                          style={{ float: 'left', position: 'relative', width: '50%' }}
                        >
                          {row.cells[5].value !== 0
                            ? humanFileSize(row.cells[5].value)
                            : 'Inconnue'}
                        </div>
                      </div>
                    </div>
                    <div
                      className="td-right"
                      style={{ float: 'left', position: 'relative', width: '20%' }}
                    >
                      <SearchTableDownloadButton item={row.original} responsive />
                    </div>
                  </div>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    );
  }

  function tableDesktop() {
    return (
      <Table
        {...getTableProps()}
        className="transparent-table"
        color="teal"
        unstackable
        compact
        selectable
      >
        <Table.Header>
          {headerGroups.map((headerGroup) => (
            <Table.Row {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Table.HeaderCell {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          ))}
        </Table.Header>
        <Table.Body {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <Table.Row {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return <Table.Cell {...cell.getCellProps()}>{cell.render('Cell')}</Table.Cell>;
                })}
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    );
  }

  return (
    <>
      <span ref={topRef} />
      <Media greaterThanOrEqual="lg">
        {(mediaClassNames, renderChildren) => {
          return renderChildren ? tableDesktop() : null;
        }}
      </Media>
      <Media lessThan="lg">
        {(mediaClassNames, renderChildren) => {
          return renderChildren ? tableMobile() : null;
        }}
      </Media>
      {pageOptions.length > 1 && (
        <Pagination
          totalPages={pageOptions.length}
          activePage={pageIndex + 1}
          onPageChange={(e, { activePage }) => {
            gotoPage(activePage - 1);
            scrollTop();
          }}
        />
      )}
    </>
  );
}

export default SearchTable;
