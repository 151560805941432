import React from 'react';
import { Icon, Loader, Segment, Table } from 'semantic-ui-react';
import { deviceTaskAction } from '../../services/Devices';
import { notifSuccess, notifError } from '../Tools/NotificationToasts';
import DeviceTableHeader from './DeviceTableHeader';
import FreeboxTableRow from './FreeboxTableRow';
import SynologyTableRow from './SynologyTableRow';

function DeviceTable({ device, tasksContent, triggered, error }) {
  const handleTaskAction = (deviceId, taskId, action) => {
    deviceTaskAction({ device_id: deviceId, task_id: taskId, action })
      .then((response) => {
        if (response.status === 200 && response.data.success) notifSuccess('Action effectuée');
        else notifError("Échec de l'action");
      })
      .catch(() => {
        notifError(`Échec de l'action ${action}`);
      });
  };

  const buildTable = tasksContent.map((i) => {
    if (device.type === 'freebox')
      return (
        <FreeboxTableRow
          key={`freebox-row-${device.id}-${i.id}`}
          item={i}
          device={device}
          handleTaskAction={handleTaskAction}
        />
      );
    return (
      <SynologyTableRow
        key={`synology-row-${device.id}-${i.id}`}
        item={i}
        device={device}
        handleTaskAction={handleTaskAction}
      />
    );
  });

  return (
    <>
      {!triggered ? (
        <Loader active />
      ) : (
        !error && (
          <>
            {tasksContent.length === 0 && (
              <Segment className="td-center transparent-bg">
                <Icon name="info circle" color="teal" size="big" />
                <br />
                <br />
                Aucun téléchargement en cours
              </Segment>
            )}
            {tasksContent.length > 0 && (
              <Table className="transparent-table" unstackable compact selectable>
                <DeviceTableHeader device={device} />
                <Table.Body>{buildTable}</Table.Body>
              </Table>
            )}
          </>
        )
      )}
      {error && (
        <Segment className="transparent-table" textAlign="center">
          <br />
          <Icon name="exclamation triangle" color="orange" size="big" />
          <br />
          <br />
          Impossible de récupérer le contenu de {device.name} pour le moment.
          <br />
          Nouvelle tentative...
          <br />
          <br />
        </Segment>
      )}
    </>
  );
}

export default DeviceTable;
