Object.defineProperty(exports, '__esModule', {
  value: true,
});

// French
const strings = {
  // environ ~= about, it's optional
  prefixAgo: 'il y a',
  prefixFromNow: '-',
  seconds: '1 min',
  minute: '1 min',
  minutes: '%d min',
  hour: '1h',
  hours: '%d h',
  day: '1 j',
  days: '%d j',
  month: '1 mois',
  months: '%d mois',
  year: '1 an',
  years: '%d ans',
};
exports.default = strings;
