import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Header, Icon, Input, Modal } from 'semantic-ui-react';
import { Base64 } from 'js-base64';
import { serverMkdir } from '../../services/Server';
import { notifError, notifSuccess, notifWarning } from '../Tools/NotificationToasts';

function MkdirModal({ setOpen, open, currentDirectory, directoryCallback }) {
  const [folderName, setFolderName] = useState('');

  const inputRef = useRef(null);
  useEffect(() => {
    if (inputRef.current !== null) inputRef.current.focus();
  });

  const handleMkdir = () => {
    if (folderName !== '') {
      const path = `${currentDirectory === '/' ? '' : currentDirectory}/${folderName}`;
      serverMkdir({ path: Base64.encode(encodeURIComponent(path.trim()), true) })
        .then((response) => {
          if (response.status === 200 && response.data.success) {
            setOpen(false);
            setFolderName('');
            directoryCallback();
            notifSuccess('Dossier créé');
          } else notifError('Échec de la création du dossier');
        })
        .catch((e) => {
          notifError(`Échec de la création du dossier: ${e}`);
        });
    } else {
      notifWarning('Action annulée');
      setOpen(false);
    }
  };

  return (
    <Modal
      basic
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="small"
    >
      <Header icon>
        <Icon name="folder open outline" color="teal" />
        <br />
        Créer un nouveau dossier
      </Header>
      <Modal.Content>
        Entrez le nom du dossier ci-dessous:
        <br />
        <br />
        <Form>
          <Input
            ref={inputRef}
            value={folderName}
            placeholder="Nouveau dossier"
            fluid
            maxLength="100"
            onChange={(event) => setFolderName(event.target.value)}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button basic color="grey" inverted onClick={() => setOpen(false)}>
          <Icon name="remove" /> Annuler
        </Button>
        <Button
          color="green"
          inverted
          onClick={() => {
            handleMkdir();
            setOpen(false);
          }}
        >
          <Icon name="folder open outline" /> Créer
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default MkdirModal;
