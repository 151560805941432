import React from 'react';
import { Button, Icon, Table } from 'semantic-ui-react';
import { Tooltip } from 'react-tooltip';
import { Link } from 'react-router-dom';
import { updateUserRole } from '../../services/Settings';
import { notifError, notifSuccess } from '../Tools/NotificationToasts';
import { notifyUserGranted } from '../../services/Server';
import { getUserId } from '../../services/Auth';

function UsersTabRow({ getUsers, user }) {
  const handleUpdateUserRole = (userId, role, nickname) => {
    updateUserRole({ user_id: userId, role })
      .then((response) => {
        if (response.status === 200 && response.data.success)
          notifSuccess(
            `Rôle de ${nickname} mis à ${
              role === -1
                ? 'Bloqué'
                : role === 0
                  ? 'En attente validation'
                  : role === 1
                    ? 'Utilisateur'
                    : 'Administrateur'
            }`
          );
        else notifError('Échec de modification du rôle');
      })
      .catch((e) => {
        notifError(`Échec de modification du rôle: ${e.message}`);
      })
      .finally(() => getUsers());
  };

  const handleNotifyUserGranted = (userId, nickname) => {
    notifyUserGranted({ user_id: userId })
      .then((response) => {
        if (response.status === 200 && response.data.success)
          notifSuccess(`Email envoyé avec succès à ${nickname}`);
        else notifError("Échec d'envoi de l'email");
      })
      .catch((e) => {
        notifError(`Échec d'envoi de l'email: ${e.message}`);
      });
  };

  return (
    <Table.Row
      key={user.id}
      className={user.role === -1 ? 'grey-text' : user.role === 0 ? 'teal' : ''}
    >
      <Table.Cell textAlign="center">{user.id}</Table.Cell>
      <Table.Cell>{user.nickname}</Table.Cell>
      <Table.Cell>{user.email}</Table.Cell>
      <Table.Cell>
        {user.role === -1
          ? 'Bloqué'
          : user.role === 0
            ? 'En attente validation'
            : user.role === 1
              ? 'Utilisateur'
              : 'Administrateur'}
        {user.role < 2 && user.id !== getUserId() && (
          <>
            <Icon
              className="clickable"
              id={`rank-up${user.id}`}
              name="angle up"
              color="green"
              onClick={() => handleUpdateUserRole(user.id, user.role + 1, user.nickname)}
            />
            <Tooltip
              anchorId={`rank-up${user.id}`}
              style={{ zIndex: 1 }}
              place="left"
              content="Augmenter les droits"
            />
          </>
        )}
        {user.role > -1 && user.id !== getUserId() && (
          <>
            <Icon
              className="clickable"
              id={`rank-down${user.id}`}
              name="angle down"
              color="red"
              onClick={() => handleUpdateUserRole(user.id, user.role - 1, user.nickname)}
            />
            <Tooltip
              anchorId={`rank-down${user.id}`}
              style={{ zIndex: 1 }}
              place="right"
              content="Diminuer les droits"
            />
          </>
        )}
        {user.role > 0 && (
          <>
            <Icon
              className="clickable"
              id={`email-granted-${user.id}`}
              name="mail"
              color="blue"
              onClick={() => handleNotifyUserGranted(user.id, user.nickname)}
            />
            <Tooltip
              anchorId={`email-granted-${user.id}`}
              style={{ zIndex: 1 }}
              place="right"
              content="Notifier l'utilisateur de son accès"
            />
          </>
        )}
      </Table.Cell>
      <Table.Cell>
        {new Date(user.created_at).toLocaleString('fr-FR', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        })}
      </Table.Cell>
      <Table.Cell>
        {new Date(user.last_login_at).toLocaleString('fr-FR', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        })}
      </Table.Cell>

      <Table.Cell>
        <>
          <Button
            color="teal"
            id={`edit-user-${user.id}`}
            as={Link}
            to={`/settings/users/edit/${user.id}`}
            size="tiny"
          >
            <Icon className="clickable" name="pencil" />
          </Button>
          <Tooltip
            anchorId={`edit-user-${user.id}`}
            style={{ zIndex: 1 }}
            place="top"
            content="Modifier"
          />
        </>
      </Table.Cell>
    </Table.Row>
  );
}

export default UsersTabRow;
