import React, { useState } from 'react';
import { Button, Card, Divider, Grid, Header, Icon, Popup, Rating } from 'semantic-ui-react';
import TimeAgo from 'react-timeago';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
import frenchStrings from '../Tools/TimeAgoFrStrings';
import { cleanDiacritic } from '../Tools/Utils';
import WatchListButton from './WatchListButton';
import DiscoveryCardDimmer from './DiscoveryCardDimmer';
import DiscoveryDetailedCard from './DiscoveryDetailedCard';

const formatter = buildFormatter(frenchStrings);

function DiscoveryCard({
  type,
  media,
  setSearchInput,
  setOpenDiscovery,
  focusSearchInput,
  added,
  watchItems,
  refreshWatchItems,
}) {
  const [openDiscoveryModal, setOpenDiscoveryModal] = useState(false);
  const title = type === 'movie' ? media.title : media.name;
  const originalTitle = type === 'movie' ? media.original_title : media.original_name;
  const releaseDate = type === 'movie' ? media.release_date : media.first_air_date;

  return (
    <Card key={media.id}>
      <DiscoveryCardDimmer
        id={media.id}
        type={type}
        title={title}
        posterPath={media.poster_path}
        watchItems={watchItems}
        refreshWatchItems={refreshWatchItems}
        setOpenDiscoveryModal={setOpenDiscoveryModal}
        detailed={['tv', 'TV', 'SEASON', 'EPISODE'].includes(type)}
      />
      {['tv', 'TV', 'SEASON', 'EPISODE'].includes(type) && (
        <DiscoveryDetailedCard
          mediaType={type}
          tmdbId={media.id}
          open={openDiscoveryModal}
          setOpen={setOpenDiscoveryModal}
          watchItems={watchItems}
          refreshWatchItems={refreshWatchItems}
        />
      )}
      <Card.Content>
        <Popup
          hideOnScroll
          trigger={
            <Card.Header className={originalTitle === title ? '' : 'clickable'}>
              <Grid>
                <Grid.Row centered>{title}</Grid.Row>
              </Grid>
            </Card.Header>
          }
          mouseEnterDelay={250}
          header="Version Originale"
          content={originalTitle}
          position="top center"
          disabled={originalTitle === title}
        />
        <Card.Meta>
          {releaseDate && (
            <Grid>
              <Grid.Row centered>
                <span className="date">
                  {new Date(releaseDate).toLocaleDateString()}
                  {' | '}
                  <TimeAgo date={releaseDate} formatter={formatter} />
                </span>
              </Grid.Row>
            </Grid>
          )}
          <br />
          <Popup
            hideOnScroll
            trigger={
              <Divider fitted horizontal>
                <Rating icon="star" rating={media.vote_average / 2} maxRating={5} disabled />
              </Divider>
            }
            content={`${media.vote_count} votes`}
            position="top center"
          />
        </Card.Meta>
        <Popup
          trigger={
            <Card.Description>
              {media.overview ? (
                <>
                  {media.overview.slice(0, 120)}
                  {media.overview.length > 120 ? (
                    <>
                      {'... '}
                      <Icon name="mouse pointer" size="small" color="grey" />
                    </>
                  ) : (
                    ''
                  )}
                </>
              ) : (
                <Grid>
                  <Grid.Row centered>Aucun résumé disponible</Grid.Row>
                </Grid>
              )}
            </Card.Description>
          }
          mouseEnterDelay={500}
          content={media.overview || 'Aucun résumé disponible'}
          position="top center"
        />
      </Card.Content>
      <Card.Content extra>
        <Grid>
          <Grid.Column floated="left">
            <WatchListButton
              id={media.id}
              media={media}
              title={title}
              originalTitle={originalTitle}
              type={type}
              added={added}
              refreshWatchItems={refreshWatchItems}
            />
          </Grid.Column>
          <Grid.Column floated="left">
            {originalTitle !== title ? (
              <Popup
                hideOnScroll
                trigger={
                  <Button
                    size="mini"
                    color="teal"
                    icon="search"
                    label="Chercher"
                    labelPosition="left"
                  />
                }
                content={
                  <Grid>
                    <Grid.Row centered>
                      <Header
                        as="h3"
                        content="Quel titre utiliser ?"
                        subheader="Dans certains cas le titre original retourne plus de résultats"
                      />
                      <Grid.Row>
                        <Button
                          color="teal"
                          content="Titre Français"
                          onClick={() => {
                            setSearchInput(cleanDiacritic(title));
                            setOpenDiscovery(false);
                            focusSearchInput();
                          }}
                        />
                      </Grid.Row>
                      <Grid.Row>
                        <Divider />
                        <Button
                          color="teal"
                          content="Titre Original"
                          onClick={() => {
                            setSearchInput(cleanDiacritic(originalTitle));
                            setOpenDiscovery(false);
                            focusSearchInput();
                          }}
                        />
                      </Grid.Row>
                    </Grid.Row>
                  </Grid>
                }
                on="click"
                position="top right"
              />
            ) : (
              <Button
                size="mini"
                color="teal"
                icon="search"
                label="Chercher"
                labelPosition="left"
                onClick={() => {
                  setSearchInput(cleanDiacritic(title));
                  setOpenDiscovery(false);
                  focusSearchInput();
                }}
              />
            )}
          </Grid.Column>
        </Grid>
      </Card.Content>
    </Card>
  );
}

export default DiscoveryCard;
