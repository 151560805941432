import React from 'react';
import { Icon, Table } from 'semantic-ui-react';

function WatchListTableHeader({ show = false }) {
  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell className="td-left">
          {show ? (
            <>
              <Icon name="tv" />
              Mes Séries
            </>
          ) : (
            <>
              <Icon name="film" />
              Mes Films
            </>
          )}
        </Table.HeaderCell>
        {show && <Table.HeaderCell className="td-center">Saison / Épisode</Table.HeaderCell>}
        <Table.HeaderCell className="td-center">Ajouté</Table.HeaderCell>
        <Table.HeaderCell className="td-center">Vu</Table.HeaderCell>
        <Table.HeaderCell className="td-center">Récupéré</Table.HeaderCell>
        <Table.HeaderCell className="td-center">
          <Icon name="search" />
        </Table.HeaderCell>
        <Table.HeaderCell className="td-right">
          <Icon name="trash alternate" />
        </Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
}

export default WatchListTableHeader;
