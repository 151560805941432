import React from 'react';
import { Button, Header, Icon, Modal } from 'semantic-ui-react';
import { deleteSearchHistory } from '../../services/Search';
import { notifError, notifSuccess } from '../Tools/NotificationToasts';

function ClearSearchHistoryModal({ setOpen, open, getHistory }) {
  const handleClearHistory = () => {
    deleteSearchHistory()
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          notifSuccess('Historique supprimé');
          getHistory();
        } else notifError(`Échec de suppression de l'historique: ${response.data.message}`);
      })
      .catch((e) => {
        notifError(`Échec de suppression de l'historique: ${e.message}`);
      });
  };

  return (
    <Modal
      basic
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="small"
    >
      <Header icon>
        <Icon name="trash alternate outline" color="red" />
        <br />
        Suppression de l&apos;historique
      </Header>
      <Modal.Content>
        Êtes-vous sûr de vouloir supprimer l&apos;intégralité de votre historique de recherche ?
      </Modal.Content>
      <Modal.Actions>
        <Button basic color="grey" inverted onClick={() => setOpen(false)}>
          <Icon name="remove" /> Annuler
        </Button>
        <Button
          color="red"
          inverted
          onClick={() => {
            handleClearHistory();
            setOpen(false);
          }}
        >
          <Icon name="checkmark" /> Supprimer
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default ClearSearchHistoryModal;
