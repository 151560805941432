import React from 'react';
import { Tab } from 'semantic-ui-react';
import UsersTab from '../Settings/UsersTab';
import DevicesTab from '../Settings/DevicesTab';
import { getLocalServerConfig } from '../../services/Server';
import ServerTab from '../Settings/ServerTab';

function TabBarSettings({ activeIndex, handleTabChange }) {
  const tabs = [];
  tabs.push({
    menuItem: {
      key: 'users',
      icon: 'user',
      content: window.innerWidth > 1210 ? 'Utilisateurs' : '',
    },
    pane: 'users',
    render: () => <UsersTab />,
  });

  if (getLocalServerConfig().devicesEnabled)
    tabs.push({
      menuItem: {
        key: 'devices',
        icon: 'server',
        content: window.innerWidth > 1210 ? 'Appareils' : '',
      },
      pane: 'devices',
      render: () => <DevicesTab />,
    });
  tabs.push({
    menuItem: {
      key: 'server',
      icon: 'hdd',
      content: window.innerWidth > 1210 ? 'Serveur' : '',
    },
    pane: 'server',
    render: () => <ServerTab />,
  });

  return (
    <Tab
      className="row content"
      renderActiveOnly
      menu={{
        attached: 'bottom',
        widths: tabs.length,
        className: 'row footer transparent-table',
      }}
      activeIndex={activeIndex}
      onTabChange={handleTabChange}
      panes={tabs}
    />
  );
}

export default TabBarSettings;
