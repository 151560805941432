import React, { useEffect, useState } from 'react';
import { Button, Icon, Modal } from 'semantic-ui-react';
import { getLocalServerConfig, getServerStatus } from '../../services/Server';

function InterruptedModal() {
  const [open, setOpen] = useState(false);

  const refreshServerStatus = () => {
    getServerStatus()
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          if (open) setOpen(false);
        } else if (!open) setOpen(true);
      })
      .catch(() => {
        if (!open) setOpen(true);
      });
  };

  useEffect(() => {
    const intervalTick = setInterval(() => refreshServerStatus(), 5000);
    return () => {
      clearInterval(intervalTick);
    };
  });

  return (
    <Modal basic size="tiny" open={open} onClose={refreshServerStatus}>
      <Modal.Header>
        <Icon name="redo alternate" loading /> {getLocalServerConfig().siteName || 'Ergo'} est
        injoignable
      </Modal.Header>
      <Modal.Content>
        Les causes probables sont:
        <br />
        <br />
        - surcharge temporaire du serveur (fin d&apos;un téléchargement)
        <br />
        - VPN de protection tous déconnectés
        <br />
        - connexion internet instable / coupée
        <br />
        <br />
        Nouvelle tentative dans 5 secondes...
      </Modal.Content>
      <Modal.Actions>
        <Button
          icon="sync alternate"
          color="orange"
          inverted
          onClick={refreshServerStatus}
          labelPosition="right"
          content="Réessayer"
        />
      </Modal.Actions>
    </Modal>
  );
}

export default InterruptedModal;
