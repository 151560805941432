import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router';
import { ToastContainer } from 'react-toastify';
import TabBarSettings from '../components/TabBar/TabBarSettings';
import NavBarAuth from '../components/NavBar/NavBarAuth';
import { getRole } from '../services/Auth';
import BackgroundRandomizer from '../components/Tools/BackgroundRandomizer';
import InactivityHandler from '../components/Tools/InactivityHandler';
import { getLocalServerConfig } from '../services/Server';

function SettingsPage() {
  const location = useLocation();
  const [state, setState] = useState({
    activeIndex: location.state && location.state.activeIndex === 1 ? 1 : 0,
  });

  useEffect(() => {
    document.title = `${getLocalServerConfig().siteName || 'Ergo'} | Réglages`;
  }, []);

  const handleTabChange = (e, { activeIndex }) =>
    setState((prevState) => ({ ...prevState, activeIndex }));

  return (
    <>
      {getRole() < 2 && <Navigate to="/" />}
      <InactivityHandler>
        <BackgroundRandomizer>
          <NavBarAuth />
          <ToastContainer />
          <TabBarSettings activeIndex={state.activeIndex} handleTabChange={handleTabChange} />
        </BackgroundRandomizer>
      </InactivityHandler>
    </>
  );
}

export default SettingsPage;
