import React from 'react';
import { Dropdown, Icon, Menu, Popup } from 'semantic-ui-react';

function NavBarDownloadInProgress({ downloads, handleTabChange }) {
  const description =
    !downloads || downloads === 0
      ? 'Aucun téléchargement en cours'
      : `${downloads} téléchargement${downloads > 1 ? 's' : ''} en approche`;
  return window.location.pathname !== '/' ? null : (
    <Popup
      content={description}
      position="bottom center"
      trigger={
        <Menu.Item
          onClick={() => {
            handleTabChange('', 'encours');
          }}
        >
          <Icon name="cloud download" color={!downloads || downloads === 0 ? 'grey' : 'teal'} />{' '}
          {!downloads || downloads === 0 ? '' : `${downloads}`}
        </Menu.Item>
      }
    />
  );
}

function NavBarDownloadInProgressMobile({ downloads, handleTabChange }) {
  const description =
    !downloads || downloads === 0
      ? 'Aucun téléchargement en cours'
      : `${downloads} téléchargement${downloads > 1 ? 's' : ''} en approche`;
  return window.location.pathname !== '/' ? null : (
    <Dropdown.Item
      onClick={() => {
        handleTabChange('', 'encours');
      }}
    >
      <Icon name="cloud download" color={!downloads || downloads === 0 ? 'grey' : 'teal'} />
      {description}
    </Dropdown.Item>
  );
}

export { NavBarDownloadInProgress, NavBarDownloadInProgressMobile };
