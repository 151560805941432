import React, { useEffect, useState } from 'react';
import { Loader, Button, Form, Grid, Header, Icon, Segment } from 'semantic-ui-react';
import NavBarAuth from '../components/NavBar/NavBarAuth';
import NavBarGuest from '../components/NavBar/NavBarGuest';
import { AuthConsumer } from '../contexts/AuthContext';
import { forgotPassword, getLocalServerConfig } from '../services/Server';
import { emailRegex } from '../components/Tools/Utils';
import BackgroundRandomizer from '../components/Tools/BackgroundRandomizer';

function ForgotPasswordPage() {
  const [state, setState] = useState({
    result: '',
    email: '',
    sending: false,
    done: false,
  });

  useEffect(() => {
    document.title = `${getLocalServerConfig().siteName || 'Ergo'} | Mot de passe oublié`;
  }, []);

  const handleSubmitForm = () => {
    const { email } = state;
    if (email === '') {
      setState((prevState) => ({ ...prevState, result: "L'email ne peut pas être vide" }));
      return false;
    }
    if (!emailRegex.test(email)) {
      setState((prevState) => ({ ...prevState, result: 'Email incorrect' }));
      return false;
    }

    setState((prevState) => ({ ...prevState, result: '', sending: true }));
    forgotPassword({ email })
      .then((response) => {
        if (response.status === 200 && response.data.success)
          setState((prevState) => ({ ...prevState, result: '', sending: false, done: true }));
        else
          setState((prevState) => ({
            ...prevState,
            result: `Échec de la demande: ${response.data.message}`,
            sending: false,
          }));
      })
      .catch((e) => {
        console.log(e);
        setState((prevState) => ({
          ...prevState,
          result: `Échec de la demande: ${e}`,
          sending: false,
        }));
      });
    return true;
  };

  const handleInputChange = (name, value) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <AuthConsumer>
      {({ isAuth }) => (
        <BackgroundRandomizer>
          {isAuth ? <NavBarAuth /> : <NavBarGuest />}
          <Grid
            className="no-margin-top"
            textAlign="center"
            style={{ overflowY: 'scroll', height: '100vh' }}
            verticalAlign="middle"
          >
            <Grid.Column style={{ maxWidth: 900 }}>
              <Segment className="transparent-table">
                <br />
                <Header as="h1" color="teal">
                  <Icon name={state.done ? 'check' : 'key'} /> Mot de passe oublié
                </Header>
                <br />
                {!state.done && !state.sending && (
                  <Header as="h3">
                    Première connexion ou mot de passe oublié ?
                    <br />
                    Rentrez votre adresse email ci-dessous pour le réinitialiser.
                  </Header>
                )}
                <Form>
                  {!state.sending && !state.done && (
                    <Form.Group widths="equal">
                      <Form.Input
                        icon="mail"
                        iconPosition="left"
                        onChange={(value) => handleInputChange('email', value.target.value)}
                        fluid
                        value={state.email}
                        label="Votre email"
                        placeholder="Votre email"
                      />
                    </Form.Group>
                  )}
                  {state.done && (
                    <>
                      <Header as="h4" className="text-center">
                        Si le compte existe, vous recevrez un email pour réinitialiser votre mot de
                        passe.
                      </Header>
                      <br />
                      <Button color="teal" size="big" as="a" href="/">
                        <Icon name="home" /> Accueil
                      </Button>
                    </>
                  )}
                  {state.result !== '' && (
                    <>
                      <div>
                        <span className="text-danger">{state.result}</span>
                      </div>
                      <br />
                    </>
                  )}
                  {state.sending && (
                    <>
                      <Loader active content="Envoi..." />
                      <br />
                      <br />
                      <br />
                    </>
                  )}
                </Form>
              </Segment>
              {!state.sending && !state.done && (
                <>
                  <Button
                    icon="send"
                    label="Envoyer"
                    labelPosition="right"
                    color="teal"
                    size="large"
                    onClick={handleSubmitForm}
                  />
                  <Button
                    icon="home"
                    label="Accueil"
                    labelPosition="right"
                    size="large"
                    as="a"
                    href="/"
                  />
                </>
              )}
            </Grid.Column>
          </Grid>
        </BackgroundRandomizer>
      )}
    </AuthConsumer>
  );
}

export default ForgotPasswordPage;
