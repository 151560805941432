import React, { useEffect, useState } from 'react';
import { Loader, Button, Form, Grid, Header, Icon, Segment } from 'semantic-ui-react';
import { Navigate } from 'react-router';
import NavBarGuest from '../components/NavBar/NavBarGuest';
import { AuthConsumer } from '../contexts/AuthContext';
import { registration } from '../services/Auth';
import { emailRegex } from '../components/Tools/Utils';
import BackgroundRandomizer from '../components/Tools/BackgroundRandomizer';
import { getLocalServerConfig } from '../services/Server';

function RegistrationPage() {
  const [state, setState] = useState({
    result: '',
    email: '',
    nickname: '',
    sending: false,
    done: false,
  });

  useEffect(() => {
    document.title = `${getLocalServerConfig().siteName || 'Ergo'} | Mot de passe oublié`;
  }, []);

  const handleSubmitForm = () => {
    const { email, nickname } = state;

    if (!emailRegex.test(email)) {
      setState((prevState) => ({ ...prevState, result: "L'adresse email est incorrect" }));
      return false;
    }

    if (!/^[a-zA-Z0-9-_ .]{2,20}$/.test(nickname)) {
      setState((prevState) => ({
        ...prevState,
        result: 'Le pseudo doit faire entre 2 et 20 caractères [azAZ09-_ .]',
      }));
      return false;
    }

    setState((prevState) => ({ ...prevState, result: '', sending: true }));
    registration({ email, nickname, password: '-' })
      .then((response) => {
        if (response.status === 200 && response.data.success)
          setState((prevState) => ({ ...prevState, result: '', sending: false, done: true }));
        else
          setState((prevState) => ({
            ...prevState,
            result: `Échec de la demande: ${response.data.message}`,
            sending: false,
          }));
      })
      .catch((e) => {
        console.log(e);
        setState((prevState) => ({
          ...prevState,
          result: `Échec de la demande: ${e}`,
          sending: false,
        }));
      });
    return true;
  };

  const handleInputChange = (name, value) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <AuthConsumer>
      {({ isAuth }) =>
        isAuth ? (
          <Navigate to="/" />
        ) : (
          <BackgroundRandomizer>
            <NavBarGuest />
            <Grid
              className="no-margin-top"
              textAlign="center"
              style={{ overflowY: 'scroll', height: '100vh' }}
              verticalAlign="middle"
            >
              <Grid.Column style={{ maxWidth: 900 }}>
                <Segment className="transparent-table">
                  <br />
                  <Header as="h1" color="teal">
                    <Icon name={state.done ? 'check' : 'key'} /> Création de compte
                  </Header>
                  <br />
                  {!state.done && (
                    <Header as="h3">
                      Votre demande d&apos;accès sera soumise à la validation d&apos;un
                      administrateur
                    </Header>
                  )}
                  <Form>
                    {!state.sending && !state.done && (
                      <>
                        <Form.Group widths="equal">
                          <Form.Input
                            icon="mail"
                            iconPosition="left"
                            onChange={(value) => handleInputChange('email', value.target.value)}
                            fluid
                            value={state.email}
                            label="Votre email"
                            placeholder="Votre email*"
                          />
                        </Form.Group>
                        <Form.Group widths="equal">
                          <Form.Input
                            icon="user"
                            iconPosition="left"
                            onChange={(value) => handleInputChange('nickname', value.target.value)}
                            fluid
                            value={state.nickname}
                            label="Pseudo"
                            placeholder="Pseudo (2 à 20 caractères [azAZ09-_ .])"
                          />
                        </Form.Group>
                      </>
                    )}
                    {state.done && (
                      <>
                        <Header as="h4" className="text-center">
                          Votre demande d&apos;accès a été envoyée
                          <br />
                          Si l&apos;adresse email n&apos;est pas déjà utilisée, vous recevrez un
                          email afin de valider votre adresse.
                          <br />
                          Nous vous notifierons une fois le compte autorisé.
                        </Header>
                        <br />
                        <Button color="teal" size="big" as="a" href="/">
                          <Icon name="home" /> Accueil
                        </Button>
                      </>
                    )}
                    {state.result !== '' && (
                      <>
                        <div>
                          <span className="text-danger">{state.result}</span>
                        </div>
                        <br />
                      </>
                    )}
                    {state.sending && (
                      <>
                        <Loader active content="Chargement" />
                        <br />
                        <br />
                        <br />
                      </>
                    )}
                    {!state.sending && !state.done && (
                      <>
                        <Button
                          icon="send"
                          label="Envoyer la demande"
                          labelPosition="right"
                          color="teal"
                          onClick={handleSubmitForm}
                        />
                        <Button
                          icon="question"
                          label="Mot de passe oublié"
                          labelPosition="right"
                          color="orange"
                          as="a"
                          href="/forgotpassword"
                        />
                        <Button icon="home" label="Accueil" labelPosition="right" as="a" href="/" />
                      </>
                    )}
                  </Form>
                </Segment>
              </Grid.Column>
            </Grid>
          </BackgroundRandomizer>
        )
      }
    </AuthConsumer>
  );
}

export default RegistrationPage;
