import { Icon, Menu, Popup } from 'semantic-ui-react';
import React from 'react';

export function NavBarOnlineUsers({ users }) {
  return users.length === 0 ? null : (
    <Popup
      content={users.map((user) => (
        <div key={user.nickname} className="nav-link">
          <Icon name="user outline" /> {user.nickname}
        </div>
      ))}
      position="bottom center"
      trigger={
        <Menu.Item>
          <Icon name="user outline" /> {users.length}
        </Menu.Item>
      }
    />
  );
}

export function NavBarOnlineUsersResponsive({ users }) {
  return users.length === 0 ? null : (
    <Popup
      on={['click', 'hover']}
      hideOnScroll
      header="En ligne"
      size="large"
      trigger={
        <span>
          <Icon name="users" /> {users.length} {users.length > 1 ? 'Utilisateurs' : 'Utilisateur'}{' '}
          en ligne
        </span>
      }
      content={users.map((user) => (
        <div key={user.nickname}>
          <Icon name="user outline" /> {user.nickname}
        </div>
      ))}
    />
  );
}
