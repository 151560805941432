import React, { forwardRef, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Icon, Image, Menu, Popup } from 'semantic-ui-react';
import io from 'socket.io-client';
import { getNickname, getRole, getToken } from '../../services/Auth';
import { getLocalServerConfig, getServerInfo, getServerNotifications } from '../../services/Server';
import { Media } from '../Tools/AppMedia';
import { AuthContext } from '../../contexts/AuthContext';
import '../../assets/css/NavBarAuth.css';
import { NavBarOnlineUsers, NavBarOnlineUsersResponsive } from './NavBarOnlineUsers';
import {
  NavBarDownloadInProgress,
  NavBarDownloadInProgressMobile,
} from './NavBarDownloadInProgress';
import BugModal from '../Modal/BugModal';
import MagnetModal from '../Modal/MagnetModal';
import NavBarHistory from './NavBarHistory';
import NavBarSearchInput from './NavBarSearchInput';
import defaultProfilePicture from '../../assets/img/default-profile-pic.jpg';

const { REACT_APP_BUILD_ID: buildId, REACT_APP_API_URL: apiUrl } = process.env;

const NavbarAuth = forwardRef(
  (
    {
      searchCallback,
      searchInput,
      setSearchInput,
      providers,
      getProvidersList,
      providersUpdatedAt,
      providersLoading,
      refreshProviders,
      selectedProvider,
      handleChangeProvider,
      handleTabChange,
      getDirectoryCallback,
      uploadCallback,
      setOpenDiscovery,
      downloadInProgress,
    },
    ref
  ) => {
    const siteName = getLocalServerConfig().siteName || 'Ergo';
    const { logout } = useContext(AuthContext);
    const [openBugModal, setOpenBugModal] = useState(false);
    const [openMagnetModal, setOpenMagnetModal] = useState(false);
    const [state, setState] = useState({
      diskSpaceLeft: '-',
      onlineUsers: [],
      vpnStatus: false,
      notifications: [],
      completion: [],
      loading: false,
    });

    const getNotificationList = () => {
      getServerNotifications()
        .then((response) => {
          if (response.data.success)
            setState((prevState) => ({
              ...prevState,
              notifications: response.data.result.reverse(),
            }));
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) logout();
        });
    };

    const getNavBarInfo = () => {
      getServerInfo()
        .then(({ data: { data, users, vpn_status } }) => {
          setState((prevState) => ({
            ...prevState,
            diskSpaceLeft: data,
            onlineUsers: users,
            vpnStatus: vpn_status,
          }));
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) logout();
          else
            setState((prevState) => ({
              ...prevState,
              diskSpaceLeft: 'Indisponible',
              onlineUsers: [],
              vpnStatus: false,
            }));
        });
    };

    useEffect(() => {
      setTimeout(() => getNavBarInfo(), 500);
      getNotificationList();
      const intervalInfo = setInterval(() => getNavBarInfo(), 5000);
      const intervalNotifications = setInterval(() => getNotificationList(), 120000);
      const wsConnection = io(
        `${window.location.protocol === 'https:' ? 'ws' : 'w'}s://${apiUrl.slice(
          apiUrl.indexOf('://') + 3
        )}`,
        {
          withCredentials: true,
          auth: { token: `Bearer ${getToken()}` },
        }
      )
        .on('connect_error', (err) => {
          if (err.message === 'Unauthorized: Token is missing or invalid Bearer') logout();
        })
        .on('logout', () => {
          logout();
        });

      return () => {
        clearInterval(intervalInfo);
        clearInterval(intervalNotifications);
        wsConnection.disconnect();
      };
    }, []);

    return (
      <>
        <Media greaterThanOrEqual="lg">
          <Menu className="transparent-table" borderless style={{ width: '100%' }}>
            {window.location.pathname === '/' ? (
              <Menu.Item
                header
                onClick={() => {
                  handleTabChange('', 'downloads');
                  getDirectoryCallback('/');
                }}
              >
                {siteName}
              </Menu.Item>
            ) : (
              <Menu.Item header as="a" href="/">
                {siteName}
              </Menu.Item>
            )}
            {window.location.pathname === '/' && (
              <Menu.Item
                style={{
                  paddingLeft: '1px',
                  paddingRight: '1px',
                  paddingTop: '1px',
                  paddingBottom: '1px',
                  flexGrow: 100,
                }}
              >
                <Popup
                  content={`Uploader un fichier${
                    getLocalServerConfig().torrentEnabled !== 'disabled' ? ' / torrent' : ''
                  }`}
                  trigger={
                    <Icon className="clickable" name="upload" onClick={() => uploadCallback()} />
                  }
                />
                {getLocalServerConfig().torrentEnabled !== 'disabled' && (
                  <Popup
                    content="Ajouter un lien Magnet"
                    trigger={
                      <Icon
                        className="clickable"
                        name="magnet"
                        onClick={() => {
                          setOpenMagnetModal(true);
                        }}
                      />
                    }
                  />
                )}
                <span style={{ paddingRight: '10px' }} />
                {getLocalServerConfig().torrentSearchEnabled && (
                  <NavBarSearchInput
                    ref={ref}
                    setSearchInput={setSearchInput}
                    providers={providers}
                    selectedProvider={selectedProvider}
                    getProvidersList={getProvidersList}
                    providersUpdatedAt={providersUpdatedAt}
                    providersLoading={providersLoading}
                    handleChangeProvider={handleChangeProvider}
                    refreshProviders={refreshProviders}
                    handleTabChange={handleTabChange}
                    searchCallback={searchCallback}
                    searchInput={searchInput}
                  />
                )}
                {getLocalServerConfig().tmdbEnabled && (
                  <Popup
                    content="Tendances & Watch-list"
                    position="bottom center"
                    trigger={
                      <Menu.Item onClick={() => setOpenDiscovery(true)}>
                        <span>
                          <Icon name="fire" /> Discovery
                        </span>
                      </Menu.Item>
                    }
                  />
                )}
              </Menu.Item>
            )}
            <Menu.Menu position="right">
              {getLocalServerConfig().torrentEnabled !== 'disabled' && (
                <NavBarDownloadInProgress
                  downloads={downloadInProgress}
                  handleTabChange={handleTabChange}
                />
              )}
              <NavBarOnlineUsers users={state.onlineUsers} />
              <Popup
                content="Espace disque restant"
                position="bottom center"
                trigger={
                  <Menu.Item>
                    <Icon name="disk" /> {state.diskSpaceLeft}
                  </Menu.Item>
                }
              />
              {getLocalServerConfig().torrentEnabled !== 'disabled' && (
                <NavBarHistory notifications={state.notifications} />
              )}
              <Menu.Item>
                {getLocalServerConfig().vpnCheckerEnabled && (
                  <Popup
                    content={state.vpnStatus ? 'VPN Connecté' : 'VPN Déconnecté'}
                    position="bottom center"
                    trigger={<Icon name="shield" color={state.vpnStatus ? 'black' : 'red'} />}
                  />
                )}
                <Popup
                  content="Signaler un bug"
                  position="bottom center"
                  trigger={
                    <Icon
                      name="bug"
                      color="orange"
                      className="clickable"
                      onClick={() => setOpenBugModal(true)}
                    />
                  }
                />
              </Menu.Item>
              <Menu.Item>
                <Dropdown simple icon={<Image src={defaultProfilePicture} avatar />}>
                  <Dropdown.Menu>
                    <Dropdown.Item as={Link} to="/account">
                      <Icon name="user" /> Mon Compte
                    </Dropdown.Item>
                    {getLocalServerConfig().devicesEnabled && (
                      <Dropdown.Item as={Link} to="/devices">
                        <Icon name="server" /> Mes Appareils
                      </Dropdown.Item>
                    )}
                    {getRole() > 1 && (
                      <Dropdown.Item as={Link} to="/settings">
                        <Icon name="settings" /> Réglages
                      </Dropdown.Item>
                    )}
                    <Dropdown.Item as={Link} to="/contact">
                      <Icon name="mail" /> Nous Contacter
                    </Dropdown.Item>
                    <Dropdown.Item onClick={logout}>
                      <Icon name="log out" /> Déconnexion
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Icon name="react" /> Ver. {buildId}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Menu.Item>
            </Menu.Menu>
          </Menu>
        </Media>
        <Media lessThan="lg">
          <Menu className="transparent-table" borderless>
            {window.location.pathname === '/' ? (
              <Menu.Item
                header
                onClick={() => {
                  handleTabChange('', 'downloads');
                  getDirectoryCallback('/');
                }}
              >
                {siteName}
              </Menu.Item>
            ) : (
              <Menu.Item header as="a" href="/">
                {siteName}
              </Menu.Item>
            )}
            {getLocalServerConfig().torrentSearchEnabled && window.location.pathname === '/' && (
              <NavBarSearchInput
                ref={ref}
                setSearchInput={setSearchInput}
                providers={providers}
                selectedProvider={selectedProvider}
                getProvidersList={getProvidersList}
                providersUpdatedAt={providersUpdatedAt}
                providersLoading={providersLoading}
                handleChangeProvider={handleChangeProvider}
                refreshProviders={refreshProviders}
                handleTabChange={handleTabChange}
                searchCallback={searchCallback}
                searchInput={searchInput}
                mobile
              />
            )}
            <Menu.Menu position="right">
              <Dropdown item icon="bars">
                <Dropdown.Menu>
                  {window.location.pathname === '/' && (
                    <>
                      <Dropdown.Item onClick={() => uploadCallback()}>
                        <Icon name="upload" /> Uploader un fichier
                        {getLocalServerConfig().torrentEnabled !== 'disabled' && ' /torrent'}
                      </Dropdown.Item>
                      {getLocalServerConfig().torrentEnabled !== 'disabled' && (
                        <Dropdown.Item
                          onClick={() => {
                            setOpenMagnetModal(true);
                          }}
                        >
                          <Icon name="magnet" /> Ajouter un lien magnet
                        </Dropdown.Item>
                      )}
                    </>
                  )}
                  {getLocalServerConfig().tmdbEnabled && window.location.pathname === '/' ? (
                    <Menu.Item onClick={() => setOpenDiscovery(true)}>
                      <span>
                        <Icon name="fire" className="blink-fast" /> Discovery
                      </span>
                    </Menu.Item>
                  ) : null}
                  {getLocalServerConfig().torrentEnabled !== 'disabled' && (
                    <NavBarDownloadInProgressMobile
                      downloads={downloadInProgress}
                      handleTabChange={handleTabChange}
                    />
                  )}
                  <Dropdown.Item>
                    <NavBarOnlineUsersResponsive users={state.onlineUsers} />
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Icon name="disk" /> {`${state.diskSpaceLeft} d'espace restant`}
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to="/account">
                    <Icon name="user" /> Mon Compte ({getNickname()})
                  </Dropdown.Item>
                  {getLocalServerConfig().devicesEnabled && (
                    <Dropdown.Item as={Link} to="/devices">
                      <Icon name="server" /> Mes Appareils
                    </Dropdown.Item>
                  )}
                  {getRole() > 1 && (
                    <Dropdown.Item as={Link} to="/settings">
                      <Icon name="settings" /> Réglages
                    </Dropdown.Item>
                  )}
                  {getLocalServerConfig().torrentEnabled !== 'disabled' && (
                    <Dropdown.Item>
                      <Icon name="shield" color={state.vpnStatus ? 'black' : 'red'} /> VPN{' '}
                      {state.vpnStatus ? ' Connecté' : ' Déconnecté'}
                    </Dropdown.Item>
                  )}
                  <Dropdown.Item onClick={() => setOpenBugModal(true)}>
                    <Icon name="bug" color="orange" /> Signaler un bug
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to="/contact">
                    <Icon name="mail" /> Nous Contacter
                  </Dropdown.Item>
                  <Dropdown.Item onClick={logout}>
                    <Icon name="log out" /> Déconnexion
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Icon name="react" /> Ver. {buildId}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Menu>
          </Menu>
        </Media>
        <BugModal open={openBugModal} setOpen={setOpenBugModal} />
        <MagnetModal open={openMagnetModal} setOpen={setOpenMagnetModal} />
      </>
    );
  }
);

export default NavbarAuth;
