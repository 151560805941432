import React from 'react';
import { Dropdown, Flag, Grid, Header, Icon } from 'semantic-ui-react';
import TimeAgo from 'react-timeago';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
import { firstCharUp } from '../Tools/Utils';
import { getRole } from '../../services/Auth';
import frenchStrings from '../Tools/TimeAgoFrStrings';

const formatter = buildFormatter(frenchStrings);

function NavBarSearchProviders({
  providers,
  selectedProvider,
  getProvidersList,
  providersUpdatedAt,
  providersLoading,
  handleChangeProvider,
  refreshProviders,
}) {
  let overallStatus = true;
  providers.forEach((provider) => {
    if (!provider.status) overallStatus = false;
  });
  const result = [
    {
      key: 0,
      text: window.innerWidth > 1210 ? 'Tous les trackers' : 'Tous',
      value: 'all',
      content: (
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Icon name="checkmark" color={overallStatus ? 'green' : 'orange'} />
            </Grid.Column>
            <Grid.Column>
              <Header
                size="tiny"
                content={window.innerWidth > 1210 ? 'Tous les trackers' : 'Tous'}
                subheader="Type: public & privé"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      ),
    },
  ]
    .concat(
      providers.map((provider, index) => {
        return {
          key: index + 1,
          text: firstCharUp(provider.provider),
          value: provider.providerId,
          disabled: !provider.status,
          content: (
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <Icon
                    name={provider.status ? 'checkmark' : 'close'}
                    color={provider.status ? 'green' : 'red'}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Header
                    size="tiny"
                    content={
                      <>
                        {firstCharUp(provider.provider)}{' '}
                        <Flag name={provider.language === 'fr-FR' ? 'fr' : 'us'} />
                      </>
                    }
                    subheader={`Type: ${provider.type}`}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          ),
        };
      })
    )
    .concat([
      {
        key: providers.length + 2,
        content: (
          <span onClick={() => getProvidersList()}>
            Mis à jour <TimeAgo date={providersUpdatedAt} formatter={formatter} />
          </span>
        ),
        value: 'providersUpdatedAt',
        disabled: true,
        image: getRole() > 1 && (
          <Icon
            className="clickable"
            name="refresh"
            color="teal"
            loading={providersLoading}
            onClick={() => refreshProviders()}
          />
        ),
      },
    ]);
  return (
    <Dropdown
      basic
      button
      direction="left"
      options={result}
      value={selectedProvider}
      onChange={handleChangeProvider}
    />
  );
}

export default NavBarSearchProviders;
