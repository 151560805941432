import axios from 'axios';

const { REACT_APP_API_URL: apiUrl } = process.env;

const instance = axios.create({
  baseURL: `${apiUrl}/`,
  timeout: 180000,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'X-Requested-With': 'XMLHttpRequest',
  },
});

export const isLoggedIn = () => {
  try {
    return !!localStorage.getItem('token');
  } catch (_e) {
    return false;
  }
};

const requestHandler = (request) => {
  if (isLoggedIn()) {
    request.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  }
  return request;
};

instance.interceptors.request.use(requestHandler);

export default instance;
