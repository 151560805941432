import React, { useState } from 'react';
import { Button, Icon, Table } from 'semantic-ui-react';
import { Tooltip } from 'react-tooltip';
import { Link } from 'react-router-dom';
import { toggleDeviceState } from '../../services/Settings';
import { notifError, notifSuccess, notifWarning } from '../Tools/NotificationToasts';
import { deleteDevice } from '../../services/Devices';
import DeviceTestConnectivityButton from '../Device/DeviceTestConnectivityButton';
import DeviceDeleteModal from '../Modal/DeviceDeleteModal';

function DevicesTabRow({ device, location, getDevices }) {
  const [open, setOpen] = useState(false);

  const handleToggleDeviceState = (deviceId, enabled) => {
    toggleDeviceState({ id: deviceId, enabled })
      .then((response) => {
        if (response.status === 200 && response.data.success)
          notifSuccess(`Appareil ${enabled ? 'activé' : 'désactivé'}`);
        else notifError(`Échec ${enabled ? "d'activation" : 'de désactivation'} de l'appareil`);
        setTimeout(() => getDevices(), 4000);
      })
      .catch((e) => {
        notifError(
          `Échec ${enabled ? "d'activation" : 'de désactivation'} de l'appareil: ${e.message}`
        );
      });
  };

  const handleDeleteDevice = (deviceId, name) => {
    deleteDevice(deviceId)
      .then((response) => {
        if (response.data.success) notifSuccess(`${name} supprimé`);
        else notifWarning(`Échec de suppression: ${response.data.message}`);
        setTimeout(() => getDevices(), 1000);
      })
      .catch((e) => {
        notifWarning(`Échec de suppression de ${name}: ${e.message}`);
      });
  };

  return (
    <React.Fragment key={device.id}>
      <Table.Row className={device.enabled ? '' : 'grey-text'}>
        <Table.Cell>{device.id}</Table.Cell>
        <Table.Cell>{`[${device.owner_id}] ${device.owner.nickname}`}</Table.Cell>
        <Table.Cell>
          {device.enabled ? (
            <>
              <Icon
                name="check"
                id={`disable-device-${device.id}`}
                color="green"
                className="clickable"
                onClick={() => handleToggleDeviceState(device.id, !device.enabled)}
              />
              <Tooltip
                anchorId={`disable-device-${device.id}`}
                style={{ zIndex: 1 }}
                place="top"
                content="Désactiver l'appareil"
              />
            </>
          ) : (
            <>
              <Icon
                name="close"
                id={`enable-device-${device.id}`}
                color="red"
                className="clickable"
                onClick={() => handleToggleDeviceState(device.id, !device.enabled)}
              />
              <Tooltip
                anchorId={`enable-device-${device.id}`}
                style={{ zIndex: 1 }}
                place="top"
                content="Activer l'appareil"
              />
            </>
          )}
        </Table.Cell>
        <Table.Cell>
          {device.logged_in ? (
            <Icon name="check" color="green" />
          ) : (
            <Icon name="close" color="orange" />
          )}
          <DeviceTestConnectivityButton device={device} getDevices={getDevices} />
        </Table.Cell>
        <Table.Cell>{device.type}</Table.Cell>
        <Table.Cell>{device.name}</Table.Cell>
        <Table.Cell>{device.login || '-'}</Table.Cell>
        <Table.Cell>{`${device.protocol}://${device.address}:${device.port}`}</Table.Cell>
        <Table.Cell>{device.notification_token || '-'}</Table.Cell>
        <Table.Cell>
          <Button
            color="teal"
            id={`edit-device-${device.id}`}
            as={Link}
            to={{
              pathname: `/settings/devices/edit/${device.id}`,
              state: { from: location.pathname },
            }}
            size="tiny"
            icon="pencil"
          />
          <Tooltip
            anchorId={`edit-device-${device.id}`}
            style={{ zIndex: 1 }}
            place="top"
            content="Modifier"
          />
          <Button
            onClick={() => {
              setOpen(true);
            }}
            color="red"
            id={`delete-device-${device.id}`}
            size="tiny"
            icon="trash alternate"
          />
          <Tooltip
            anchorId={`delete-device-${device.id}`}
            style={{ zIndex: 1 }}
            place="top"
            content="Supprimer définitivement"
          />
        </Table.Cell>
      </Table.Row>
      <DeviceDeleteModal
        open={open}
        setOpen={setOpen}
        deviceId={device.id}
        deviceName={device.name}
        handleDelete={handleDeleteDevice}
      />
    </React.Fragment>
  );
}

export default DevicesTabRow;
