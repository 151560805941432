import React from 'react';
import { Card, Grid, Pagination } from 'semantic-ui-react';
import MediaCardPlaceholder from '../MediaCard/MediaCardPlaceholder';

function DiscoveryCardsPlaceholder() {
  return (
    <>
      <Grid>
        <Grid.Row centered>
          <Pagination
            secondary
            boundaryRange={window.innerWidth > 1210 ? 2 : 0}
            siblingRange={1}
            ellipsisItem={window.innerWidth > 1210 ? '...' : null}
            totalPages={1000}
            disabled
          />
        </Grid.Row>
      </Grid>
      <Card.Group centered>
        <MediaCardPlaceholder type="discovery" />
        <MediaCardPlaceholder type="discovery" />
        <MediaCardPlaceholder type="discovery" />
        <MediaCardPlaceholder type="discovery" />
        <MediaCardPlaceholder type="discovery" />
        <MediaCardPlaceholder type="discovery" />
        <MediaCardPlaceholder type="discovery" />
        <MediaCardPlaceholder type="discovery" />
        <MediaCardPlaceholder type="discovery" />
        <MediaCardPlaceholder type="discovery" />
      </Card.Group>
      <Grid>
        <Grid.Row centered>
          <Pagination
            secondary
            boundaryRange={window.innerWidth > 1210 ? 2 : 0}
            siblingRange={1}
            ellipsisItem={window.innerWidth > 1210 ? '...' : null}
            totalPages={1000}
            disabled
          />
        </Grid.Row>
      </Grid>
    </>
  );
}

export default DiscoveryCardsPlaceholder;
