import { Base64 } from 'js-base64';
import Axios from './Axios';

export const getEnabledProvidersList = () => Axios.get(`/api/v1/search/enabledProviders/`);
export const refreshProvidersList = () => Axios.post(`/api/v1/search/refreshProviders/`);
export const getSearch = (terms, provider = 'all') =>
  Axios.get(`/api/v1/search/?query=${terms}&provider=${provider}`);
export const getSearchCompletion = (query) =>
  Axios.get(`/api/v1/search/history/?query=${Base64.encode(query, true)}`);
export const deleteSearchCompletion = (query) =>
  Axios.delete(`/api/v1/search/history/`, { data: { query: Base64.encode(query, true) } });
export const deleteSearchHistory = () => Axios.delete(`/api/v1/search/history/clear/`);
export const getSearchHistory = () => Axios.get(`/api/v1/search/history/all/`);
