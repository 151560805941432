import { Base64 } from 'js-base64';
import Axios from './Axios';

export const getDeviceList = (disabled) => Axios.get(`/api/v1/devices/list/?disabled=${disabled}`);
export const getDeviceTasks = (device_id) =>
  Axios.get(`/api/v1/devices/tasks/list/?device_id=${device_id}`);
export const addDeviceTasks = (params) => Axios.post('/api/v1/devices/tasks/add/', params); // device_id, [links], [filenames]
export const deviceTaskAction = (params) => Axios.post('/api/v1/devices/tasks/action/', params); // device_id, task_id, action
export const getDeviceFsList = (device_id, path) =>
  Axios.get(`/api/v1/devices/fs/list/?device_id=${device_id}&path=${path}`);
export const testDevice = (device_id) => Axios.get(`/api/v1/devices/test/?device_id=${device_id}`);
export const deleteDeviceFs = (device_id, path, current_path) =>
  Axios.delete(
    `/api/v1/devices/fs/delete/${device_id}/${Base64.encode(
      encodeURIComponent(path),
      true
    )}/${Base64.encode(encodeURIComponent(current_path), true)}`
  ); // device_id, path, current_path
export const addDevice = (params) => Axios.post('/api/v1/settings/devices/new', params);
export const deleteDevice = (device_id) => Axios.delete(`/api/v1/settings/devices/${device_id}`);
