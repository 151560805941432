import React, { useState } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { testDevice } from '../../services/Devices';
import { notifError, notifSuccess } from '../Tools/NotificationToasts';

function DeviceTestConnectivityButton({ device, getDevices }) {
  const [state, setState] = useState({
    working: 0,
    loading: false,
  });

  const testConnectivity = () => {
    setState({ working: 0, loading: true });
    testDevice(device.id)
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          notifSuccess(`${device.name} est prêt`);
          setState({ working: 1, loading: false });
          setTimeout(() => getDevices(), 1000);
        } else {
          notifError(`${device.name} ne fonctionne pas: ${response.data.message}`);
          setState({ working: 2, loading: false });
        }
      })
      .catch(() => {
        notifError(`Impossible de tester ${device.name} pour le moment`);
        setState({ working: 2, loading: false });
      });
  };

  return (
    device.enabled && (
      <Button
        basic
        size="mini"
        onClick={() => testConnectivity()}
        disabled={state.loading || state.working === 1}
      >
        {state.working !== 1 && (
          <Icon
            name={
              state.working === 1
                ? 'check'
                : state.working === 2
                  ? 'close'
                  : state.loading
                    ? 'refresh'
                    : 'question'
            }
            loading={state.loading}
            color={state.working === 1 ? 'green' : state.working === 2 ? 'red' : 'grey'}
          />
        )}
        {state.working === 1
          ? 'Prêt'
          : state.working === 2
            ? 'Échec'
            : state.loading
              ? 'En cours...'
              : 'Tester'}
      </Button>
    )
  );
}

export default DeviceTestConnectivityButton;
