import React, { useCallback, useEffect, useState } from 'react';
import { Loader, Grid, Header, Icon, Segment } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';
import NavBarGuest from '../components/NavBar/NavBarGuest';
import { grantMagicLink } from '../services/Auth';
import BackgroundRandomizer from '../components/Tools/BackgroundRandomizer';
import { getLocalServerConfig } from '../services/Server';

function MagicLoginPage() {
  const { magicToken } = useParams();
  const siteName = getLocalServerConfig().siteName || 'Ergo';
  const [state, setState] = useState({
    result: '',
    sending: false,
    done: false,
  });

  const handleValidation = useCallback(() => {
    if (magicToken === '') {
      setState((prevState) => ({
        ...prevState,
        result: 'Token de connexion invalide. Recommencez la connexion depuis le début',
      }));
      return false;
    }
    setState((prevState) => ({ ...prevState, result: '', sending: true }));

    grantMagicLink({ magicToken })
      .then((response) => {
        if (response.status === 200 && response.data.success)
          setState({ result: '', sending: false, done: true });
        else
          setState((prevState) => ({
            ...prevState,
            result: `${response.data.message}`,
            sending: false,
          }));
      })
      .catch((e) => {
        console.log(e);
        setState((prevState) => ({
          ...prevState,
          result: `Échec de la requête, rafraîchissez la page`,
          sending: false,
        }));
      });
    return true;
  }, [magicToken]);

  useEffect(() => {
    document.title = `${siteName} | Autorisation de connexion`;
    handleValidation();
  }, [handleValidation]);

  return (
    <BackgroundRandomizer>
      <NavBarGuest />
      <Grid
        textAlign="center"
        style={{ overflowY: 'scroll', height: '100vh' }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 900 }}>
          <Segment>
            <br />
            <Header as="h2" color="teal">
              <Icon name={state.done ? 'check' : 'key'} />{' '}
              {!state.done ? `Autorisation de connexion à ${siteName}` : 'Connexion autorisée'}
            </Header>
            <br />
            {state.done && (
              <>
                La connexion va continuer automatiquement sur l&apos;appareil concerné.
                <br />
                <br />
                Vous pouvez fermer cette page.
              </>
            )}
            {state.result !== '' && (
              <div>
                <span className="text-danger">{state.result}</span>
              </div>
            )}
            {state.sending && (
              <>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <Loader active content="Chargement" />
              </>
            )}
          </Segment>
        </Grid.Column>
      </Grid>
    </BackgroundRandomizer>
  );
}

export default MagicLoginPage;
