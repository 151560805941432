import React, { useEffect } from 'react';
import { Icon, Button, Grid, Header, Modal } from 'semantic-ui-react';
import BackgroundRandomizer from '../components/Tools/BackgroundRandomizer';
import { getLocalServerConfig } from '../services/Server';

function ExpiredPage() {
  useEffect(() => {
    document.title = `${getLocalServerConfig().siteName || 'Ergo'} | Session suspendue`;
  }, []);

  return (
    <BackgroundRandomizer>
      <Modal basic dimmer="blurring" size="small" open>
        <Grid>
          <Grid.Row centered>
            <Header as="h1" color="teal" icon>
              <Icon name="pause circle outline" />
              Session en pause
            </Header>
          </Grid.Row>
          <Grid.Row centered>
            Votre session a été suspendue après 10 minutes d&apos;inactivité
            <br />
          </Grid.Row>
          <Grid.Row centered>
            <Button basic inverted as="a" color="teal" size="large" href="/">
              <Icon name="home" /> Accueil
            </Button>
          </Grid.Row>
        </Grid>
      </Modal>
    </BackgroundRandomizer>
  );
}

export default ExpiredPage;
