import React, { useState } from 'react';
import { Button, Header, Icon, Image, Modal, Segment, Select } from 'semantic-ui-react';
import { getLocalServerConfig } from '../../services/Server';
import { addSonarrMedia } from '../../services/Tvshow';
import { notifError, notifSuccess, notifWarning } from '../Tools/NotificationToasts';

function MediaSchedulerAddModal({
  open,
  setOpen,
  item,
  setOpenMediaScheduler,
  schedulerMediaType,
  qualityProfiles,
  tags,
}) {
  const siteName = getLocalServerConfig().siteName || 'Ergo';
  const [selectedQualityProfile, setSelectedQualityProfile] = useState(
    qualityProfiles ? qualityProfiles[0].value : null
  );
  const [selectedTag, setSelectedTag] = useState(tags ? tags[1].value : null);

  const addMedia = () => {
    addSonarrMedia({
      title: item.title,
      tvdbId: item.tvdbId,
      qualityProfileId: selectedQualityProfile,
      tag: selectedTag ? [selectedTag] : [],
    })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          notifSuccess(`${item.title} ajouté avec succès au calendrier.`);
          setOpen(false);
          setOpenMediaScheduler(false);
        } else {
          notifError("Impossible d'ajouter le média, veuillez réessayer plus tard.");
          console.log(response);
        }
      })
      .catch((e) => {
        if (e && e.message === 'Request failed with status code 400')
          notifWarning('Média déjà présent dans le calendrier');
        else {
          console.table(e);
          notifError("Impossible d'ajouter le média, veuillez réessayer plus tard.");
        }
      });
  };

  return (
    <Modal onClose={() => setOpen(false)} onOpen={() => setOpen(true)} open={open} size="small">
      <Header icon>
        <Icon name="calendar check outline" color="teal" />
        Ajouter {item.title} au calendrier
      </Header>
      <Modal.Content>
        <center>
          <Image
            size="small"
            src={item.remotePoster || `${window.origin}/placeholderPoster.png`}
            wrapped
          />
          <Segment>
            Lors de la parution {schedulerMediaType === 'tv' ? "d'un nouvel épisode" : 'du film'}{' '}
            sur un tracker, celui-ci sera automatiquement téléchargé vers
            <br />
            {siteName}.
            <br />
            <br />
            {schedulerMediaType === 'tv' && (
              <>
                <Icon name="warning sign" color="yellow" size="large" />
                N&apos;oubliez pas d&apos;abonner votre NAS / Freebox à la série pour la récupérer
                automatiquement, ou bien votre compte être notifié(e).
              </>
            )}
          </Segment>
          <Segment>
            <b>Qualité / Résolution</b>
            <br />
            <br />
            <Select
              options={qualityProfiles || []}
              placeholder="Qualité / Résolution"
              onChange={(e, { value }) => setSelectedQualityProfile(value)}
              value={selectedQualityProfile}
            />
          </Segment>
          <Segment>
            <b>Langue(s)</b>
            <br />
            <br />
            <Icon name="warning circle" color="teal" size="large" /> Si possible, merci de
            privilégier la langue MULTI pour répondre au mieux à la demande de la communauté.
            <br />
            <br />
            <Select
              options={tags || []}
              placeholder="Langue"
              onChange={(e, { value }) => setSelectedTag(value)}
              value={selectedTag}
            />
          </Segment>
        </center>
      </Modal.Content>
      <Modal.Actions>
        <Button basic color="black" onClick={() => setOpen(false)}>
          <Icon name="remove" /> Annuler
        </Button>
        <Button color="green" onClick={() => addMedia()} disabled={selectedQualityProfile === null}>
          <Icon name="checkmark" /> Ajouter
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default MediaSchedulerAddModal;
