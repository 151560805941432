import Axios from './Axios';

export const createAuthSession = (credentials) => {
  return Axios.post('/auth/signin', credentials);
};

export const setUserInfo = ({
  nickname,
  email,
  display_type,
  role,
  created_at,
  last_login_at,
  id,
  notification_token,
  notification_token_enabled,
  notification_email_enabled,
}) => {
  localStorage.setItem('nickname', `${nickname}`);
  localStorage.setItem('email', `${email}`);
  localStorage.setItem('displayType', `${display_type}`);
  localStorage.setItem('role', `${role}`);
  localStorage.setItem('registeredAt', `${created_at}`);
  localStorage.setItem('lastLogin', `${last_login_at}`);
  localStorage.setItem('userId', `${id}`);
  localStorage.setItem('notificationToken', `${notification_token}`);
  localStorage.setItem('notificationTokenEnabled', `${notification_token_enabled}`);
  localStorage.setItem('notificationEmailEnabled', `${notification_email_enabled}`);
};

export const createMagicLink = (params) => Axios.post('/auth/magiclink/', params); // email

export const getMagicLink = (requestId, sig) =>
  Axios.get(`/auth/magiclink?requestId=${requestId}&sig=${sig}`);

export const grantMagicLink = (params) => Axios.post('/auth/magiclink/grant/', params); // token

export const setCookie = (name, value, days = 7, path = '/') => {
  const expires = new Date(Date.now() + days * 864e5).toUTCString();
  document.cookie = `${name}=${encodeURIComponent(
    value
  )}; expires=${expires}; path=${path}; SameSite=Strict`;
};

export const deleteCookie = (name, path) => {
  setCookie(name, '', -1, path);
};

export const registration = (params) => Axios.post('/auth/signup/', params); // email, nickname, password (password is unused but any string must be passed)

export const setToken = (token) => {
  localStorage.setItem('token', `${token}`);
};

export const getNickname = () => localStorage.getItem('nickname');

export const getEmail = () => localStorage.getItem('email');

export const getDisplayType = () => localStorage.getItem('displayType');

export const getRole = () => parseInt(localStorage.getItem('role'), 10);

export const getRegisteredAt = () => localStorage.getItem('registeredAt');

export const getLastLogin = () => localStorage.getItem('lastLogin');

export const getUserId = () => parseInt(localStorage.getItem('userId'), 10);

export const getNotificationToken = () => localStorage.getItem('notificationToken');

export const getNotificationTokenEnabled = () => localStorage.getItem('notificationTokenEnabled');

export const getNotificationEmailEnabled = () => localStorage.getItem('notificationEmailEnabled');

export const getToken = () => localStorage.getItem('token');

export const setHeader = (token) => {
  Axios.defaults.headers.common = {
    Authorization: `Bearer ${token}`,
  };
};

export const initializeSession = (user, token) => {
  setUserInfo(user);
  setHeader(token);
  setToken(token);
  setCookie('token', token, 7, '/');
};

export async function Login(credentials) {
  Axios.defaults.headers.common = {};
  let authResponse;
  try {
    authResponse = await createAuthSession(credentials);
  } catch (e) {
    return e.response;
  }
  const {
    data,
    headers: { authorization },
  } = authResponse;
  const token = authorization;
  initializeSession(data.result, token);
  return { authResponse };
}

export const getSessions = () => Axios.get('/auth/sessions/');
export const revoke = (params) => Axios.post('/auth/revoke', params); // aud
export const logout = () => Axios.get('/auth/session/logout');
