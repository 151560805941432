import React from 'react';
import { Base64 } from 'js-base64';
import DirectoryTableRow from './DirectoryTableRow';

function DirectoryTable({
  currentDirectory,
  items,
  age,
  devices,
  checkedItems,
  checkItemCallback,
  directoryCallback,
  deleteCallback,
  zipCallback,
}) {
  const getParentDirectory = () => {
    const parent = currentDirectory.split('/');
    parent.pop();
    let parentDirectory = parent.join('/');
    if (parentDirectory === '') parentDirectory = '/';
    return parentDirectory;
  };

  const buildTable = () => {
    const dirs = [];
    const files = [];
    items.forEach((item) => {
      const id = Base64.encode(unescape(encodeURIComponent(item.filepath)), true);
      if (item.type === 'dir')
        dirs.push(
          <DirectoryTableRow
            key={id}
            age={age}
            devices={devices}
            element={item}
            checkedItems={checkedItems.length > 0}
            checked={checkedItems.includes(item)}
            checkItemCallback={checkItemCallback}
            directoryCallback={directoryCallback}
            deleteCallback={deleteCallback}
            zipCallback={zipCallback}
          />
        );
      else
        files.push(
          <DirectoryTableRow
            key={id}
            age={age}
            devices={devices}
            element={item}
            checkedItems={checkedItems.length > 0}
            checked={checkedItems.includes(item)}
            checkItemCallback={checkItemCallback}
            directoryCallback={directoryCallback}
            deleteCallback={deleteCallback}
            zipCallback={zipCallback}
          />
        );
    });
    dirs.push(files);
    return dirs;
  };

  const table = buildTable();
  const parentDirectory = {
    file: 'Répertoire Parent',
    type: 'dir',
    date: '',
    filepath: getParentDirectory(),
    who_downloaded: [],
  };
  return (
    <>
      {currentDirectory !== '/' && (
        <DirectoryTableRow
          key={Base64.encode(unescape(encodeURIComponent(parentDirectory.filepath)), true)}
          age={age}
          element={parentDirectory}
          directoryCallback={directoryCallback}
          deleteCallback={deleteCallback}
        />
      )}
      {table}
    </>
  );
}

export default DirectoryTable;
