import React, { useState } from 'react';
import {
  Card,
  Button,
  Container,
  Dropdown,
  Header,
  Icon,
  Input,
  Modal,
  Search,
  Segment,
} from 'semantic-ui-react';
import { getSonarrQualityProfiles, getSonarrTags, searchSonarrMedia } from '../../services/Tvshow';
import { notifError, notifSuccess } from '../Tools/NotificationToasts';
import MediaSchedulerCardsPlaceholder from './MediaSchedulerCardsPlaceholder';
import MediaSchedulerCard from './MediaSchedulerCard';
import { firstCharUp } from '../Tools/Utils';

function MediaSchedulerSearchActions({ media, handleChangeMedia, deleteSearchElements }) {
  return (
    <Dropdown
      basic
      button
      direction="left"
      value={media}
      options={[
        {
          key: 0,
          icon: 'tv',
          text: 'Série TV',
          value: 'tv',
        },
        {
          key: 1,
          icon: 'film',
          text: 'Film',
          value: 'movie',
          disabled: true,
        },
      ]}
      onChange={(e, { value }) => {
        handleChangeMedia(value);
        deleteSearchElements();
      }}
    />
  );
}

function MediaScheduler({ openMediaScheduler, setOpenMediaScheduler }) {
  const [state, setState] = useState({
    mediaType: 'tv',
    qualityProfiles: [],
    tags: [],
    elements: [],
    loading: false,
    error: false,
  });

  const deleteSearchElements = () => {
    setState((prevState) => ({
      ...prevState,
      elements: [],
    }));
  };

  const setSchedulerMediaType = (type) => {
    setState((prevState) => ({
      ...prevState,
      mediaType: type,
    }));
  };

  const getTags = () => {
    const tag = [];
    getSonarrTags()
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          if (response.data.result.length > 0) {
            response.data.result.forEach((elem) => {
              tag.push({
                key: elem.id,
                text: firstCharUp(elem.label),
                value: elem.id,
              });
            });
            setState((prevState) => ({
              ...prevState,
              tags: tag,
            }));
          } else {
            setState((prevState) => ({
              ...prevState,
              tags: [],
            }));
            notifError(
              'Impossible de récupérer les tags de Sonarr pour le moment, veuillez réessayer plus tard.'
            );
          }
        } else {
          setState((prevState) => ({
            ...prevState,
            tags: [],
          }));
          notifError(
            'Impossible de récupérer les tags de Sonarr pour le moment, veuillez réessayer plus tard.'
          );
        }
      })
      .catch((e) => {
        console.log(e);
        notifError(
          'Impossible de récupérer les tags de Sonarr pour le moment, veuillez réessayer plus tard.'
        );
        setState((prevState) => ({
          ...prevState,
          tags: [],
        }));
      });
  };

  const getQualityProfiles = () => {
    const profiles = [];
    getSonarrQualityProfiles()
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          if (response.data.result.length > 0) {
            response.data.result.forEach((profile) => {
              profiles.push({
                key: profile.id,
                text: profile.name,
                value: profile.id,
              });
            });
            setState((prevState) => ({
              ...prevState,
              qualityProfiles: profiles,
            }));
          } else {
            setState((prevState) => ({
              ...prevState,
              qualityProfiles: [],
            }));
            notifError(
              'Impossible de récupérer les profils de qualité de Sonarr pour le moment, veuillez réessayer plus tard.'
            );
          }
        } else {
          setState((prevState) => ({
            ...prevState,
            qualityProfiles: [],
          }));
          notifError(
            'Impossible de récupérer les profils de qualité de Sonarr pour le moment, veuillez réessayer plus tard.'
          );
        }
      })
      .catch((e) => {
        console.log(e);
        notifError(
          'Impossible de récupérer les profils de qualité de Sonarr pour le moment, veuillez réessayer plus tard.'
        );
        setState((prevState) => ({
          ...prevState,
          qualityProfiles: [],
        }));
      });
  };

  const getElements = (terms) => {
    setState((prevState) => ({
      ...prevState,
      elements: [],
      loading: true,
      error: false,
    }));
    searchSonarrMedia(terms)
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          const data = response.data.result;
          setState((prevState) => ({
            ...prevState,
            elements: data,
            loading: false,
            error: false,
          }));
          notifSuccess('Recherche terminée');
        } else {
          notifError('Échec de la recherche');
          setState((prevState) => ({
            ...prevState,
            elements: [],
            loading: false,
            error: true,
          }));
        }
      })
      .catch((e) => {
        setState((prevState) => ({
          ...prevState,
          elements: [],
          loading: false,
          error: true,
        }));
        notifError(`Échec de la recherche: ${e}`);
      });
  };

  const handleSearch = (event) => {
    if (event.key === 'Enter' && event.target.value.length > 1) {
      event.target.blur();
      getElements(event.target.value);
      getQualityProfiles();
      getTags();
    }
  };

  return (
    <Modal
      size="large"
      open={openMediaScheduler}
      onClose={() => setOpenMediaScheduler(false)}
      onOpen={() => setOpenMediaScheduler(true)}
      closeIcon={{ style: { top: '1rem', right: '1rem' }, color: 'black', name: 'close' }}
    >
      <Modal.Header>
        Ajout de {state.mediaType === 'tv' ? 'série' : 'film'} au calendrier de téléchargement
        automatique
      </Modal.Header>
      <Modal.Content>
        <Container fluid>
          <Search
            style={{ width: '100%' }}
            showNoResults={false}
            minCharacters={1}
            loading={state.loading}
            input={
              <Input
                fluid
                action={
                  <MediaSchedulerSearchActions
                    media={state.mediaType}
                    handleChangeMedia={setSchedulerMediaType}
                    deleteSearchElements={deleteSearchElements}
                  />
                }
                autoComplete="off"
                size="large"
                iconPosition="left"
                icon="search"
                type="text"
                placeholder={
                  state.mediaType === 'tv' ? 'Chercher une Série TV' : 'Chercher un Film'
                }
                onKeyUp={handleSearch}
              />
            }
          />
          <br />
          {state.loading ? (
            <MediaSchedulerCardsPlaceholder />
          ) : !state.error ? (
            state.elements.length > 0 ? (
              <Card.Group centered>
                {state.elements.map((elem) => {
                  return (
                    <MediaSchedulerCard
                      key={`${elem.title}-${elem.tmdbId}-${elem.tvdbId}`}
                      item={elem}
                      setOpenMediaScheduler={setOpenMediaScheduler}
                      schedulerMediaType={state.mediaType}
                      qualityProfiles={state.qualityProfiles}
                      tags={state.tags}
                    />
                  );
                })}
              </Card.Group>
            ) : (
              <Segment textAlign="center" placeholder>
                <Header icon>
                  <Icon name="info" color="teal" size="big" />
                  Aucun résultat
                </Header>
                Aucun résultat trouvé pour cette recherche
              </Segment>
            )
          ) : (
            <Segment textAlign="center" placeholder>
              <Header icon>
                <Icon name="exclamation triangle" color="orange" size="big" />
                Recherche indisponible
              </Header>
              Une erreur est survenue lors de la recherche
              <br />
              <br />
              <Button onClick={() => getElements()} color="teal">
                <Icon name="refresh" />
                Réessayer
              </Button>
            </Segment>
          )}
        </Container>
      </Modal.Content>
    </Modal>
  );
}

export default MediaScheduler;
