import React, { useEffect, useState } from 'react';
import { Button, Header, Icon, Loader, Segment, Tab, Table } from 'semantic-ui-react';
import { getAllUsers } from '../../services/Settings';
import UsersTabRow from './UsersTabRow';

function UsersTab() {
  const [state, setState] = useState({
    users: [],
    loading: true,
    error: false,
  });

  const getUsers = () => {
    getAllUsers()
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          setState({ users: response.data.result, loading: false, error: false });
        } else {
          console.log('Could not get users at the moment');
          console.log(response.status);
          setState({ users: [], loading: false, error: true });
        }
      })
      .catch((e) => {
        console.log(`Could not get users: ${e.message}`);
        setState({ users: [], loading: false, error: true });
      });
  };

  useEffect(() => {
    getUsers();
  }, []);

  const buildUserRow = state.users.map((user) => {
    return <UsersTabRow getUsers={getUsers} user={user} key={user.id} />;
  });

  return (
    <Tab.Pane className="row content transparent" attached="top">
      <Segment className="transparent-table" textAlign="center">
        <Header as="h2" icon color="teal">
          <Icon name="users" circular />
          <Header.Content>Utilisateurs</Header.Content>
        </Header>
      </Segment>
      {state.loading && <Loader active />}
      {!state.loading && state.users.length > 0 && (
        <Segment className="transparent-table" style={{ overflowX: 'scroll', height: '100vh' }}>
          <Table basic="very" unstackable selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell textAlign="center">ID</Table.HeaderCell>
                <Table.HeaderCell>Surnom</Table.HeaderCell>
                <Table.HeaderCell>Email</Table.HeaderCell>
                <Table.HeaderCell>Rôle</Table.HeaderCell>
                <Table.HeaderCell>Inscription</Table.HeaderCell>
                <Table.HeaderCell>Connexion</Table.HeaderCell>
                <Table.HeaderCell>Modifier</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>{buildUserRow}</Table.Body>
          </Table>
        </Segment>
      )}
      {state.error && (
        <Segment textAlign="center">
          <Icon name="exclamation" circular color="red" />
          <br />
          <br />
          Nous ne pouvons pas accéder à la liste des utilisateurs pour le moment.
          <br />
          <br />
          <Button color="orange" onClick={getUsers}>
            Réessayer
          </Button>
        </Segment>
      )}
    </Tab.Pane>
  );
}

export default UsersTab;
