import '../../assets/css/Subscription.css';
import React from 'react';
import { Icon, Input, Popup, Table } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { Media } from '../Tools/AppMedia';
import { SubscriptionTableRow, SubscriptionTableRowMobile } from './SubscriptionTableRow';
import { getNotificationEmailEnabled, getNotificationTokenEnabled } from '../../services/Auth';

function SubscriptionTable({ devices, data, subscriptionCallback, searchCallback }) {
  const buildTableHead = devices.map((device) => {
    return (
      <Table.HeaderCell
        key={`subscribe-head-device-${device.id}`}
        className="text-capitalize td-center"
      >
        <Icon name="server" /> {device.name}
      </Table.HeaderCell>
    );
  });

  const buildTable = data.tvshow_list.map((i) => {
    return (
      <React.Fragment key={`subscribe-row-${i.tvdbId}`}>
        <Media greaterThanOrEqual="lg">
          {(mediaClassNames, renderChildren) => {
            return renderChildren ? (
              <SubscriptionTableRow
                show={i}
                devices={devices}
                subscriptionCallback={subscriptionCallback}
              />
            ) : null;
          }}
        </Media>
        <Media lessThan="lg">
          {(mediaClassNames, renderChildren) => {
            return renderChildren ? (
              <SubscriptionTableRowMobile
                show={i}
                devices={devices}
                subscriptionCallback={subscriptionCallback}
              />
            ) : null;
          }}
        </Media>
      </React.Fragment>
    );
  });

  return (
    <Table className="transparent-table" unstackable compact selectable>
      <Table.Header>
        <Media greaterThanOrEqual="lg">
          {(mediaClassNames, renderChildren) => {
            return renderChildren ? (
              <Table.Row>
                <Table.HeaderCell className="td-center">
                  <Icon name="info" />
                </Table.HeaderCell>
                <Table.HeaderCell className="td-center">
                  <Icon name="image outline" />
                </Table.HeaderCell>
                <Table.HeaderCell className="td-left">
                  <Input
                    size="small"
                    placeholder="Nom de la série"
                    icon="search"
                    onKeyUp={(e) => searchCallback(e)}
                  />
                </Table.HeaderCell>
                {buildTableHead}
                <Table.HeaderCell className="td-center">
                  <Popup
                    content={
                      <span>
                        {getNotificationEmailEnabled() === 'true' ? (
                          <Icon name="checkmark" color="green" />
                        ) : (
                          <Icon name="close" color="red" />
                        )}{' '}
                        <Icon name="mail" /> Email
                        <br />
                        {getNotificationTokenEnabled() === 'true' ? (
                          <Icon name="checkmark" color="green" />
                        ) : (
                          <Icon name="close" color="red" />
                        )}{' '}
                        <Icon name="mobile" />
                        Mobile
                      </span>
                    }
                    position="top center"
                    trigger={
                      <span>
                        <Icon name="bell outline" /> <Link to="/account">Notification</Link>
                      </span>
                    }
                  />
                </Table.HeaderCell>
              </Table.Row>
            ) : null;
          }}
        </Media>
        <Media lessThan="lg">
          {(mediaClassNames, renderChildren) => {
            return renderChildren ? (
              <Table.Row>
                <Table.HeaderCell>Liste des abonnements</Table.HeaderCell>
              </Table.Row>
            ) : null;
          }}
        </Media>
      </Table.Header>
      <Table.Body>{buildTable}</Table.Body>
    </Table>
  );
}

export default SubscriptionTable;
