import React, { useEffect, useState } from 'react';
import { Button, Form, Grid, Header, Icon, Segment } from 'semantic-ui-react';
import NavBarAuth from '../components/NavBar/NavBarAuth';
import NavBarGuest from '../components/NavBar/NavBarGuest';
import { getEmail, getNickname } from '../services/Auth';
import { contactForm, getLocalServerConfig } from '../services/Server';
import { AuthConsumer } from '../contexts/AuthContext';
import { emailRegex } from '../components/Tools/Utils';
import BackgroundRandomizer from '../components/Tools/BackgroundRandomizer';

function ContactPage() {
  const [state, setState] = useState({
    result: '',
    sending: false,
    name: getNickname(),
    email: getEmail(),
    subject: '',
    success: false,
    message: '',
  });

  useEffect(() => {
    document.title = `${getLocalServerConfig().siteName || 'Ergo'} | Nous Contacter`;
  });

  const handleSubmitForm = () => {
    const { name, email, subject, message } = state;

    if (name === '') {
      setState((prevState) => ({ ...prevState, result: 'Le pseudo / nom ne peut pas être vide' }));
      return false;
    }
    if (email === '') {
      setState((prevState) => ({ ...prevState, result: "L'email ne peut pas être vide" }));
      return false;
    }
    if (!emailRegex.test(email)) {
      setState((prevState) => ({ ...prevState, result: 'Email incorrect' }));
      return false;
    }

    if (subject === '') {
      setState((prevState) => ({ ...prevState, result: 'Le sujet ne peut pas être vide' }));
      return false;
    }
    if (message === '') {
      setState((prevState) => ({ ...prevState, result: 'Le message ne peut pas être vide' }));
      return false;
    }
    setState((prevState) => ({ ...prevState, result: 'Envoi en cours...', sending: true }));
    contactForm({
      name,
      from: email,
      subject,
      message,
    })
      .then((response) => {
        if (response.status === 200 && response.data.success)
          setState((prevState) => ({
            ...prevState,
            result: response.data.message,
            success: true,
            sending: false,
          }));
        else
          setState((prevState) => ({
            ...prevState,
            result: `Échec de l'envoi du message: ${response.data.message}`,
            success: false,
            sending: false,
          }));
      })
      .catch((e) => {
        console.log(e);
        setState((prevState) => ({
          ...prevState,
          result: `Échec de l'envoi du message: ${e}`,
          success: false,
          sending: false,
        }));
      });
    return true;
  };

  const handleInputChange = (name, value) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <AuthConsumer>
      {({ isAuth }) => (
        <BackgroundRandomizer>
          {isAuth ? <NavBarAuth /> : <NavBarGuest />}
          <Grid
            className="no-margin-top"
            textAlign="center"
            style={{ overflowY: 'scroll', height: '100vh' }}
            verticalAlign="middle"
          >
            <Grid.Column style={{ maxWidth: 900, marginBottom: 20 }}>
              <Segment className="transparent-table">
                <br />
                <Header as="h1" color="teal" icon>
                  <Icon name="mail outline" circular />
                  Nous Contacter
                </Header>
                <br />
                <Header as="h3">
                  Vous avez une question ?
                  <br /> Vous voulez signaler un bug ou donner votre avis ?
                  <br />
                  N&apos;hésitez pas à nous contacter, nous vous répondrons dès que possible.
                </Header>
                <br />
                <br />
                <Form>
                  <Form.Group widths="equal">
                    {isAuth ? (
                      <Form.Input
                        fluid
                        label="Votre nom/pseudo"
                        placeholder="Votre nom / pseudo"
                        value={getNickname()}
                        disabled
                      />
                    ) : (
                      <Form.Input
                        onChange={(e) => handleInputChange('name', e.target.value)}
                        fluid
                        label="Votre nom / pseudo"
                        placeholder="Votre nom / pseudo"
                      />
                    )}
                    {isAuth ? (
                      <Form.Input
                        fluid
                        label="Votre email"
                        placeholder="Votre email"
                        value={getEmail()}
                        disabled
                      />
                    ) : (
                      <Form.Input
                        onChange={(e) => handleInputChange('email', e.target.value)}
                        fluid
                        label="Votre email"
                        placeholder="Votre email"
                      />
                    )}
                  </Form.Group>
                  <Form.Input
                    onChange={(e) => handleInputChange('subject', e.target.value)}
                    fluid
                    placeholder="Sujet"
                  />
                  <Form.TextArea
                    onChange={(e) => handleInputChange('message', e.target.value)}
                    placeholder="Votre message"
                  />
                </Form>
                <br />
                {state.result}
              </Segment>
              {!state.sending && (
                <>
                  {!state.success && (
                    <Button
                      icon="send"
                      label="Envoyer"
                      labelPosition="right"
                      color="teal"
                      size="large"
                      onClick={handleSubmitForm}
                    />
                  )}
                  <Button
                    icon="home"
                    label="Accueil"
                    labelPosition="right"
                    size="large"
                    as="a"
                    href="/"
                  />
                </>
              )}
            </Grid.Column>
          </Grid>
        </BackgroundRandomizer>
      )}
    </AuthConsumer>
  );
}

export default ContactPage;
