import React, { useState } from 'react';
import { Icon, Popup, Table } from 'semantic-ui-react';
import { Tooltip } from 'react-tooltip';
import { notifInfo } from '../Tools/NotificationToasts';
import { Media } from '../Tools/AppMedia';
import { humanFileSize } from '../Tools/Utils';
import DeviceTaskDeleteModal from '../Modal/DeviceTaskDeleteModal';

function SynologyTableRow({ device, handleTaskAction, item }) {
  const [open, setOpen] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);

  const buildActionCell = (i) => {
    switch (i.status) {
      case 'downloading':
        return (
          <>
            <Icon
              id={`task-action-cell-${i.id}`}
              name="stop"
              color="red"
              className="clickable"
              onClick={() => {
                notifInfo('Mise en pause de la tâche...');
                handleTaskAction(device.id, i.id, 'pause');
              }}
            />
            <Tooltip
              anchorId={`task-action-cell-${i.id}`}
              style={{ zIndex: 1 }}
              place="right"
              content="Mettre en pause"
            />
          </>
        );
      case 'paused':
        return (
          <>
            <Icon
              data-tip
              data-for={`task-action-cell-${i.id}`}
              name="play"
              color="green"
              className="clickable"
              onClick={() => {
                notifInfo('Reprise de la tâche...');
                handleTaskAction(device.id, i.id, 'resume');
              }}
            />
            <Tooltip
              anchorId={`task-action-cell-${i.id}`}
              style={{ zIndex: 1 }}
              place="right"
              content="Reprendre"
            />
          </>
        );
      case 'finished':
      case 'error':
      case 'waiting':
      default:
        return null;
    }
  };

  const buildStateCell = (i) => {
    switch (i.status) {
      case 'finished':
        return <Icon name="check" />;
      case 'error':
        return <Icon name="exclamation triangle" color="red" />;
      case 'waiting':
        return <Icon name="clock" />;
      case 'downloading':
        return <Icon name="cloud download" className="green-text" />;
      case 'paused':
        return <Icon name="pause" />;
      default:
        return null;
    }
  };

  const buildActionCellResponsive = (i) => {
    switch (i.status) {
      case 'downloading':
        return (
          <>
            <br />
            <span
              className="clickable"
              onClick={() => {
                notifInfo('Mise en pause de la tâche...');
                handleTaskAction(device.id, i.id, 'pause');
                setOpenPopup(false);
              }}
            >
              <Icon data-tip data-for={`task-action-cell-${i.id}`} name="stop" color="red" /> Pause
            </span>
            <br />
            <br />
            <span
              className="clickable"
              onClick={() => {
                setOpen(true);
                setOpenPopup(false);
              }}
            >
              <Icon
                data-tip
                data-for={`synology-delete-cell-${i.id}`}
                color="red"
                name="trash alternate outline"
              />{' '}
              Supprimer
            </span>
          </>
        );
      case 'paused':
        return (
          <>
            <br />
            <span
              className="clickable"
              onClick={() => {
                notifInfo('Reprise de la tâche...');
                handleTaskAction(device.id, i.id, 'resume');
                setOpenPopup(false);
              }}
            >
              <Icon data-tip data-for={`task-action-cell-${i.id}`} name="play" color="green" />{' '}
              Reprendre
            </span>
            <br />
            <br />
            <span
              className="clickable"
              onClick={() => {
                setOpen(true);
                setOpenPopup(false);
              }}
            >
              <Icon
                data-tip
                data-for={`synology-delete-cell-${i.id}`}
                color="red"
                name="trash alternate outline"
              />{' '}
              Supprimer
            </span>
          </>
        );
      case 'finished':
      case 'error':
      case 'waiting':
      default:
        return (
          <>
            <br />
            <span
              className="clickable"
              onClick={() => {
                setOpen(true);
                setOpenPopup(false);
              }}
            >
              <Icon
                data-tip
                data-for={`synology-delete-cell-${i.id}`}
                color="red"
                name="trash alternate outline"
              />{' '}
              Supprimer
            </span>
            <br />
          </>
        );
    }
  };

  const i = item;
  const percentage =
    i.size !== 0
      ? `${((i.additional.transfer.size_downloaded / i.size) * 100).toFixed(2)} %`
      : '0.00 %';
  return (
    <>
      <Media greaterThanOrEqual="lg">
        {(mediaClassNames, renderChildren) => {
          return renderChildren ? (
            <Table.Row
              className={
                i.status === 'paused'
                  ? 'warning'
                  : i.status === 'error'
                    ? 'negative'
                    : i.status === 'downloading'
                      ? 'positive'
                      : i.status === 'waiting'
                        ? 'active'
                        : ''
              }
            >
              <Table.Cell className="td-center">{buildActionCell(i)}</Table.Cell>
              <Table.Cell className="td-center">{buildStateCell(i)}</Table.Cell>
              <Table.Cell
                style={{ wordBreak: 'break-all' }}
                className={`td-left ${i.status !== 'finished' && 'font-weight-normal'}`}
              >
                {i.title}
              </Table.Cell>
              <Table.Cell className="td-center">
                {i.status === 'downloading' &&
                  `${
                    i.additional.transfer.speed_download !== 0
                      ? `${humanFileSize(i.additional.transfer.speed_download)}/s`
                      : '0'
                  } | ${
                    i.additional.transfer.speed_upload !== 0
                      ? `${humanFileSize(i.additional.transfer.speed_upload)}/s`
                      : '0 kb/s'
                  }`}
              </Table.Cell>
              <Table.Cell className="td-center font-weight-bolder">
                {i.status === 'finished' ? <Icon color="green" name="check" /> : percentage}
              </Table.Cell>
              <Table.Cell className="td-center">
                {new Date(i.additional.detail.create_time * 1000).toLocaleString()}
              </Table.Cell>
              <Table.Cell className="td-right font-weight-bolder">
                {`${(i.size / 1000 / 100 / 100 / 100).toFixed(2)} Go`}
              </Table.Cell>
              <Table.Cell className="td-center">
                <Icon
                  id={`synology-delete-cell-${i.id}`}
                  color="red"
                  className="clickable"
                  name="trash alternate outline"
                  onClick={() => {
                    setOpen(true);
                  }}
                />
                <Tooltip
                  anchorId={`synology-delete-cell-${i.id}`}
                  style={{ zIndex: 1 }}
                  place="left"
                  content="Supprimer la tâche"
                />
              </Table.Cell>
            </Table.Row>
          ) : null;
        }}
      </Media>
      <Media lessThan="lg">
        {(mediaClassNames, renderChildren) => {
          return renderChildren ? (
            <Table.Row
              className={
                i.status === 'paused'
                  ? 'warning'
                  : i.status === 'error'
                    ? 'negative'
                    : i.status === 'downloading'
                      ? 'positive'
                      : i.status === 'waiting'
                        ? 'active'
                        : ''
              }
            >
              <Table.Cell>
                {/* // Line 1 */}
                <div style={{ float: 'left', position: 'relative', width: '100%' }}>
                  <span
                    style={{ wordBreak: 'break-all' }}
                    className={i.status !== 'finished' ? 'font-weight-bolder' : ''}
                  >
                    {i.title}
                  </span>
                </div>
                {/* // Line 2 */}
                <div style={{ float: 'left', position: 'relative', width: '100%' }}>
                  <div
                    className="td-left"
                    style={{ float: 'left', position: 'relative', width: '5%' }}
                  >
                    <span>{buildStateCell(i)}</span>
                  </div>
                  <div
                    className="td-left"
                    style={{ float: 'left', position: 'relative', width: '45%' }}
                  >
                    {i.status === 'downloading'
                      ? `${
                          i.additional.transfer.speed_download !== 0
                            ? `${humanFileSize(i.additional.transfer.speed_download)}/s`
                            : '0'
                        } | ${
                          i.additional.transfer.speed_upload !== 0
                            ? `${humanFileSize(i.additional.transfer.speed_upload)}/s`
                            : '0 kb/s'
                        }`
                      : '-'}
                  </div>
                  <div
                    className="td-left font-weight-bolder"
                    style={{ float: 'left', position: 'relative', width: '40%' }}
                  >
                    {`${(i.size / 1000 / 100 / 100 / 100).toFixed(2)} Go`}
                  </div>
                  <div
                    className="td-right"
                    style={{ float: 'left', position: 'relative', width: '10%' }}
                  >
                    <Popup
                      on={['click']}
                      position="left center"
                      hideOnScroll
                      header="Actions:"
                      size="large"
                      open={openPopup}
                      onOpen={() => setOpenPopup(true)}
                      onClose={() => setOpenPopup(false)}
                      trigger={
                        <Icon
                          className="clickable"
                          name="angle double right"
                          color="teal"
                          size="big"
                        />
                      }
                      content={buildActionCellResponsive(i)}
                    />
                  </div>
                </div>
                {/* // Line 3 */}
                <div style={{ float: 'left', position: 'relative', width: '100%' }}>
                  <div
                    className="td-left font-weight-bolder"
                    style={{ float: 'left', position: 'relative', width: '50%' }}
                  >
                    {i.status === 'finished' ? (
                      <>
                        <Icon color="green" name="check" /> 100%
                      </>
                    ) : (
                      percentage
                    )}
                  </div>
                  <div
                    className="td-center"
                    style={{ float: 'left', position: 'relative', width: '50%' }}
                  >
                    {new Date(i.additional.detail.create_time * 1000).toLocaleString()}
                  </div>
                </div>
              </Table.Cell>
            </Table.Row>
          ) : null;
        }}
      </Media>
      <DeviceTaskDeleteModal
        open={open}
        deviceId={device.id}
        taskId={i.id}
        setOpen={setOpen}
        action="delete"
        handleDeleteTaskAction={handleTaskAction}
      />
    </>
  );
}

export default SynologyTableRow;
