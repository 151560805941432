import React, { useEffect, useState } from 'react';
import {
  Tab,
  Header,
  Icon,
  Segment,
  Table,
  Button,
  Grid,
  Radio,
  Input,
  Select,
  Loader,
} from 'semantic-ui-react';
import { getSettings, setSettings } from '../../services/Settings';
import { notifError, notifInfo, notifSuccess } from '../Tools/NotificationToasts';

function ServerTab() {
  const [state, setState] = useState({
    settings: {},
    loading: true,
    error: false,
  });

  const getServerConfig = () => {
    getSettings()
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          setState({ settings: response.data.result, loading: false, error: false });
        } else {
          console.log('Could not get settings at the moment');
          console.log(response.status);
          setState({ settings: {}, loading: false, error: true });
        }
      })
      .catch((e) => {
        console.log(`Could not get settings: ${e.message}`);
        setState({ settings: {}, loading: false, error: true });
      });
  };

  const setServerConfig = () => {
    setSettings({ settings: JSON.stringify(state.settings) })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          notifSuccess('Paramètres enregistrés');
          setTimeout(() => getServerConfig(), 1500);
        } else if (response.status === 200) {
          notifError(`Paramètres invalides: ${response.data.errors.toString()}`);
        } else {
          notifError("Echec d'enregistrement des paramètres");
        }
      })
      .catch((e) => {
        console.log(e);
        notifError("Echec d'enregistrement des paramètres");
        console.log(`Could not get settings: ${e.message}`);
      });
  };

  const handleInputChange = (group, key, val) => {
    const { settings } = state;
    if (group) settings[group][key] = val;
    else settings[key] = val;
    setState((prevState) => ({ ...prevState, settings }));
  };

  useEffect(() => {
    getServerConfig();
  }, []);

  return (
    <Tab.Pane className="row content transparent" attached="top">
      {state.loading && <Loader active />}
      <Segment className="transparent-table" textAlign="center">
        <Header as="h2" icon color="teal">
          <Icon name="hdd" circular />
          <Header.Content>Réglages du serveur</Header.Content>
        </Header>
        <br />
      </Segment>
      {!state.loading && state.error && (
        <Segment textAlign="center" placeholder>
          <Header icon>
            <Icon name="exclamation triangle" color="orange" size="big" />
            Configuration indisponible
          </Header>
          Une erreur est survenue lors du chargement des réglages
          <br />
          <br />
          <Button onClick={() => getServerConfig()} color="teal">
            <Icon name="refresh" />
            Réessayer
          </Button>
        </Segment>
      )}
      {!state.loading && !state.error && (
        <>
          <Segment className="transparent-table" style={{ overflowX: 'auto', height: '100vh' }}>
            <Segment>
              <Table columns={2} celled color="teal">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Général</Table.HeaderCell>
                    <Table.HeaderCell>Valeur</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>Logs standards</Table.Cell>
                    <Table.Cell>
                      <Radio
                        toggle
                        checked={state.settings.server.debug}
                        onChange={(e, val) => {
                          handleInputChange('server', 'debug', val.checked);
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Logs SQL</Table.Cell>
                    <Table.Cell>
                      <Radio
                        toggle
                        checked={state.settings.server.debugSql}
                        onChange={(e, val) => {
                          handleInputChange('server', 'debugSql', val.checked);
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Port</Table.Cell>
                    <Table.Cell>
                      <Input
                        fluid
                        placeholder="1-65535"
                        value={state.settings.server.port}
                        type="number"
                        min="1"
                        max="65535"
                        onChange={(e, val) => {
                          if (val.value)
                            handleInputChange('server', 'port', parseInt(val.value, 10));
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Nom du site</Table.Cell>
                    <Table.Cell>
                      <Input
                        fluid
                        placeholder="Ergo"
                        value={state.settings.server.siteName}
                        onChange={(e, val) => {
                          handleInputChange('server', 'siteName', val.value);
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Nom du serveur</Table.Cell>
                    <Table.Cell>
                      <Input
                        fluid
                        placeholder="Ergo"
                        value={state.settings.server.serverFullName}
                        onChange={(e, val) => {
                          handleInputChange('server', 'serverFullName', val.value);
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Adresse du serveur de fichiers</Table.Cell>
                    <Table.Cell>
                      <Input
                        fluid
                        placeholder="https://www.your_cdn.com"
                        value={state.settings.server.cdnUrl}
                        onChange={(e, val) => {
                          handleInputChange('server', 'cdnUrl', val.value);
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      Adresses autorisées CORS (liste des adresses autorisées à contacter le
                      serveur, séparés par une virgule)
                    </Table.Cell>
                    <Table.Cell>
                      <Input
                        fluid
                        placeholder="http://localhost,http://localhost:8080"
                        value={state.settings.server.crossOrigin.join(',')}
                        onChange={(e, val) => {
                          handleInputChange(
                            'server',
                            'crossOrigin',
                            val.value.replace(/\s/g, '').trim().split(',')
                          );
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Segment>
            <Segment>
              <Table columns={2} celled color="teal">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Réglages Email et Notifications</Table.HeaderCell>
                    <Table.HeaderCell>Valeur</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>Email de contact</Table.Cell>
                    <Table.Cell>
                      <Input
                        fluid
                        placeholder="your@email.com"
                        autoComplete="new-password"
                        value={state.settings.email.emailTo}
                        onChange={(e, val) => {
                          handleInputChange('email', 'emailTo', val.value);
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Login SMTP (adresse utilisée pour envoyer les emails)</Table.Cell>
                    <Table.Cell>
                      <Input
                        fluid
                        placeholder="your@email.com"
                        autoComplete="new-password"
                        value={state.settings.email.smtpLogin}
                        onChange={(e, val) => {
                          handleInputChange('email', 'smtpLogin', val.value);
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Password SMTP</Table.Cell>
                    <Table.Cell>
                      <Input
                        fluid
                        placeholder="YOUR_SMTP_PASSWORD"
                        autoComplete="new-password"
                        value={state.settings.email.smtpPassword}
                        onChange={(e, val) => {
                          handleInputChange('email', 'smtpPassword', val.value);
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Serveur SMTP</Table.Cell>
                    <Table.Cell>
                      <Input
                        fluid
                        placeholder="YOUR_SMTP_HOST"
                        autoComplete="new-password"
                        value={state.settings.email.smtpHost}
                        onChange={(e, val) => {
                          handleInputChange('email', 'smtpHost', val.value);
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Port du serveur SMTP</Table.Cell>
                    <Table.Cell>
                      <Input
                        fluid
                        placeholder="1-65535"
                        value={state.settings.email.smtpPort}
                        type="number"
                        min="1"
                        max="65535"
                        onChange={(e, val) => {
                          if (val.value)
                            handleInputChange('email', 'smtpPort', parseInt(val.value, 10));
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Connexion SMTP chiffrée</Table.Cell>
                    <Table.Cell>
                      <Select
                        options={[
                          { key: 'SSL/STLS', value: true, text: 'SSL/TLS' },
                          { key: 'STARTTLS', value: false, text: 'STARTTLS' },
                        ]}
                        value={state.settings.email.smtpSSLTLS}
                        onChange={(e, val) => {
                          handleInputChange('email', 'smtpSSLTLS', val.value);
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Webhook Discord de notifications</Table.Cell>
                    <Table.Cell>
                      <Input
                        fluid
                        placeholder="https://discord.com/api/webhooks/.../..."
                        value={state.settings.discord.webhook}
                        onChange={(e, val) => {
                          handleInputChange('discord', 'webhook', val.value);
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Webhook Discord d&apos;exceptions</Table.Cell>
                    <Table.Cell>
                      <Input
                        fluid
                        placeholder="https://discord.com/api/webhooks/.../..."
                        value={state.settings.discord.exceptionWebhook}
                        onChange={(e, val) => {
                          handleInputChange('discord', 'exceptionWebhook', val.value);
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Segment>
            <Segment>
              <Table columns={2} celled color="teal">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Répertoires et comportement</Table.HeaderCell>
                    <Table.HeaderCell>Valeur</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>Répertoire racine</Table.Cell>
                    <Table.Cell>
                      <Input
                        fluid
                        placeholder="/data/downloads/"
                        value={state.settings.directories.dirRoot}
                        onChange={(e, val) => {
                          handleInputChange('directories', 'dirRoot', val.value);
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Répertoire d&apos;uploads temporaire</Table.Cell>
                    <Table.Cell>
                      <Input
                        fluid
                        placeholder="/data/upload_tmp/"
                        value={state.settings.directories.dirUploadTmp}
                        onChange={(e, val) => {
                          handleInputChange('directories', 'dirUploadTmp', val.value);
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Répertoires de logs</Table.Cell>
                    <Table.Cell>
                      <Input
                        fluid
                        placeholder="/data/logs"
                        value={state.settings.directories.logFolder}
                        onChange={(e, val) => {
                          handleInputChange('directories', 'logFolder', val.value);
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      Jours avant de considérer un fichier comme &quot;récent&quot;
                    </Table.Cell>
                    <Table.Cell>
                      <Input
                        fluid
                        placeholder="1"
                        type="number"
                        min="1"
                        value={state.settings.directories.dirRecentAfterDays}
                        onChange={(e, val) => {
                          if (val.value)
                            handleInputChange(
                              'directories',
                              'dirRecentAfterDays',
                              parseInt(val.value, 10)
                            );
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      Jours avant de considérer un fichier comme &quot;vieux&quot;
                    </Table.Cell>
                    <Table.Cell>
                      <Input
                        fluid
                        type="number"
                        min="2"
                        placeholder="3"
                        value={state.settings.directories.dirOldAfterDays}
                        onChange={(e, val) => {
                          if (val.value)
                            handleInputChange(
                              'directories',
                              'dirOldAfterDays',
                              parseInt(val.value, 10)
                            );
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Segment>
            <Segment>
              <Table columns={2} celled color="teal">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Appareils externes</Table.HeaderCell>
                    <Table.HeaderCell>Valeur</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>Autoriser les appareils externes</Table.Cell>
                    <Table.Cell>
                      <Radio
                        toggle
                        checked={state.settings.devicesEnabled}
                        onChange={(e, val) => {
                          handleInputChange(null, 'devicesEnabled', val.checked);
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Freebox</Table.Cell>
                    <Table.Cell>
                      <Radio
                        toggle
                        checked={state.settings.freeboxEnabled}
                        disabled={!state.settings.devicesEnabled}
                        onChange={(e, val) => {
                          handleInputChange(null, 'freeboxEnabled', val.checked);
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      ID d&apos;application lors de l&apos;association avec les Freebox
                    </Table.Cell>
                    <Table.Cell>
                      <Input
                        fluid
                        disabled={!state.settings.devicesEnabled || !state.settings.freeboxEnabled}
                        placeholder="fr.ergo.ergo"
                        value={state.settings.freeboxAppId}
                        onChange={(e, val) => {
                          handleInputChange(null, 'freeboxAppId', val.value);
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>NAS Synology</Table.Cell>
                    <Table.Cell>
                      <Radio
                        toggle
                        checked={state.settings.synologyEnabled}
                        disabled={!state.settings.devicesEnabled}
                        onChange={(e, val) => {
                          handleInputChange(null, 'synologyEnabled', val.checked);
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Segment>
            <Segment>
              <Table columns={2} celled color="teal">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Fournisseur de torrents (Prowlarr)</Table.HeaderCell>
                    <Table.HeaderCell>Valeur</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>Recherche de torrents</Table.Cell>
                    <Table.Cell>
                      <Radio
                        toggle
                        checked={state.settings.torrentSearchEnabled}
                        onChange={(e, val) => {
                          handleInputChange(null, 'torrentSearchEnabled', val.checked);
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>URL de Prowlarr</Table.Cell>
                    <Table.Cell>
                      <Input
                        fluid
                        disabled={!state.settings.torrentSearchEnabled}
                        placeholder="https://yourProwlarrUrl.com"
                        value={state.settings.prowlarr.baseUrl}
                        onChange={(e, val) => {
                          handleInputChange('prowlarr', 'baseUrl', val.value);
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Clé API de Prowlarr</Table.Cell>
                    <Table.Cell>
                      <Input
                        fluid
                        disabled={!state.settings.torrentSearchEnabled}
                        placeholder="YOUR_PROWLARR_API_KEY"
                        value={state.settings.prowlarr.apiKey}
                        onChange={(e, val) => {
                          handleInputChange('prowlarr', 'apiKey', val.value);
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Segment>
            <Segment>
              <Table columns={2} celled color="teal">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Client torrent</Table.HeaderCell>
                    <Table.HeaderCell>Valeur</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>Client Torrent</Table.Cell>
                    <Table.Cell>
                      <Select
                        options={[
                          { key: 'disabled', value: 'disabled', text: 'Désactivé' },
                          { key: 'qbittorrent', value: 'QBITTORRENT', text: 'qBittorrent' },
                        ]}
                        value={state.settings.torrentEnabled}
                        onChange={(e, val) => {
                          handleInputChange(null, 'torrentEnabled', val.value);
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Adresse du client</Table.Cell>
                    <Table.Cell>
                      <Input
                        fluid
                        disabled={state.settings.torrentEnabled === 'disabled'}
                        placeholder="Hostname/IP Address"
                        value={state.settings.torrents.qbtHost}
                        onChange={(e, val) => {
                          handleInputChange('torrents', 'qbtHost', val.value);
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Port du client</Table.Cell>
                    <Table.Cell>
                      <Input
                        fluid
                        placeholder="6881"
                        type="number"
                        min="1"
                        max="65535"
                        value={state.settings.torrents.qbtPort}
                        disabled={state.settings.torrentEnabled === 'disabled'}
                        onChange={(e, val) => {
                          if (val.value)
                            handleInputChange('torrents', 'qbtPort', parseInt(val.value, 10));
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Protocole du client</Table.Cell>
                    <Table.Cell>
                      <Select
                        fluid
                        disabled={state.settings.torrentEnabled === 'disabled'}
                        options={[
                          { key: 'http', value: 'http', text: 'http' },
                          { key: 'https', value: 'https', text: 'https' },
                        ]}
                        value={state.settings.torrents.qbtProtocol}
                        onChange={(e, val) => {
                          handleInputChange('torrents', 'qbtProtocol', val.value);
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Nom d&apos;utilisateur du client</Table.Cell>
                    <Table.Cell>
                      <Input
                        fluid
                        placeholder="admin"
                        value={state.settings.torrents.qbtUser}
                        disabled={state.settings.torrentEnabled === 'disabled'}
                        onChange={(e, val) => {
                          handleInputChange('torrents', 'qbtUser', val.value);
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Password du client</Table.Cell>
                    <Table.Cell>
                      <Input
                        fluid
                        disabled={state.settings.torrentEnabled === 'disabled'}
                        placeholder="password"
                        value={state.settings.torrents.qbtPassword}
                        onChange={(e, val) => {
                          handleInputChange('torrents', 'qbtPassword', val.value);
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Répertoire de lancement auto des fichiers torrent</Table.Cell>
                    <Table.Cell>
                      <Input
                        fluid
                        placeholder="/data/start/"
                        value={state.settings.torrents.autoStartDir}
                        onChange={(e, val) => {
                          handleInputChange('torrents', 'autoStartDir', val.value);
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Répertoire des téléchargements en cours</Table.Cell>
                    <Table.Cell>
                      <Input
                        fluid
                        disabled={state.settings.torrentEnabled === 'disabled'}
                        placeholder="/data/tmp/"
                        value={state.settings.torrents.qbtTmpDir}
                        onChange={(e, val) => {
                          handleInputChange('torrents', 'qbtTmpDir', val.value);
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      Temps (minutes) avant arrêt automatique des torrents terminés
                    </Table.Cell>
                    <Table.Cell>
                      <Input
                        fluid
                        placeholder="0"
                        type="number"
                        min="0"
                        value={state.settings.torrents.autoStopMin}
                        disabled={state.settings.torrentEnabled === 'disabled'}
                        onChange={(e, val) => {
                          if (val.value)
                            handleInputChange('torrents', 'autoStopMin', parseInt(val.value, 10));
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      Temps (jours) avant suppression automatique des torrents terminés
                    </Table.Cell>
                    <Table.Cell>
                      <Input
                        fluid
                        type="number"
                        min="1"
                        disabled={state.settings.torrentEnabled === 'disabled'}
                        placeholder="21"
                        value={state.settings.torrents.autoDeleteOlderDays}
                        onChange={(e, val) => {
                          if (val.value)
                            handleInputChange(
                              'torrents',
                              'autoDeleteOlderDays',
                              parseInt(val.value, 10)
                            );
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Segment>
            <Segment>
              <Table columns={2} celled color="teal">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Séries TV (Sonarr)</Table.HeaderCell>
                    <Table.HeaderCell>Valeur</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>Calendrier de sorties des séries TV via Sonarr</Table.Cell>
                    <Table.Cell>
                      <Radio
                        toggle
                        checked={state.settings.sonarrEnabled}
                        onChange={(e, val) => {
                          handleInputChange(null, 'sonarrEnabled', val.checked);
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      Abonnements au téléchargements automatiques / notifications récupération
                    </Table.Cell>
                    <Table.Cell>
                      <Radio
                        toggle
                        checked={state.settings.subscriptionsEnabled}
                        disabled={!state.settings.sonarrEnabled}
                        onChange={(e, val) => {
                          handleInputChange(null, 'subscriptionsEnabled', val.checked);
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>URL de Sonarr</Table.Cell>
                    <Table.Cell>
                      <Input
                        fluid
                        disabled={!state.settings.sonarrEnabled}
                        placeholder="https://yourSonarrUrl.fr"
                        value={state.settings.sonarr.baseUrl}
                        onChange={(e, val) => {
                          handleInputChange('sonarr', 'baseUrl', val.value);
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Clé API de Sonarr</Table.Cell>
                    <Table.Cell>
                      <Input
                        fluid
                        disabled={!state.settings.sonarrEnabled}
                        placeholder="YOUR_SONARR_API_KEY"
                        value={state.settings.sonarr.apiKey}
                        onChange={(e, val) => {
                          handleInputChange('sonarr', 'apiKey', val.value);
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Segment>
            <Segment>
              <Table columns={2} celled color="teal">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Films (Radarr)</Table.HeaderCell>
                    <Table.HeaderCell>Valeur</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>Calendrier de sorties des films via Radarr</Table.Cell>
                    <Table.Cell>
                      <Radio
                        toggle
                        checked={state.settings.radarrEnabled}
                        onChange={(e, val) => {
                          handleInputChange(null, 'radarrEnabled', val.checked);
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>URL de Radarr</Table.Cell>
                    <Table.Cell>
                      <Input
                        fluid
                        disabled={!state.settings.radarrEnabled}
                        placeholder="https://yourRadarrUrl.fr"
                        value={state.settings.radarr.baseUrl}
                        onChange={(e, val) => {
                          handleInputChange('radarr', 'baseUrl', val.value);
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Clé API de Radarr</Table.Cell>
                    <Table.Cell>
                      <Input
                        fluid
                        disabled={!state.settings.radarrEnabled}
                        placeholder="YOUR_RADARR_API_KEY"
                        value={state.settings.radarr.apiKey}
                        onChange={(e, val) => {
                          handleInputChange('radarr', 'apiKey', val.value);
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Segment>
            <Segment>
              <Table columns={2} celled color="teal">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>
                      Service de suggestions de films et séries et d&apos;amélioration de détection
                      de nom des séries en auto-download (The MovieDB)
                    </Table.HeaderCell>
                    <Table.HeaderCell>Valeur</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>Activé</Table.Cell>
                    <Table.Cell>
                      <Radio
                        toggle
                        checked={state.settings.tmdbEnabled}
                        onChange={(e, val) => {
                          handleInputChange(null, 'tmdbEnabled', val.checked);
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Clé API The MovieDB</Table.Cell>
                    <Table.Cell>
                      <Input
                        fluid
                        disabled={!state.settings.tmdbEnabled}
                        placeholder="YOUR_TMDB_API_KEY"
                        value={state.settings.tmdb.apiKey}
                        onChange={(e, val) => {
                          handleInputChange('tmdb', 'apiKey', val.value);
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Base URL TheMovieDB</Table.Cell>
                    <Table.Cell>
                      <Input
                        fluid
                        disabled={!state.settings.tmdbEnabled}
                        placeholder="https://image.tmdb.org/t/p/"
                        value={state.settings.tmdb.baseUrl}
                        onChange={(e, val) => {
                          handleInputChange('tmdb', 'baseUrl', val.value);
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Segment>
            <Segment>
              <Table columns={2} celled color="teal">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Vérification d&apos;état du VPN</Table.HeaderCell>
                    <Table.HeaderCell>Valeur</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>VPN Checker</Table.Cell>
                    <Table.Cell>
                      <Radio
                        toggle
                        checked={state.settings.vpnCheckerEnabled}
                        onChange={(e, val) => {
                          handleInputChange(null, 'vpnCheckerEnabled', val.checked);
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      Adresse IPv4 publique du serveur (pour détecter la déconnexion du VPN)
                    </Table.Cell>
                    <Table.Cell>
                      <Input
                        fluid
                        disabled={!state.settings.vpnCheckerEnabled}
                        placeholder="xxx.xxx.xxx.xxx"
                        value={state.settings.server.serverPublicIp}
                        onChange={(e, val) => {
                          handleInputChange('server', 'serverPublicIp', val.value);
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Segment>
            <Segment>
              <Table columns={2} celled color="teal">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Fonctionnalités</Table.HeaderCell>
                    <Table.HeaderCell>Valeur</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>Création de fichiers Zip</Table.Cell>
                    <Table.Cell>
                      <Radio
                        toggle
                        checked={state.settings.zipEnabled}
                        onChange={(e, val) => {
                          handleInputChange(null, 'zipEnabled', val.checked);
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Segment>
          </Segment>
          <Grid>
            <Grid.Column textAlign="center">
              <Button
                size="large"
                label="Enregistrer"
                color="teal"
                labelPosition="right"
                icon="save"
                onClick={() => setServerConfig()}
              />
              <Button
                label="Annuler"
                labelPosition="right"
                icon="close"
                size="large"
                onClick={() => {
                  getServerConfig();
                  notifInfo('Modifications annulées');
                }}
              />
            </Grid.Column>
          </Grid>
        </>
      )}
    </Tab.Pane>
  );
}

export default ServerTab;
