import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Icon, Label, Loader, Popup, Segment, Tab, Table } from 'semantic-ui-react';
import { getSubscriptions } from '../../services/Tvshow';
import SubscriptionHistoryModal from '../Modal/SubscriptionHistoryModal';
import SubscriptionTable from './SubscriptionTable';
import { Media } from '../Tools/AppMedia';
import { getLocalServerConfig } from '../../services/Server';

function SubscriptionTab({ devices, setOpenMediaScheduler }) {
  const [openHistoryModal, setOpenHistoryModal] = useState(false);
  const [state, setState] = useState({
    subscriptions: [],
    filtered: [],
    loading: true,
    error: false,
  });

  const handleSearchFilter = (event) => {
    const term = event.target.value.toLowerCase().trim();
    if (event.key === 'Enter' && term.length > 1) {
      const filtered = { ...state.subscriptions };
      filtered.tvshow_list = filtered.tvshow_list.filter(
        (a) => a.title.toLowerCase().indexOf(term) > -1
      );
      setState((prevState) => ({
        ...prevState,
        filtered: { ...filtered },
      }));
    }
    if (term.length === 0) {
      setState((prevState) => ({
        ...prevState,
        filtered: state.subscriptions,
      }));
    }
  };

  const handleGetSubscriptions = () => {
    setState((prevState) => ({ ...prevState, subscriptions: [], loading: true, error: false }));
    getSubscriptions()
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          response.data.result.tvshow_list.sort((a, b) => {
            if (a.title < b.title) return -1;
            if (a.title > b.title) return 1;
            return 0;
          });
          response.data.result.tvshow_list.sort((a, b) => {
            if (a.ended && !b.ended) return 1;
            if (!a.ended && b.ended) return -1;
            return 0;
          });
          setState((prevState) => ({
            ...prevState,
            subscriptions: response.data.result,
            filtered: response.data.result,
            loading: false,
            error: false,
          }));
        } else
          setState((prevState) => ({
            ...prevState,
            subscriptions: [],
            filtered: [],
            loading: false,
            error: true,
          }));
      })
      .catch(() => {
        setState((prevState) => ({
          ...prevState,
          subscriptions: [],
          filtered: [],
          loading: false,
          error: true,
        }));
      });
  };

  useEffect(() => {
    handleGetSubscriptions();
  }, []);

  const handleModifySubscription = (indexerId, subscriberId, isUser, enabled) => {
    const { subscriptions } = state;
    subscriptions.tvshow_list = subscriptions.tvshow_list.map((show) => {
      if (show.tvdbId === indexerId) {
        if (enabled) {
          if (isUser) show.subscribers.users.push(subscriberId);
          else show.subscribers.devices.push(subscriberId);
        } else if (isUser) {
          const index = show.subscribers.users.indexOf(subscriberId);
          if (index !== -1) show.subscribers.users.splice(index, 1);
        } else {
          const index = show.subscribers.devices.indexOf(subscriberId);
          if (index !== -1) show.subscribers.devices.splice(index, 1);
        }
      }
      return show;
    });
    setState((prevState) => ({ ...prevState, subscriptions: { ...subscriptions } }));
  };

  return (
    <>
      <Tab.Pane className="row content transparent" attached="top">
        {state.loading ? (
          <Loader active />
        ) : state.error ? (
          <Segment textAlign="center">
            <Icon name="exclamation triangle" color="orange" size="big" />
            <br />
            <br />
            Échec de chargement des abonnements
            <br />
            <br />
            <Button color="blue" onClick={handleGetSubscriptions}>
              Réessayer
            </Button>
          </Segment>
        ) : (
          <>
            <Table compact>
              <Table.Body>
                <Table.Row>
                  <Table.Cell className="td-center">
                    Notifications & téléchargements automatiques{' '}
                    <Popup
                      on={['click']}
                      hideOnScroll
                      position="top right"
                      header="Information"
                      content={
                        <>
                          <br />
                          <span>
                            Rendez-vous sur
                            <br />
                            <Link to="/devices">Mes Appareils</Link> pour ajouter un
                            <Label color="teal">
                              <Icon name="server" />
                              NAS Synology / Freebox
                            </Label>
                            <br />
                            <br /> Si vous n&apos;avez pas d&apos;appareil,
                            <br /> utilisez <Icon name="bell outline" /> Notification pour être
                            simplement notifié de la disponibilité d&apos;un nouvel épisode sur{' '}
                            {getLocalServerConfig().siteName || 'Ergo'}.
                            <br />
                            <br />
                            <a target="_blank" rel="noopener noreferrer" href="https://ntfy.sh/">
                              Ntfy
                            </a>{' '}
                            permet d&apos;envoyer des notifications sur votre téléphone.
                            <br />
                            Vous pouvez modifier le topic Ntfy sur{' '}
                            <Link to="/account">Mon Compte</Link>
                            <br />
                          </span>
                        </>
                      }
                      trigger={
                        <Icon
                          name="question circle"
                          color="blue"
                          size="large"
                          className="clickable"
                        />
                      }
                    />
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
            <SubscriptionTable
              devices={devices}
              data={state.filtered}
              subscriptionCallback={handleModifySubscription}
              searchCallback={handleSearchFilter}
            />
          </>
        )}
      </Tab.Pane>
      {!state.loading && (
        <Table compact="very" className="transparent-table" unstackable>
          <Table.Body>
            <Media greaterThanOrEqual="lg">
              {(mediaClassNames, renderChildren) => {
                return renderChildren ? (
                  <Table.Row textAlign="center">
                    <Table.Cell>
                      <Button
                        size="small"
                        color="green"
                        icon="plus"
                        label="Ajouter une série"
                        labelPosition="left"
                        onClick={() => setOpenMediaScheduler(true)}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <Button
                        size="small"
                        color="blue"
                        icon="clock"
                        label="Historique téléchargements automatiques"
                        labelPosition="left"
                        onClick={() => setOpenHistoryModal(true)}
                      />
                    </Table.Cell>
                  </Table.Row>
                ) : null;
              }}
            </Media>
            <Media lessThan="lg">
              {(mediaClassNames, renderChildren) => {
                return renderChildren ? (
                  <Table.Row>
                    <Table.Cell textAlign="center">
                      <Button
                        size="small"
                        color="green"
                        icon="plus"
                        label="Ajouter une série"
                        labelPosition="left"
                        onClick={() => setOpenMediaScheduler(true)}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <Button
                        size="small"
                        color="blue"
                        icon="clock"
                        label="Historique"
                        labelPosition="left"
                        onClick={() => setOpenHistoryModal(true)}
                      />
                    </Table.Cell>
                  </Table.Row>
                ) : null;
              }}
            </Media>
          </Table.Body>
        </Table>
      )}
      <SubscriptionHistoryModal open={openHistoryModal} setOpen={setOpenHistoryModal} />
    </>
  );
}

export default SubscriptionTab;
