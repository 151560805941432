import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { Link } from 'react-router-dom';
import { Button, Checkbox, Header, Icon, Segment, Table } from 'semantic-ui-react';
import { decodeJwt } from 'jose';
import { Base64 } from 'js-base64';
import {
  getDisplayType,
  getEmail,
  getLastLogin,
  getNickname,
  getNotificationToken,
  getNotificationTokenEnabled,
  getNotificationEmailEnabled,
  getRegisteredAt,
  getRole,
  getUserId,
  getSessions,
  revoke,
  getToken,
} from '../services/Auth';
import NavBarAuth from '../components/NavBar/NavBarAuth';
import BackgroundRandomizer from '../components/Tools/BackgroundRandomizer';
import { notifError, notifSuccess } from '../components/Tools/NotificationToasts';
import InactivityHandler from '../components/Tools/InactivityHandler';
import { getSearchHistory } from '../services/Search';
import ClearSearchHistoryModal from '../components/Modal/ClearSearchHistoryModal';
import ChangeNicknameModal from '../components/Modal/ChangeNicknameModal';
import NotificationTokenModal from '../components/Modal/NotificationTokenModal';
import ChangePasswordModal from '../components/Modal/ChangePasswordModal';
import {
  enableNotificationEmail,
  enableNotificationToken,
  getLocalServerConfig,
} from '../services/Server';
import { testUserNotification } from '../services/Settings';
import NtfyGuideModal from '../components/Modal/NtfyGuideModal';

function AccountPage() {
  const [openChangeNicknameModal, setOpenNicknameModal] = useState(false);
  const [openTokenModal, setOpenTokenModal] = useState(false);
  const [openClearHistoryModal, setOpenClearHistoryModal] = useState(false);
  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const [openNtfyGuideModal, setOpenNtfyGuideModal] = useState(false);
  const [searchHistory, setSearchHistory] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [notificationEmailEnabled, setNotificationEmailEnabled] = useState(
    getNotificationEmailEnabled() === 'true'
  );
  const [notificationTokenEnabled, setNotificationTokenEnabled] = useState(
    getNotificationTokenEnabled() === 'true'
  );

  useEffect(() => {
    document.title = `${getLocalServerConfig().siteName || 'Ergo'} | Mon Compte`;
  }, []);

  const handleGetHistory = () => {
    getSearchHistory()
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          setSearchHistory(response.data.result);
        } else {
          setSearchHistory([]);
          notifError(`Échec de récupération de l'historique: ${response.data.message}`);
        }
      })
      .catch((e) => {
        setSearchHistory([]);
        notifError(`Échec de récupération de l'historique: ${e.message}`);
      });
  };

  const handleEnableNotifications = (type, enabled) => {
    if (type === 'email')
      enableNotificationEmail({ user_id: getUserId(), enabled })
        .then((response) => {
          if (response.status === 200 && response.data.success) {
            notifSuccess(`Notifications Email ${enabled ? 'activées' : 'désactivées'}`);
            localStorage.setItem('notificationEmailEnabled', enabled);
            setNotificationEmailEnabled(enabled);
          } else
            notifError(`Échec de modification des notifications Email: ${response.data.message}`);
        })
        .catch((e) => {
          notifError(`Échec de modification des notifications Email: ${e.message}`);
        });
    else if (type === 'ntfy')
      enableNotificationToken({ user_id: getUserId(), enabled })
        .then((response) => {
          if (response.status === 200 && response.data.success) {
            notifSuccess(`Notifications Mobile ${enabled ? 'activées' : 'désactivées'}`);
            localStorage.setItem('notificationTokenEnabled', enabled);
            setNotificationTokenEnabled(enabled);
          } else
            notifError(`Échec de modification des notifications mobile: ${response.data.message}`);
        })
        .catch((e) => {
          notifError(`Échec de modification des notifications mobile: ${e.message}`);
        });
  };

  const handleTestUserNotification = () => {
    testUserNotification()
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          notifSuccess('Notification envoyée');
        } else notifError(`Échec d'envoi de la notification: ${response.data.message}`);
      })
      .catch((e) => {
        notifError(`Échec d'envoi de la notification: ${e.message}`);
      });
  };

  const handleGetSession = () => {
    getSessions()
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          setSessions(response.data.result);
        } else {
          setSessions([]);
          notifError(`Échec de récupération des sessions: ${response.data.message}`);
        }
      })
      .catch((e) => {
        setSessions([]);
        notifError(`Échec de récupération des sessions: ${e.message}`);
      });
  };

  const handleRevokeSession = (aud) => {
    revoke({ aud })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          handleGetSession();
          notifSuccess('Session révoquée');
        } else notifError(`Échec de récupération des sessions: ${response.data.message}`);
      })
      .catch((e) => {
        notifError(`Échec de révoquation de la session: ${e.message}`);
      });
  };

  const buildSessionsTable = () => {
    const decode = decodeJwt(getToken());
    return sessions.map((session) => {
      return (
        <Table.Row key={session.jti}>
          <Table.Cell>{`${new Date(session.iat * 1000).toLocaleDateString()} - ${new Date(
            session.iat * 1000
          ).toLocaleTimeString()}`}</Table.Cell>
          <Table.Cell>{Base64.decode(session.aud)}</Table.Cell>
          <Table.Cell textAlign="right">
            {decode.jti === session.jti ? (
              'Session actuelle'
            ) : (
              <Button
                icon="broken chain"
                label="Révoquer"
                labelPosition="left"
                size="mini"
                color="red"
                onClick={() => handleRevokeSession(session.aud)}
              />
            )}
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  useEffect(() => {
    handleGetHistory();
    handleGetSession();
  }, []);

  return (
    <InactivityHandler>
      <BackgroundRandomizer>
        <NavBarAuth />
        <ToastContainer />
        <Segment
          className="transparent"
          textAlign="center"
          style={{ overflowY: 'scroll', height: '100vh' }}
        >
          <Segment className="transparent-table" style={{ overflowX: 'auto' }}>
            <Header as="h2" icon color="teal">
              <Icon name="user" circular />
              <Header.Content>Mon Compte</Header.Content>
            </Header>
            <Table basic="very" selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell />
                  <Table.HeaderCell />
                  <Table.HeaderCell />
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>Pseudo:</Table.Cell>
                  <Table.Cell>{getNickname()} </Table.Cell>
                  <Table.Cell className="td-right">
                    <Button
                      icon="pencil"
                      label="Modifier"
                      labelPosition="left"
                      size="mini"
                      color="blue"
                      onClick={() => setOpenNicknameModal(true)}
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Email:</Table.Cell>
                  <Table.Cell>{getEmail()}</Table.Cell>
                  <Table.Cell />
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Mot de passe:</Table.Cell>
                  <Table.Cell>********** </Table.Cell>
                  <Table.Cell className="td-right">
                    <Button
                      icon="pencil"
                      label="Modifier"
                      labelPosition="left"
                      size="mini"
                      color="orange"
                      onClick={() => setOpenPasswordModal(true)}
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Date d&apos;inscription:</Table.Cell>
                  <Table.Cell>
                    {new Date(getRegisteredAt()).toLocaleString('fr-FR', {
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric',
                    })}
                  </Table.Cell>
                  <Table.Cell />
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Dernière ouverture de session:</Table.Cell>
                  <Table.Cell>
                    {new Date(getLastLogin()).toLocaleString('fr-FR', {
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric',
                    })}
                  </Table.Cell>
                  <Table.Cell />
                </Table.Row>
                <Table.Row>
                  <Table.Cell>User ID:</Table.Cell>
                  <Table.Cell>{getUserId()}</Table.Cell>
                  <Table.Cell />
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Niveau d&apos;accès:</Table.Cell>
                  <Table.Cell>{getRole()}</Table.Cell>
                  <Table.Cell />
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Historique de recherche:</Table.Cell>
                  <Table.Cell>
                    {searchHistory.length} élément{searchHistory.length !== 1 ? 's ' : ' '}
                  </Table.Cell>
                  <Table.Cell className="td-right">
                    <Button
                      icon="trash alternate"
                      label="Vider"
                      labelPosition="left"
                      size="mini"
                      color="red"
                      onClick={() => setOpenClearHistoryModal(true)}
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Mode d&apos;affichage du contenu des répertoires:</Table.Cell>
                  <Table.Cell>
                    {getDisplayType() === 'group'
                      ? 'Groupé par âge, Dossiers en tête, Ordre alphanumérique (group)'
                      : 'Listing simple, Dossiers en tête, Ordre Alphanumérique (list)'}
                  </Table.Cell>
                  <Table.Cell />
                </Table.Row>
              </Table.Body>
            </Table>
          </Segment>
          <Segment className="transparent-table">
            <Header as="h2" icon color="teal">
              <Icon name="bell" circular />
              <Header.Content>Notifications</Header.Content>
            </Header>
            <Table basic="very" selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Type</Table.HeaderCell>
                  <Table.HeaderCell>Configuration</Table.HeaderCell>
                  <Table.HeaderCell />
                  <Table.HeaderCell />
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    <Icon name="mail" />
                    Email
                  </Table.Cell>
                  <Table.Cell textAlign="left">
                    {notificationEmailEnabled ? 'Activées' : 'Désactivées'}
                  </Table.Cell>
                  <Table.Cell />
                  <Table.Cell textAlign="right">
                    <Checkbox
                      toggle
                      checked={notificationEmailEnabled}
                      onChange={() =>
                        handleEnableNotifications('email', getNotificationEmailEnabled() !== 'true')
                      }
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    <Icon name="mobile alternate" />
                    Mobile par{' '}
                    <span className="clickable" onClick={() => setOpenNtfyGuideModal(true)}>
                      Ntfy
                    </span>{' '}
                    :
                  </Table.Cell>
                  <Table.Cell textAlign="left">
                    {notificationTokenEnabled ? (
                      <>
                        Activées sur le topic{' '}
                        {getNotificationToken() !== '' ? getNotificationToken() : '-'}{' '}
                        <Button
                          icon="pencil"
                          size="mini"
                          color="blue"
                          onClick={() => setOpenTokenModal(true)}
                        />
                      </>
                    ) : (
                      'Désactivées'
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    <Button
                      icon="help"
                      label="Aide"
                      labelPosition="left"
                      size="mini"
                      color="teal"
                      onClick={() => setOpenNtfyGuideModal(true)}
                    />
                    <Button
                      icon="send"
                      label="Tester"
                      labelPosition="left"
                      size="mini"
                      color="green"
                      disabled={!notificationTokenEnabled}
                      onClick={() => handleTestUserNotification()}
                    />
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    <Checkbox
                      toggle
                      checked={notificationTokenEnabled}
                      onChange={() =>
                        handleEnableNotifications('ntfy', getNotificationTokenEnabled() !== 'true')
                      }
                    />
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Segment>
          <Segment className="transparent-table">
            <Header as="h2" icon color="teal">
              <Icon name="chain" circular />
              <Header.Content>Sessions ouvertes</Header.Content>
            </Header>
            <Table basic="very" selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Date</Table.HeaderCell>
                  <Table.HeaderCell>Session</Table.HeaderCell>
                  <Table.HeaderCell />
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {sessions.length === 0 ? (
                  <Table.Row>
                    <Table.Cell>
                      <Icon name="spinner" loading />
                    </Table.Cell>
                    <Table.Cell />
                    <Table.Cell />
                  </Table.Row>
                ) : (
                  buildSessionsTable()
                )}
              </Table.Body>
            </Table>
          </Segment>
          <Button
            icon="home"
            label="Accueil"
            labelPosition="right"
            as={Link}
            color="teal"
            size="large"
            to="/"
          />
          <Button
            icon="server"
            label="Mes Appareils"
            labelPosition="right"
            as={Link}
            color="blue"
            size="large"
            to="/devices"
          />
          <Button
            icon="mail"
            label="Nous Contacter"
            labelPosition="right"
            as={Link}
            color="grey"
            size="large"
            to="/contact"
          />
        </Segment>
        <ChangeNicknameModal open={openChangeNicknameModal} setOpen={setOpenNicknameModal} />
        <NotificationTokenModal open={openTokenModal} setOpen={setOpenTokenModal} />
        <ClearSearchHistoryModal
          open={openClearHistoryModal}
          setOpen={setOpenClearHistoryModal}
          getHistory={handleGetHistory}
        />
        <ChangePasswordModal open={openPasswordModal} setOpen={setOpenPasswordModal} />
        <NtfyGuideModal open={openNtfyGuideModal} setOpen={setOpenNtfyGuideModal} />
      </BackgroundRandomizer>
    </InactivityHandler>
  );
}

export default AccountPage;
