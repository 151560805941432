import React, { useEffect } from 'react';
import { Button, Grid, Header, Icon, Segment } from 'semantic-ui-react';
import NavBarGuest from '../components/NavBar/NavBarGuest';
import BackgroundRandomizer from '../components/Tools/BackgroundRandomizer';
import { getLocalServerConfig } from '../services/Server';

function NotFoundPage() {
  useEffect(() => {
    document.title = `${getLocalServerConfig().siteName || 'Ergo'} | 404 Page Introuvable`;
  }, []);

  return (
    <BackgroundRandomizer>
      <NavBarGuest />
      <Grid textAlign="center" style={{ height: '100vh' }} verticalAlign="middle">
        <Grid.Column style={{ maxWidth: 900 }}>
          <Segment className="transparent-table">
            <Icon name="exclamation triangle" size="huge" color="teal" circular />
            <Header as="h1" color="teal">
              Page Introuvable
            </Header>
            <Header as="h3">Erreur 404</Header>
          </Segment>
          <Button
            icon="home"
            label="Accueil"
            labelPosition="right"
            as="a"
            color="teal"
            size="large"
            href="/"
          />
        </Grid.Column>
      </Grid>
    </BackgroundRandomizer>
  );
}

export default NotFoundPage;
