import React, { useState } from 'react';
import {
  Header,
  Menu,
  Icon,
  Label,
  Segment,
  Tab,
  Popup,
  CommentText,
  Button,
} from 'semantic-ui-react';
import SearchTab from '../Search/SearchTab';
import CloudHistoryModal from '../Modal/CloudHistoryModal';

function TabBarSearch({
  searchTabs,
  deleteSearchTab,
  setOpenDiscovery,
  activeSearchIndex,
  handleSearchTabChange,
  setSearchInput,
  focusSearchInput,
}) {
  const [open, setOpen] = useState(false);

  const tabs = searchTabs.map((tab) => {
    return {
      menuItem: (
        <Menu.Item
          key={`search-tab-${tab.id}`}
          onMouseUp={(e) => {
            if (e.button === 1 && !tab.loading) {
              deleteSearchTab(tab.id);
              e.preventDefault();
              e.stopPropagation();
            }
          }}
        >
          {tab.loading && <Icon name="spinner" loading />}
          <Popup
            trigger={
              <CommentText>
                {tab.terms.slice(0, window.innerWidth > 1210 ? 30 : 6)}
                {window.innerWidth < 1210 && tab.terms.length > 6 && '...'}
              </CommentText>
            }
            content={tab.terms}
            position="bottom center"
          />
          {window.innerWidth > 1210 && !tab.loading && (
            <Label color={tab.data.length === 0 ? 'orange' : 'green'}>{tab.data.length}</Label>
          )}{' '}
          {!tab.loading && (
            <Popup
              trigger={
                <Icon
                  name="close"
                  color="red"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    deleteSearchTab(tab.id);
                  }}
                />
              }
              position="bottom center"
              content="Fermer l'onglet"
            />
          )}
        </Menu.Item>
      ),
      render: () => (
        <SearchTab
          terms={tab.terms}
          searchData={tab.data}
          searchLoading={tab.loading}
          searchError={tab.error}
          setOpenDiscovery={setOpenDiscovery}
        />
      ),
    };
  });

  return searchTabs.length > 0 ? (
    <Tab
      className="row content"
      renderActiveOnly
      menu={{
        attached: 'top',
        className: 'row footer transparent-table',
      }}
      activeIndex={activeSearchIndex}
      onTabChange={handleSearchTabChange}
      panes={tabs}
    />
  ) : (
    <Tab.Pane className="row content transparent" attached="top">
      <Segment color="teal" textAlign="center" placeholder>
        <Header icon>
          <Icon name="search" color="teal" />
          Tapez votre recherche ci-dessus et appuyez sur Entrée
        </Header>
        <Button basic size="small" color="blue" onClick={() => setOpen(true)}>
          <Icon name="clock" />
          Historique
        </Button>
        <Label
          basic
          color={
            [
              'red',
              'orange',
              'yellow',
              'olive',
              'green',
              'teal',
              'blue',
              'violet',
              'purple',
              'pink',
              'brown',
              'grey',
              'black',
            ][Math.floor(Math.random() * 13)]
          }
          attached="bottom"
          className="clickable"
          onClick={() => setOpenDiscovery(true)}
        >
          À court d&apos;idées ? Rendez-vous dans Discovery <Icon name="fire" />
        </Label>
      </Segment>
      <CloudHistoryModal
        open={open}
        setOpen={setOpen}
        setSearchInput={setSearchInput}
        focusSearchInput={focusSearchInput}
      />
    </Tab.Pane>
  );
}

export default TabBarSearch;
