import React from 'react';
import { Icon, Label, Table } from 'semantic-ui-react';
import { Media } from '../Tools/AppMedia';

function DeviceTableHeader({ device }) {
  return (
    <Table.Header>
      <Media greaterThanOrEqual="lg">
        {(mediaClassNames, renderChildren) => {
          return renderChildren ? (
            <Table.Row>
              <Table.HeaderCell className="td-center">
                <Icon name="tasks" />
              </Table.HeaderCell>
              <Table.HeaderCell className="td-center">
                <Icon name="chart bar" />
              </Table.HeaderCell>
              <Table.HeaderCell className="td-left">Nom</Table.HeaderCell>
              <Table.HeaderCell className="td-center">
                <Icon name="exchange" rotated="clockwise" />
              </Table.HeaderCell>
              <Table.HeaderCell className="td-center">
                <Icon name="percent" />
              </Table.HeaderCell>
              <Table.HeaderCell className="td-center">
                <Icon name="calendar check outline" /> Ajout
              </Table.HeaderCell>
              <Table.HeaderCell className="td-right">Taille</Table.HeaderCell>
              <Table.HeaderCell className="td-center">
                <Icon name="trash alternate" />
              </Table.HeaderCell>
            </Table.Row>
          ) : null;
        }}
      </Media>
      <Media lessThan="lg">
        {(mediaClassNames, renderChildren) => {
          return renderChildren ? (
            <Table.Row>
              <Table.HeaderCell>
                Liste des tâches <Label color="teal">{device.name}</Label>
              </Table.HeaderCell>
            </Table.Row>
          ) : null;
        }}
      </Media>
    </Table.Header>
  );
}

export default DeviceTableHeader;
