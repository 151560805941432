import React from 'react';
import { Icon, Popup, Table } from 'semantic-ui-react';
import SubscriptionTableSwitch from './SubscriptionTableSwitch';
import { getUserId } from '../../services/Auth';
import { getLocalServerConfig } from '../../services/Server';
import SubscriptionIndexerPopup from './SubscriptionIndexerPopup';

const { REACT_APP_API_URL: apiUrl } = process.env;

function SubscriptionTableRow({ show, devices, subscriptionCallback }) {
  return (
    <Table.Row>
      <Table.Cell className="td-center" disabled={show.ended}>
        <SubscriptionIndexerPopup show={show} />
      </Table.Cell>
      <Table.Cell className="subscribe-indexerid-img-td">
        <img
          className="valign-middle"
          alt={show.title}
          src={`${apiUrl}/api/v1/tvshows/banner/${show.id}`}
          width={140}
          height={35}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = `${window.origin}/placeholder.png`;
          }}
        />
      </Table.Cell>
      <Table.Cell className="td-left" color="red">
        <Popup
          content="Description sur TheMovieDB"
          position="right center"
          trigger={
            <a
              href={
                getLocalServerConfig().tmdbEnabled
                  ? `${apiUrl}/api/v1/tvshows/getTmdbPageByTvdbId/${show.tvdbId}`
                  : `https://www.themoviedb.org/search/tv?query=${show.title}`
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <span style={{ color: show.ended ? 'lightgrey' : 'black' }}>
                {show.title}
                {show.ended && ' (Série terminée)'}{' '}
              </span>
              <Icon name="external" size="small" color="teal" />
            </a>
          }
        />
      </Table.Cell>
      {devices.map((device) => (
        <Popup
          key={`subscribe-switch-device-${device.id}-indexerid-${show.tvdbId}`}
          content={device.name}
          position="top center"
          trigger={
            <td className="td-center">
              <SubscriptionTableSwitch
                enabled={show.subscribers.devices.includes(device.id)}
                subscriberId={device.id}
                isUser="false"
                indexerId={show.tvdbId}
                subscriptionCallback={subscriptionCallback}
              />
            </td>
          }
        />
      ))}
      <Popup
        content={<span>Notification uniquement</span>}
        position="top center"
        trigger={
          <Table.Cell className="td-center">
            <SubscriptionTableSwitch
              enabled={show.subscribers.users.includes(getUserId())}
              subscriberId={getUserId()}
              isUser="true"
              indexerId={show.tvdbId}
              subscriptionCallback={subscriptionCallback}
            />
          </Table.Cell>
        }
      />
    </Table.Row>
  );
}

function SubscriptionTableRowMobile({ show, devices, subscriptionCallback }) {
  return (
    <Table.Row>
      <Table.Cell>
        <div style={{ float: 'left', position: 'relative', width: '100%' }}>
          <div style={{ float: 'left', position: 'relative', width: '45%' }}>
            <img
              className="calendar-table-cell"
              alt=""
              src={`${apiUrl}/api/v1/tvshows/banner/${show.id}`}
              width={140}
              height={35}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = `${window.origin}/placeholder.png`;
              }}
            />
          </div>
          <div style={{ float: 'left', position: 'relative', width: '55%' }}>
            <div style={{ float: 'left', position: 'relative', width: '100%' }}>
              <div
                className="td-right"
                style={{
                  float: 'left',
                  position: 'relative',
                  width: '100%',
                  color: show.ended ? 'lightgrey' : 'black',
                }}
              >
                <SubscriptionIndexerPopup show={show} mobile />
              </div>
            </div>
            <div
              className="td-right"
              style={{ float: 'left', position: 'relative', width: '100%' }}
            >
              <span>
                <img
                  alt=""
                  src={`${apiUrl}/api/v1/tvshows/networkLogo/${show.tvdbId}`}
                  height="20px"
                />
              </span>
            </div>
          </div>
        </div>
        <div style={{ float: 'left', position: 'relative', width: '100%' }}>
          <div className="td-left" style={{ float: 'left', position: 'relative', width: '100%' }}>
            <span className="font-weight-bolder">
              <Popup
                content="Description sur TheMovieDB"
                trigger={
                  <a
                    href={`https://www.themoviedb.org/search/tv?query=${show.title}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span style={{ color: show.ended ? 'lightgrey' : 'black' }}>
                      {show.title}
                      {show.ended && ' (Série terminée)'}{' '}
                    </span>
                    <Icon name="external" size="small" color="teal" />
                  </a>
                }
              />
            </span>
          </div>
        </div>
        {devices.map((device) => {
          return (
            <div
              style={{
                float: 'left',
                position: 'relative',
                width: '100%',
                paddingTop: '10px',
                paddingBottom: '10px',
              }}
              className="td-center"
              key={`subscribe-switch-device-${device.id}-indexerid-${show.tvdbId}`}
            >
              <div
                className="td-left"
                style={{ float: 'left', position: 'relative', width: '80%' }}
              >
                <Icon name="server" />
                <span className="text-capitalize">{device.name}</span>
              </div>
              <div
                className="td-right"
                style={{ float: 'left', position: 'relative', width: '20%' }}
              >
                <SubscriptionTableSwitch
                  enabled={show.subscribers.devices.includes(device.id)}
                  subscriberId={device.id}
                  isUser="false"
                  indexerId={show.tvdbId}
                  subscriptionCallback={subscriptionCallback}
                />
              </div>
            </div>
          );
        })}
        <div
          style={{
            float: 'left',
            position: 'relative',
            width: '100%',
            paddingTop: '10px',
            paddingBottom: '10px',
          }}
          className="td-center"
        >
          <div className="td-left" style={{ float: 'left', position: 'relative', width: '80%' }}>
            <Icon name="bell" />
            <span className="text-capitalize">Notification</span>
          </div>
          <div className="td-right" style={{ float: 'left', position: 'relative', width: '20%' }}>
            <SubscriptionTableSwitch
              enabled={show.subscribers.users.includes(getUserId())}
              subscriberId={getUserId()}
              isUser="true"
              indexerId={show.tvdbId}
              subscriptionCallback={subscriptionCallback}
            />
          </div>
        </div>
      </Table.Cell>
    </Table.Row>
  );
}

export { SubscriptionTableRow, SubscriptionTableRowMobile };
