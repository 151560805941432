import React from 'react';
import { Icon, Label, Table } from 'semantic-ui-react';
import { Tooltip } from 'react-tooltip';
import { Media } from '../Tools/AppMedia';
import { getLocalServerConfig } from '../../services/Server';
import DirectoryTableHeaderDevicesPopup from './DirectoryTableHeaderDevicesCell';

function DirectoryTableHeader({
  devices,
  age,
  count,
  checkedItemsList,
  checkedItems,
  setOpenDelete,
  setOpenZip,
  setOpenMkdir,
  directoryCallback,
}) {
  const ageLabel = (() => {
    switch (age) {
      case 'new':
        return (
          <Label horizontal color="red">
            Nouveau ({count})
          </Label>
        );
      case 'recent':
        return (
          <Label horizontal color="orange">
            Récent ({count})
          </Label>
        );
      case 'old':
        return (
          <Label horizontal color="black">
            Corbeille ({count})
          </Label>
        );
      case 'error':
        return (
          <Label horizontal color="purple">
            Fichiers illisibles / Verrouillés ({count})
          </Label>
        );
      default:
        return <Label horizontal>Répertoire Vide</Label>;
    }
  })();

  const noCheckedFolder = checkedItemsList.filter((item) => item.type === 'dir').length === 0;

  return (
    <>
      <Media greaterThanOrEqual="lg">
        {(mediaClassNames, renderChildren) => {
          return renderChildren ? (
            <Table.Row className="tr-header">
              <Table.Cell className="td-center" collapsing>
                <Icon
                  id={`mkdir-row-header-${age}`}
                  className="clickable"
                  name="plus square outline"
                  color="green"
                  onClick={() => setOpenMkdir(true)}
                />
                <Tooltip
                  anchorId={`mkdir-row-header-${age}`}
                  style={{ zIndex: 1 }}
                  place="right"
                  content="Nouveau dossier"
                />
                {getLocalServerConfig().devicesEnabled && (
                  <>
                    <DirectoryTableHeaderDevicesPopup
                      devices={devices}
                      items={checkedItemsList}
                      directoryCallback={directoryCallback}
                      noCheckedFolder={noCheckedFolder}
                      age={age}
                      trigger={
                        <span>
                          <Icon
                            id={`download-row-header-${age}`}
                            className={checkedItems && noCheckedFolder ? 'clickable blink' : ''}
                            name="download"
                            color={checkedItems && noCheckedFolder ? 'teal' : 'black'}
                          />
                          <Tooltip
                            anchorId={`download-row-header-${age}`}
                            place="right"
                            style={{ zIndex: 1 }}
                            content={
                              noCheckedFolder
                                ? 'Télécharger vers un appareil'
                                : 'Cocher uniquement des fichiers'
                            }
                          />
                        </span>
                      }
                    />{' '}
                  </>
                )}
                {getLocalServerConfig().zipEnabled && (
                  <>
                    <Icon
                      id={`zip-row-header-${age}`}
                      className={checkedItems ? 'clickable blink' : ''}
                      name="file archive outline"
                      color={checkedItems ? 'blue' : 'black'}
                      onClick={checkedItems ? () => setOpenZip(true) : () => {}}
                    />
                    <Tooltip
                      anchorId={`zip-row-header-${age}`}
                      style={{ zIndex: 1 }}
                      place="right"
                      content={
                        checkedItems ? 'Zipper plusieurs éléments' : 'Cocher des éléments à zipper'
                      }
                    />{' '}
                  </>
                )}
                <Icon
                  id={`delete-row-header-${age}`}
                  className={checkedItems ? 'clickable blink' : ''}
                  name="trash alternate outline"
                  color={checkedItems ? 'red' : 'black'}
                  onClick={checkedItems ? () => setOpenDelete(true) : () => {}}
                />
                <Tooltip
                  anchorId={`delete-row-header-${age}`}
                  style={{ zIndex: 1 }}
                  place="right"
                  content={
                    checkedItems
                      ? 'Supprimer plusieurs éléments'
                      : 'Cocher des éléments à supprimer'
                  }
                />
              </Table.Cell>
              <Table.Cell className="td-center" collapsing>
                <Icon name="info" />
              </Table.Cell>
              <Table.Cell className="td-center" collapsing>
                <Icon name="file archive outline" />
              </Table.Cell>
              <Table.Cell className="td-left">{ageLabel}</Table.Cell>
              <Table.Cell className="td-center" />
              <Table.Cell className="td-center" collapsing>
                Type
              </Table.Cell>
              <Table.Cell className="td-center" collapsing>
                Date
              </Table.Cell>
              <Table.Cell className="td-right" collapsing>
                Taille
              </Table.Cell>
              <Table.Cell className="td-center" collapsing>
                <Icon name="trash alternate outline" />
              </Table.Cell>
            </Table.Row>
          ) : null;
        }}
      </Media>
      <Media lessThan="lg">
        {(mediaClassNames, renderChildren) => {
          return renderChildren ? (
            <Table.Row className="tr-header">
              <Table.Cell className="td-left">
                {ageLabel}{' '}
                <Icon
                  id="mkdir-row-header"
                  name="plus square outline"
                  color="green"
                  className="clickable"
                  onClick={() => setOpenMkdir(true)}
                />
                <Tooltip
                  anchorId="mkdir-row-header"
                  style={{ zIndex: 1 }}
                  place="right"
                  content="Nouveau dossier"
                />
              </Table.Cell>
              <Table.Cell collapsing />
              <Table.Cell collapsing />
            </Table.Row>
          ) : null;
        }}
      </Media>
    </>
  );
}

export default DirectoryTableHeader;
