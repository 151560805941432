import React, { useEffect, useState } from 'react';
import { Button, Icon, Label, Loader, Segment, Table } from 'semantic-ui-react';
import { deleteDeviceFs, getDeviceFsList } from '../../services/Devices';
import { Media } from '../Tools/AppMedia';
import { notifError, notifSuccess } from '../Tools/NotificationToasts';
import { parentDirectory } from '../Tools/Utils';
import FreeboxFileExplorerTableRow from './FreeboxFileExplorerTableRow';

function FreeboxFileExplorerTable({ device }) {
  const [state, setState] = useState({
    fsContent: [],
    fsPath: 'Lw==',
    fsLoading: true,
    fsError: false,
  });

  const freeboxFsDirectory = (deviceId, path) => {
    setState((prevState) => ({ ...prevState, fsContent: [], fsLoading: true }));
    getDeviceFsList(deviceId, path)
      .then((response) => {
        if (response.status === 200 && response.data.success)
          setState({
            fsPath: response.data.path,
            fsContent: response.data.result,
            fsLoading: false,
            fsError: false,
          });
        else
          setState((prevState) => ({
            ...prevState,
            fsContent: [],
            fsLoading: false,
            fsError: false,
          }));
      })
      .catch(() => {
        setState((prevState) => ({ ...prevState, fsContent: [], fsLoading: false, fsError: true }));
      });
  };

  useEffect(() => {
    freeboxFsDirectory(device.id, state.fsPath);
  }, [state.fsPath]);

  const freeboxFsDelete = (path) => {
    deleteDeviceFs(device.id, path, state.fsPath)
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          notifSuccess('Supprimé avec succès');
          setTimeout(() => freeboxFsDirectory(device.id, state.fsPath), 1000);
        } else notifError('Échec de la suppression, réessayez ultérieurement');
      })
      .catch((e) => {
        notifError(`Échec de la suppression: ${e}`);
      });
  };

  const buildTable = () => {
    if (state.fsContent.length < 3)
      return (
        <>
          <Media greaterThanOrEqual="lg">
            {(mediaClassNames, renderChildren) => {
              return renderChildren ? (
                <Table.Row>
                  <Table.Cell />
                  <Table.Cell>Ce répertoire ne contient aucun élément</Table.Cell>
                  <Table.Cell />
                  <Table.Cell />
                  <Table.Cell />
                  <Table.Cell />
                </Table.Row>
              ) : null;
            }}
          </Media>
          <Media lessThan="lg">
            {(mediaClassNames, renderChildren) => {
              return renderChildren ? (
                <Table.Row>
                  <Table.Cell>
                    {/* // Line 1 */}
                    <div
                      className="td-center"
                      style={{ float: 'left', position: 'relative', width: '10%' }}
                    />
                    {/* // Line 2 */}
                    <div style={{ float: 'left', position: 'relative', width: '80%' }}>
                      <div
                        className="td-left"
                        style={{ float: 'left', position: 'relative', width: '100%' }}
                      >
                        Ce répertoire ne contient aucun fichier
                      </div>
                      <div style={{ float: 'left', position: 'relative', width: '100%' }} />
                    </div>
                    {/* // Line 3 */}
                    <div
                      className="td-center"
                      style={{ float: 'left', position: 'relative', width: '10%' }}
                    />
                  </Table.Cell>
                </Table.Row>
              ) : null;
            }}
          </Media>
        </>
      );
    return state.fsContent.map((item) => {
      return !item.hidden ? (
        <FreeboxFileExplorerTableRow
          key={`row-${item.index}`}
          item={item}
          device={device}
          directoryCallback={freeboxFsDirectory}
          deleteCallback={freeboxFsDelete}
        />
      ) : null;
    });
  };

  return state.fsError ? (
    <Segment className="transparent-table">
      <Icon name="exclamation triangle" color="orange" size="large" />
      <br />
      <br />
      Impossible de récupérer le contenu du répertoire pour le moment
      <br />
      <br />
      <Button onClick={() => freeboxFsDirectory(device.id, state.fsPath)}>Réessayer</Button>
    </Segment>
  ) : state.fsLoading ? (
    <Loader active />
  ) : (
    <Table className="transparent-table" unstackable compact selectable>
      <Table.Header>
        <Media greaterThanOrEqual="lg">
          {(mediaClassNames, renderChildren) => {
            return renderChildren ? (
              <Table.Row>
                <Table.HeaderCell className="td-center">
                  <Icon name="info" />
                </Table.HeaderCell>
                <Table.HeaderCell className="td-left">Nom</Table.HeaderCell>
                <Table.HeaderCell className="td-center">Type</Table.HeaderCell>
                <Table.HeaderCell className="td-center">
                  <Icon name="calendar check" />
                </Table.HeaderCell>
                <Table.HeaderCell className="td-right">Taille</Table.HeaderCell>
                <Table.HeaderCell className="td-center">
                  <Icon name="trash alternate outline" />
                </Table.HeaderCell>
              </Table.Row>
            ) : null;
          }}
        </Media>
        <Media lessThan="lg">
          {(mediaClassNames, renderChildren) => {
            return renderChildren ? (
              <Table.Row>
                <Table.HeaderCell>
                  Liste des fichiers <Label color="teal">{device.name}</Label>
                </Table.HeaderCell>
              </Table.Row>
            ) : null;
          }}
        </Media>
      </Table.Header>
      <Table.Body>
        {state.fsPath !== 'Lw==' && (
          <>
            <Media greaterThanOrEqual="lg">
              {(mediaClassNames, renderChildren) => {
                return renderChildren ? (
                  <Table.Row>
                    <Table.Cell className="td-center">
                      <Icon
                        name="angle double left"
                        color="teal"
                        className="clickable"
                        onClick={() => {
                          freeboxFsDirectory(device.id, parentDirectory(state.fsPath));
                        }}
                      />
                    </Table.Cell>
                    <Table.Cell
                      className="clickable font-weight-bolder"
                      onClick={() => {
                        freeboxFsDirectory(device.id, parentDirectory(state.fsPath));
                      }}
                    >
                      Répertoire Parent
                    </Table.Cell>
                    <Table.Cell className="td-center">
                      <Icon name="folder open outline" />
                    </Table.Cell>
                    <Table.Cell />
                    <Table.Cell />
                    <Table.Cell />
                  </Table.Row>
                ) : null;
              }}
            </Media>
            <Media lessThan="lg">
              {(mediaClassNames, renderChildren) => {
                return renderChildren ? (
                  <Table.Row>
                    <Table.Cell>
                      {/* // Line 1 */}
                      <div
                        className="td-center"
                        style={{ float: 'left', position: 'relative', width: '10%' }}
                      >
                        <Icon name="angle double left" color="teal" size="large" />
                      </div>
                      {/* // Line 2 */}
                      <div style={{ float: 'left', position: 'relative', width: '80%' }}>
                        <div
                          className="td-left clickable"
                          style={{ float: 'left', position: 'relative', width: '100%' }}
                          onClick={() => {
                            freeboxFsDirectory(device.id, parentDirectory(state.fsPath));
                          }}
                        >
                          Répertoire Parent
                        </div>
                        <div style={{ float: 'left', position: 'relative', width: '100%' }} />
                      </div>
                      {/* // Line 3 */}
                      <div
                        className="td-center"
                        style={{ float: 'left', position: 'relative', width: '10%' }}
                      />
                    </Table.Cell>
                  </Table.Row>
                ) : null;
              }}
            </Media>
          </>
        )}
        {buildTable()}
      </Table.Body>
    </Table>
  );
}

export default FreeboxFileExplorerTable;
