import React, { useState } from 'react';
import { Icon, Label, Popup, Progress, Table } from 'semantic-ui-react';
import { Tooltip } from 'react-tooltip';
import TimeAgo from 'react-timeago';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
import { Media } from '../Tools/AppMedia';
import {
  buildActionCell,
  buildActionCellResponsive,
  buildStateCell,
  buildTrState,
  cloudAction,
  cloudAddCategory,
  cloudAddNotification,
} from './CloudFunctions';
import { firstCharUp, millisecondsToStr, oneDayAgo, rmBrackets } from '../Tools/Utils';
import { getRole } from '../../services/Auth';
import CloudDeleteModal from '../Modal/CloudDeleteModal';
import frenchStrings from '../Tools/TimeAgoFrStrings';
import { getLocalServerConfig } from '../../services/Server';

const formatter = buildFormatter(frenchStrings);

function CloudTableRow({ item }) {
  const [open, setOpen] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const days = 86400000 * (getLocalServerConfig().torrentAutoDeleteOlderDays || 5);
  const elapsed = Date.now() - new Date(days + item.completion_on * 1000) > 0;

  return (
    <>
      <Media greaterThanOrEqual="lg">
        {(mediaClassNames, renderChildren) => {
          return renderChildren ? (
            <Table.Row className={`torrent-table-row ${buildTrState(item)}`}>
              <Table.Cell className="td-center">{buildActionCell(item)}</Table.Cell>
              <Table.Cell className="td-center">
                <span id={`torrent-state-cell-${item.hash}`}>{buildStateCell(item)}</span>
                <Tooltip
                  anchorId={`torrent-state-cell-${item.hash}`}
                  style={{ zIndex: 1 }}
                  place="bottom"
                  content={item.state}
                />
              </Table.Cell>
              <Table.Cell className={`td-left ${item.progress !== 1 && 'font-weight-normal'}`}>
                <span id={`torrent-name-cell-${item.hash}`}>
                  {firstCharUp(rmBrackets(rmBrackets(item.name)))}
                  {item.category !== '' && <span className="text-success"> [{item.category}]</span>}
                </span>
                <Tooltip
                  anchorId={`torrent-name-cell-${item.hash}`}
                  style={{ zIndex: 1 }}
                  place="bottom"
                  content={item.name}
                />
                {item.progress !== 1 && getRole() > 1 && (
                  <>
                    <Icon
                      name={item.category === '' ? 'plus circle' : 'minus circle'}
                      color={item.category === '' ? 'green' : 'red'}
                      id={`torrent-category-cell-${item.hash}`}
                      className="clickable clickable-color"
                      onClick={() => {
                        cloudAddCategory(item.hash, item.category === '' ? 'Auto' : '');
                      }}
                    />
                    <Tooltip
                      anchorId={`torrent-category-cell-${item.hash}`}
                      style={{ zIndex: 1 }}
                      place="right"
                      content={`${item.category === '' ? 'Ajouter' : 'Retirer'} la catégorie Auto`}
                    />
                  </>
                )}
                {item.progress !== 1 && (
                  <>
                    <Icon
                      name="bell"
                      color={item.notification ? 'yellow' : 'grey'}
                      id={`torrent-category-bell-${item.hash}`}
                      className="clickable clickable-color"
                      onClick={() => cloudAddNotification(item.hash, !item.notification)}
                    />
                    <Tooltip
                      anchorId={`torrent-category-bell-${item.hash}`}
                      style={{ zIndex: 1 }}
                      place="right"
                      content={item.notification ? 'Ne pas être notifié(e)' : 'Être notifié(e)'}
                    />
                  </>
                )}
              </Table.Cell>
              <Table.Cell className="td-center">
                {item.progress === 1 && !elapsed && (
                  <>
                    <Label color="teal" circular id={`torrent-delete-eta-cell-${item.hash}`}>
                      <TimeAgo
                        date={new Date(days + item.completion_on * 1000)}
                        formatter={formatter}
                      />
                    </Label>
                    <Tooltip
                      anchorId={`torrent-delete-eta-cell-${item.hash}`}
                      style={{ zIndex: 1 }}
                      place="top"
                      html={`Suppression à
                        ${new Date(days + item.completion_on * 1000).toLocaleTimeString()}
                        <br />
                        le ${new Date(days + item.completion_on * 1000).toLocaleDateString()}`}
                    />
                  </>
                )}
              </Table.Cell>
              <Table.Cell className="td-center">
                {item.progress !== 1 && millisecondsToStr(item.eta * 1000)}
              </Table.Cell>
              <Table.Cell className="td-center font-weight-bolder">
                {item.progress === 1 ? (
                  <Icon name="check" color="green" />
                ) : (
                  (item.progress * 100).toFixed(1)
                )}
              </Table.Cell>
              <Table.Cell className="td-center">
                {item.dlspeed !== 0 || item.upspeed !== 0
                  ? `${(item.dlspeed / 1000).toFixed(0)} | ${(item.upspeed / 1000).toFixed(0)} ko/s`
                  : '-'}
              </Table.Cell>
              <Table.Cell className="td-center">
                {item.ratio === 0
                  ? '-'
                  : `${((item.ratio * item.size) / 1000 / 100 / 100 / 100).toFixed(2)} Go`}
              </Table.Cell>
              <Table.Cell className="td-center">
                {item.ratio === 0 ? '-' : item.ratio.toFixed(2)}
              </Table.Cell>
              <Table.Cell className="td-center">
                {item.state !== 'pausedUP' &&
                  (item.num_seeds === 0 && item.num_complete === 0
                    ? ''
                    : `${item.num_seeds}/${item.num_complete}`)}
              </Table.Cell>
              <Table.Cell className="td-center">
                {item.state !== 'pausedUP' &&
                  (item.num_leechs === 0 && item.num_incomplete === 0
                    ? ''
                    : `${item.num_leechs}/${item.num_incomplete}`)}
              </Table.Cell>
              <Table.Cell className="td-right">
                {`${(item.size / 1000 / 100 / 100 / 100).toFixed(2)} Go`}
              </Table.Cell>
              <Table.Cell className="td-center">
                {oneDayAgo(item.completion_on * 1000) && item.progress === 1 && getRole() < 2 ? (
                  <>
                    <Icon
                      id={`prevent-torrent-delete-cell-${item.hash}`}
                      className="clickable"
                      name="trash alternate outline"
                      color="blue"
                      onClick={() => {
                        setOpen(true);
                      }}
                    />
                    <Tooltip
                      anchorId={`prevent-torrent-delete-cell-${item.hash}`}
                      style={{ zIndex: 1 }}
                      place="left"
                      content="Supprimer torrent + fichiers récents"
                    />
                  </>
                ) : (
                  <>
                    <Icon
                      id={`torrent-delete-cell-${item.hash}`}
                      className="clickable"
                      name="trash alternate outline"
                      color="red"
                      onClick={() => {
                        setOpen(true);
                      }}
                    />
                    <Tooltip
                      anchorId={`torrent-delete-cell-${item.hash}`}
                      style={{ zIndex: 1 }}
                      place="left"
                      content="Supprimer torrent + fichiers"
                    />
                  </>
                )}
              </Table.Cell>
            </Table.Row>
          ) : null;
        }}
      </Media>
      <Media lessThan="lg">
        {(mediaClassNames, renderChildren) => {
          return renderChildren ? (
            <Table.Row className={`torrent-table-row ${buildTrState(item)}`}>
              <Table.Cell>
                {/* // Line 1 */}
                <div style={{ float: 'left', position: 'relative', width: '100%' }}>
                  <span style={{ wordBreak: 'break-all' }} id={`torrent-name-cell-${item.hash}`}>
                    {firstCharUp(rmBrackets(rmBrackets(item.name)))}
                    {item.category !== '' && (
                      <span className="text-success"> [{item.category}]</span>
                    )}
                  </span>
                  <Tooltip
                    anchorId={`torrent-name-cell-${item.hash}`}
                    style={{ zIndex: 1 }}
                    place="bottom"
                    content={item.name}
                  />
                </div>

                {/* // Line 2 */}
                <div style={{ float: 'left', position: 'relative', width: '100%' }}>
                  <div
                    className="td-left"
                    style={{ float: 'left', position: 'relative', width: '5%' }}
                  >
                    <span id={`torrent-state-cell-${item.hash}`}>{buildStateCell(item)}</span>
                    <Tooltip
                      anchorId={`torrent-state-cell-${item.hash}`}
                      style={{ zIndex: 1 }}
                      place="right"
                      content={item.state}
                    />
                  </div>
                  <div
                    className="td-left"
                    style={{ float: 'left', position: 'relative', width: '20%' }}
                  >
                    {item.progress !== 1 ? (
                      <>
                        <span id={`torrent-eta-cell-${item.hash}`}>
                          <Icon name="clock" /> {millisecondsToStr(item.eta * 1000)}
                        </span>
                        <Tooltip
                          anchorId={`torrent-eta-cell-${item.hash}`}
                          style={{ zIndex: 1 }}
                          place="bottom"
                          content="Temps restant"
                        />
                      </>
                    ) : !elapsed ? (
                      <>
                        <Label color="teal" size="tiny" id={`torrent-delete-eta-cell-${item.hash}`}>
                          <Icon name="trash alternate outline" />{' '}
                          <TimeAgo
                            date={new Date(days + item.completion_on * 1000)}
                            formatter={formatter}
                          />
                        </Label>
                        <Tooltip
                          anchorId={`torrent-delete-eta-cell-${item.hash}`}
                          style={{ zIndex: 1 }}
                          place="right"
                          html={`Suppression à
                            ${new Date(days + item.completion_on * 1000).toLocaleTimeString()}
                            <br />
                            le
                            ${new Date(days + item.completion_on * 1000).toLocaleDateString()}`}
                        />
                      </>
                    ) : (
                      <>-</>
                    )}
                  </div>
                  <div
                    className="td-left"
                    style={{ float: 'left', position: 'relative', width: '20%' }}
                  >
                    {item.dlspeed !== 0 || item.upspeed !== 0
                      ? `${(item.dlspeed / 1000).toFixed(0)} | ${(item.upspeed / 1000).toFixed(
                          0
                        )} ko/s`
                      : '-'}
                  </div>
                  <div
                    className="td-left"
                    style={{ float: 'left', position: 'relative', width: '10%' }}
                  >
                    <span id={`cloud-seeder-cell-${item.hash}`}>
                      {item.state !== 'pausedUP'
                        ? item.num_seeds === 0 && item.num_complete === 0
                          ? '0'
                          : `${item.num_seeds}/${item.num_complete}`
                        : '-'}
                    </span>
                    <Tooltip
                      anchorId={`cloud-seeder-cell-${item.hash}`}
                      style={{ zIndex: 1 }}
                      place="bottom"
                      content="Seeders (Connecté/Disponible)"
                    />
                  </div>
                  <div
                    className="td-left"
                    style={{ float: 'left', position: 'relative', width: '10%' }}
                  >
                    <span id={`cloud-leecher-cell-${item.hash}`}>
                      {item.state !== 'pausedUP'
                        ? item.num_leechs === 0 && item.num_incomplete === 0
                          ? '0'
                          : `${item.num_leechs}/${item.num_incomplete}`
                        : '-'}
                    </span>
                    <Tooltip
                      anchorId={`cloud-leecher-cell-${item.hash}`}
                      style={{ zIndex: 1 }}
                      place="bottom"
                      content="Leechers (Connecté/Disponible)"
                    />
                  </div>
                  <div
                    className="td-left"
                    style={{ float: 'left', position: 'relative', width: '25%' }}
                  >
                    <span className="font-weight-bolder">
                      {`${(item.size / 1000 / 100 / 100 / 100).toFixed(2)} Go`}
                    </span>
                  </div>
                  <div
                    className="td-right"
                    style={{ float: 'left', position: 'relative', width: '10%' }}
                  >
                    <Popup
                      on={['click']}
                      position="left center"
                      hideOnScroll
                      header="Actions:"
                      size="large"
                      open={openPopup}
                      onOpen={() => setOpenPopup(true)}
                      onClose={() => setOpenPopup(false)}
                      trigger={
                        <Icon
                          className="clickable"
                          name="angle double right"
                          color="teal"
                          size="big"
                        />
                      }
                      content={buildActionCellResponsive(item, setOpen, setOpenPopup)}
                    />
                  </div>
                </div>

                {/* // Line 3 */}
                <div style={{ float: 'left', position: 'relative', width: '100%' }}>
                  {(item.progress * 100).toFixed(0) !== '100' ? (
                    <Progress
                      percent={(item.progress * 100).toFixed(0)}
                      progress
                      success
                      size="small"
                    />
                  ) : (
                    <>
                      <span id={`cloud-percent-cell-${item.hash}`}>
                        <Icon name="checkmark" color="green" />
                      </span>
                      <Tooltip
                        anchorId={`cloud-percent-cell-${item.hash}`}
                        style={{ zIndex: 1 }}
                        place="right"
                        content="Téléchargement terminé"
                      />
                    </>
                  )}
                </div>
              </Table.Cell>
            </Table.Row>
          ) : null;
        }}
      </Media>
      <CloudDeleteModal
        open={open}
        item={item}
        recent={oneDayAgo(item.completion_on * 1000)}
        setOpen={setOpen}
        cloudAction={cloudAction}
      />
    </>
  );
}

export default CloudTableRow;
