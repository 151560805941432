import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Button, Header, Icon, Input, Modal } from 'semantic-ui-react';
import { updateNotificationToken } from '../../services/Server';
import { notifError, notifSuccess, notifWarning } from '../Tools/NotificationToasts';

function NotificationTokenModal({ setOpen, open }) {
  const [token, setToken] = useState('');
  const [, updateState] = useState();
  const inputRef = useRef(null);
  const forceUpdate = useCallback(() => updateState({}), []);

  useEffect(() => {
    if (inputRef.current !== null) inputRef.current.focus();
  });

  const handleModifyNotificationToken = (p) => {
    if (p != null && p.length > 0) {
      updateNotificationToken({ notificationToken: p })
        .then((response) => {
          if (response.status === 200 && response.data.success) {
            localStorage.setItem('notificationToken', p);
            setOpen(false);
            setToken('');
            notifSuccess('Token mis à jour');
            forceUpdate();
          } else notifError(`Échec de mise à jour du token: ${response.data.message}`);
        })
        .catch((e) => {
          notifError(`Échec de mise à jour du token: ${e.message}`);
        });
    } else {
      setOpen(false);
      notifWarning('Modification annulée');
    }
  };

  return (
    <Modal
      basic
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="small"
    >
      <Header icon>
        <Icon name="paper plane" color="teal" />
        <br />
        Modification de topic Ntfy
      </Header>
      <Modal.Content>
        Vous pouvez vous procurer un topic sur le site de Ntfy dans le menu latéral gauche :
        <br />
        - S&apos;abonner au sujet
        <br />
        - Cliquez sur Générer un nom (copiez le nom qui a été généré ci-dessous)
        <br />
        - Cliquez sur S&apos;abonner
        <br />
        <br />
        <Input
          fluid
          focus
          type="text"
          ref={inputRef}
          placeholder="XuIyDYTw...."
          value={token}
          onChange={(event) => setToken(event.target.value)}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button basic color="grey" inverted onClick={() => setOpen(false)}>
          <Icon name="remove" /> Annuler
        </Button>
        <Button
          color="green"
          inverted
          onClick={() => {
            handleModifyNotificationToken(token);
          }}
        >
          <Icon name="checkmark" /> Modifier
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default NotificationTokenModal;
