import React, { useState } from 'react';
import { Button, Form, Header, Icon, Loader, Modal } from 'semantic-ui-react';
import { passwordRegex } from '../Tools/Utils';
import { updatePassword } from '../../services/Server';

function ChangePasswordModal({ setOpen, open }) {
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordValidation, setNewPasswordValidation] = useState('');
  const [sending, setSending] = useState(false);
  const [message, setMessage] = useState('');
  const [done, setDone] = useState(false);

  const handleUpdatePassword = () => {
    if (newPassword !== newPasswordValidation) {
      setSending(false);
      setMessage('Les mots de passe ne correspondent pas');
      return false;
    }

    if (password.length === 0) {
      setMessage('Le mot de passe actuel est incorrect');
      setSending(false);
      return false;
    }

    if (!passwordRegex.test(newPassword)) {
      setMessage('Le mot de passe ne respecte pas les critères de sécurité');
      setSending(false);
      return false;
    }
    setMessage('');
    setSending(true);
    updatePassword({ currentPassword: password, newPassword })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          setMessage('Mot de passe modifié avec succès. Déconnexion...');
          setSending(false);
          setDone(true);
        } else {
          setMessage(`Échec : ${response.data.message}`);
          setSending(false);
        }
      })
      .catch((e) => {
        console.log(e);
        setMessage(`Échec : ${e}`);
        setSending(false);
      });
    return true;
  };

  return (
    <Modal basic onClose={setOpen} onOpen={setOpen} open={open}>
      <Header icon>
        <Icon name="key" color="teal" />
        <br />
        Changer de mot de passe
      </Header>
      <Modal.Content>
        <Modal.Description>
          <Header color="teal">
            Votre nouveau mot de passe doit respecter les critères suivants:
          </Header>
          <p>
            - longueur minimale de 8 caractères
            <br />
            - au moins un chiffre
            <br />
            - au moins une majuscule ET une minuscule
            <br />- au moins un caractère spécial
          </p>
          <Header as="h5" color="teal">
            Une fois le mot de passe modifié, vous serez déconnecté(e).
          </Header>
          {sending ? (
            <>
              <Loader active content="Sauvegarde" />
              <br />
              <br />
              <br />
            </>
          ) : (
            <Form>
              <Form.Group widths="equal">
                <Form.Input
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                  autoComplete="new-password"
                  value={password}
                  placeholder="Ancien mot de passe"
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Input
                  onChange={(e) => setNewPassword(e.target.value)}
                  type="password"
                  autoComplete="new-password"
                  value={newPassword}
                  placeholder="Nouveau mot de passe"
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Input
                  onChange={(e) => setNewPasswordValidation(e.target.value)}
                  type="password"
                  autoComplete="new-password"
                  value={newPasswordValidation}
                  placeholder="Confirmation nouveau mot de passe"
                />
              </Form.Group>
            </Form>
          )}
          <div className={done ? 'text-success' : 'text-danger'}>{message}</div>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button basic color="grey" onClick={() => setOpen(false)} inverted>
          <Icon name="remove" /> Annuler
        </Button>
        <Button onClick={handleUpdatePassword} color="green" inverted>
          <Icon name="checkmark" /> Enregistrer
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default ChangePasswordModal;
