import { useNavigate } from 'react-router';
import { useIdleTimer } from 'react-idle-timer';

function InactivityHandler({ children }) {
  const navigate = useNavigate();

  const handleIdleUser = () => navigate('/expired');
  useIdleTimer({
    element: document,
    timeout: 1000 * 60 * 10,
    onIdle: handleIdleUser,
    debounce: 0,
    crossTab: false,
    name: 'ergo-idle-timer',
    leaderElection: false,
    throttle: 0,
    syncTimers: 0,
    startOnMount: true,
    startManually: false,
  });

  return children;
}

export default InactivityHandler;
