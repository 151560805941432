import React, { useState } from 'react';
import { Button, Dimmer, Header, Icon, Image, Label, Loader } from 'semantic-ui-react';
import { getMovieVideos } from '../../services/Tvshow';
import { notifError } from '../Tools/NotificationToasts';
import { getLocalServerConfig } from '../../services/Server';

function DiscoveryCardDimmer({ id, type, title, posterPath, setOpenDiscoveryModal, detailed }) {
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(true);
  const [vf, setVf] = useState([]);
  const [vost, setVost] = useState([]);
  const [debounce, setDebounce] = useState(0);

  const getVideos = () => {
    getMovieVideos(id)
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          setVf(
            response.data.result.filter((elem) => {
              return elem.name.includes('VF');
            })
          );
          setVost(
            response.data.result.filter((elem) => {
              return elem.name.includes('VOST');
            })
          );
          setLoading(false);
        } else {
          setVf([]);
          setVost([]);
        }
      })
      .catch((e) => {
        notifError(`Échec de chargement des vidéos: ${e}`);
        console.log(`Error while getting videos for ${title} : ${e}`);
      });
  };

  function DebounceDimmer(dim) {
    setActive(dim);
    if (['movie', 'MOVIE'].includes(type)) {
      if (dim && loading === true) setDebounce(setTimeout(() => getVideos(), 300));
      else clearTimeout(debounce);
    } else setLoading(false);
  }

  const content = (
    <div>
      <Header as="h3" inverted>
        {title}
      </Header>
      {loading && (
        <>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <Loader />
        </>
      )}
      {['movie', 'MOVIE'].includes(type) && (
        <>
          {!loading && vf.length === 0 && vost.length === 0 && (
            <Button disabled>Aucun trailer</Button>
          )}
          {!loading && vf.length > 0 && (
            <Button
              as="a"
              labelPosition="right"
              href={`https://www.youtube.com/watch?v=${vf[0].key}`}
              target="_blank"
              rel="noreferrer noopener"
            >
              <Button color="youtube">
                <Icon name="youtube" />
                Trailer
              </Button>
              <Label as="div" basic color="red" pointing="left">
                VF
              </Label>
            </Button>
          )}
          {!loading && vf.length > 0 && vost.length > 0 && (
            <>
              <br />
              <br />
            </>
          )}
          {!loading && vost.length > 0 && (
            <Button
              as="a"
              labelPosition="right"
              href={`https://www.youtube.com/watch?v=${vost[0].key}`}
              target="_blank"
              rel="noreferrer noopener"
            >
              <Button color="youtube">
                <Icon name="youtube" />
                Trailer
              </Button>
              <Label as="div" basic color="red" pointing="left">
                VOSTFR
              </Label>
            </Button>
          )}
        </>
      )}
      {detailed && !loading && ['tv', 'TV', 'SEASON', 'EPISODE'].includes(type) && (
        <>
          <br />
          <br />
          <Button color="blue" onClick={() => setOpenDiscoveryModal(true)}>
            <Icon name="list" />
            Détails
          </Button>
        </>
      )}
      {!loading && (
        <>
          <br />
          <br />
          <Button
            as="a"
            color="blue"
            href={`https://www.themoviedb.org/${
              ['movie', 'MOVIE'].includes(type) ? 'movie' : 'tv'
            }/${id}`}
            target="_blank"
            rel="noreferrer noopener"
          >
            <Icon name="external alternate" />
            TheMovieDB
          </Button>
        </>
      )}
    </div>
  );

  return (
    <Dimmer.Dimmable
      blurring
      as={Image}
      dimmed={active}
      dimmer={{ active, content }}
      onMouseEnter={() => DebounceDimmer(true)}
      onMouseLeave={() => DebounceDimmer(false)}
      src={`${getLocalServerConfig().tmdbBaseUrl}w342${posterPath}`}
      wrapped
      ui={false}
    />
  );
}

export default DiscoveryCardDimmer;
