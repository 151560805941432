import React from 'react';
import { Button, Card, Divider, Grid, Placeholder, Rating } from 'semantic-ui-react';

function MediaCardPlaceholder({ type }) {
  return (
    <Card>
      <Placeholder>
        <Placeholder.Image square style={{ height: '435px' }} />
      </Placeholder>
      <Card.Content>
        <Card.Header>
          <Placeholder>
            <Placeholder.Header>
              <Placeholder.Line length="full" />
            </Placeholder.Header>
          </Placeholder>
        </Card.Header>
        <Card.Meta>
          <Placeholder>
            <Placeholder.Line length="full" />
          </Placeholder>
          {type === 'discovery' && (
            <>
              <br />
              <Divider fitted horizontal>
                <Rating icon="star" rating={0} maxRating={5} disabled />
              </Divider>
            </>
          )}
        </Card.Meta>
        <Card.Description>
          <Placeholder>
            <Placeholder.Paragraph>
              <Placeholder.Line length="full" />
              <Placeholder.Line length="full" />
              <Placeholder.Line length="very long" />
              <Placeholder.Line length="long" />
            </Placeholder.Paragraph>
          </Placeholder>
          <br />
          <br />
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        {type === 'scheduler' && (
          <center>
            <Button
              size="mini"
              color="green"
              icon="plus"
              label="Ajouter au calendrier auto"
              labelPosition="right"
              disabled
            />
          </center>
        )}
        {type === 'discovery' && (
          <Grid>
            <Grid.Column floated="left">
              <Button
                size="mini"
                color="green"
                icon="plus"
                label="Ajouter"
                labelPosition="right"
                disabled
              />
            </Grid.Column>
            <Grid.Column floated="left">
              <Button
                size="mini"
                color="teal"
                icon="search"
                label="Chercher"
                labelPosition="left"
                disabled
              />
            </Grid.Column>
          </Grid>
        )}
      </Card.Content>
    </Card>
  );
}

export default MediaCardPlaceholder;
