import React, { useEffect, useState } from 'react';
import { Card, Container, Dropdown, Icon, Input, Search, Segment } from 'semantic-ui-react';
import { searchMedia } from '../../services/Tvshow';
import { notifError } from '../Tools/NotificationToasts';
import DiscoveryCardsPlaceholder from './DiscoveryCardsPlaceholder';
import DiscoveryCard from './DiscoveryCard';
import DiscoveryPagination from './DiscoveryPagination';

function DiscoverySearchActions({ media, handleChangeMedia, deleteSearchElements }) {
  return (
    <Dropdown
      basic
      button
      direction="left"
      value={media}
      options={[
        {
          key: 0,
          icon: 'tv',
          text: 'Séries',
          value: 'tv',
        },
        {
          key: 1,
          icon: 'film',
          text: 'Films',
          value: 'movie',
        },
      ]}
      onChange={(e, { value }) => {
        handleChangeMedia(value);
        deleteSearchElements();
      }}
    />
  );
}

function DiscoverySearch({
  setOpenDiscovery,
  setSearchInput,
  focusSearchInput,
  setDiscoverySearchInput,
  setDiscoverySearchPage,
  setDiscoverySearchTotalPage,
  setDiscoverySearchMediaType,
  discoverySearchMediaType,
  discoverySearchInput,
  discoverySearchPage,
  discoverySearchTotalPage,
  refreshWatchItems,
  watchItems,
  scrollTop,
}) {
  const [state, setState] = useState({
    elements: [],
    loading: false,
    error: false,
  });

  const deleteSearchElements = () => {
    setState((prevState) => ({
      ...prevState,
      elements: [],
    }));
  };

  const getElements = (desiredPage) => {
    setState((prevState) => ({
      ...prevState,
      elements: [],
      loading: true,
      error: false,
    }));
    searchMedia(discoverySearchMediaType, discoverySearchInput, desiredPage)
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          const data = response.data.result.data.results;
          setDiscoverySearchTotalPage(response.data.result.data.total_pages);
          setState((prevState) => ({
            ...prevState,
            elements: data,
            loading: false,
            error: false,
          }));
        } else {
          notifError('Échec de la recherche');
          setState((prevState) => ({
            ...prevState,
            elements: [],
            loading: false,
            error: true,
          }));
        }
      })
      .catch((e) => {
        setState((prevState) => ({
          ...prevState,
          elements: [],
          loading: false,
          error: true,
        }));
        notifError(`Échec de la recherche: ${e}`);
      });
  };

  const handleSearch = (event) => {
    if (event.key === 'Enter' && event.target.value.length > 1) {
      event.target.blur();
      setDiscoverySearchInput(event.target.value);
      setDiscoverySearchPage(1);
      getElements(1);
    }
  };

  useEffect(() => {
    if (discoverySearchInput.length > 1) getElements(discoverySearchPage);
  }, []);

  return (
    <Container fluid>
      <Search
        style={{ width: '100%' }}
        showNoResults={false}
        minCharacters={1}
        onSearchChange={(e) => {
          setDiscoverySearchInput(e.target.value);
        }}
        loading={state.loading}
        input={
          <Input
            fluid
            action={
              <DiscoverySearchActions
                media={discoverySearchMediaType}
                handleChangeMedia={setDiscoverySearchMediaType}
                deleteSearchElements={deleteSearchElements}
              />
            }
            autoComplete="off"
            size="large"
            iconPosition="left"
            icon="search"
            type="text"
            placeholder="Chercher sur TheMovieDB.org"
            onKeyUp={handleSearch}
            value={discoverySearchInput}
          />
        }
      />
      {state.loading && <DiscoveryCardsPlaceholder />}
      {!state.error ? (
        state.elements.length > 0 ? (
          <>
            <br />
            <DiscoveryPagination
              currentPage={discoverySearchPage}
              totalPages={discoverySearchTotalPage}
              getElements={(activePage) => {
                setDiscoverySearchPage(activePage);
                getElements(activePage);
              }}
              scrollTop={scrollTop}
            />
            <Card.Group centered>
              {state.elements
                .filter((elem) => {
                  return !elem.adult && elem.poster_path;
                })
                .map((elem) => {
                  let added = false;
                  watchItems.forEach((item) => {
                    if (
                      item.tmdbId === elem.id &&
                      item.type === discoverySearchMediaType.toUpperCase()
                    )
                      added = true;
                  });
                  return (
                    <DiscoveryCard
                      type={discoverySearchMediaType}
                      key={`search-${discoverySearchMediaType}${elem.id}`}
                      media={elem}
                      setSearchInput={setSearchInput}
                      setOpenDiscovery={setOpenDiscovery}
                      focusSearchInput={focusSearchInput}
                      added={added}
                      watchItems={watchItems}
                      refreshWatchItems={refreshWatchItems}
                    />
                  );
                })}
            </Card.Group>
            <DiscoveryPagination
              currentPage={discoverySearchPage}
              totalPages={discoverySearchTotalPage}
              getElements={(activePage) => {
                setDiscoverySearchPage(activePage);
                getElements(activePage);
              }}
              scrollTop={scrollTop}
            />
          </>
        ) : (
          !state.loading && (
            <Segment textAlign="center">
              <Icon name="info" color="teal" size="big" />
              <br />
              <br />
              Aucun résultat pour la recherche
            </Segment>
          )
        )
      ) : (
        <Segment textAlign="center">
          <Icon name="exclamation triangle" color="orange" size="big" />
          <br />
          <br />
          Echec de la recherche, réessayez ultérieurement.
        </Segment>
      )}
    </Container>
  );
}

export default DiscoverySearch;
