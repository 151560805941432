import React, { useEffect, useRef, useState } from 'react';
import { Icon, Modal, Tab } from 'semantic-ui-react';
import Discovery from '../Discovery/Discovery';
import WatchList from '../Discovery/WatchList';
import DiscoverySearch from '../Discovery/DiscoverySearch';
import { getWatchItems } from '../../services/Tvshow';

function TabBarDiscovery({
  openDiscovery,
  currentIndex,
  handleTabChange,
  setSearchInput,
  setOpenDiscovery,
  focusSearchInput,
  setDiscoveryPage,
  setDiscoverySearchInput,
  setDiscoverySearchPage,
  setDiscoverySearchTotalPage,
  discoverySearchInput,
  discoverySearchMediaType,
  discoverySearchPage,
  discoverySearchTotalPage,
  setDiscoverySearchMediaType,
  discoveryWeekMoviePage,
  discoveryWeekMovieTotalPages,
  discoveryWeekTvPage,
  discoveryWeekTvTotalPages,
  trendingWeekMoviePage,
  trendingWeekMovieTotalPages,
  trendingWeekTvPage,
  trendingWeekTvTotalPages,
}) {
  const scrollRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(currentIndex);

  const [state, setState] = useState({
    watchItems: [],
    loading: true,
    error: false,
  });

  const getWatchElements = () => {
    getWatchItems()
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          setState((prevState) => ({
            ...prevState,
            watchItems: response.data.result,
          }));
        } else
          setState((prevState) => ({
            ...prevState,
            watchItems: [],
          }));
      })
      .catch(() => {
        setState((prevState) => ({
          ...prevState,
          watchItems: [],
        }));
      });
  };

  const scrollTopDiscovery = () => {
    if (scrollRef.current) scrollRef.current.scrollIntoView();
  };

  const panes = [
    {
      menuItem: {
        icon: 'check',
        content: window.innerWidth > 1210 ? 'Ma Liste' : '',
        key: 'watchlist',
      },
      pane: 'watchlist',
      render: () => (
        <Tab.Pane attached={false}>
          <WatchList
            setSearchInput={setSearchInput}
            setOpenDiscovery={setOpenDiscovery}
            focusSearchInput={focusSearchInput}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        icon: 'search',
        content: '',
        key: 'search',
      },
      pane: 'search',
      render: () => (
        <Tab.Pane attached={false}>
          <DiscoverySearch
            setSearchInput={setSearchInput}
            setDiscoverySearchInput={setDiscoverySearchInput}
            setDiscoverySearchPage={setDiscoverySearchPage}
            setDiscoverySearchTotalPage={setDiscoverySearchTotalPage}
            setDiscoverySearchMediaType={setDiscoverySearchMediaType}
            discoverySearchInput={discoverySearchInput}
            discoverySearchMediaType={discoverySearchMediaType}
            discoverySearchPage={discoverySearchPage}
            discoverySearchTotalPage={discoverySearchTotalPage}
            setOpenDiscovery={setOpenDiscovery}
            focusSearchInput={focusSearchInput}
            refreshWatchItems={getWatchElements}
            watchItems={state.watchItems}
            scrollTop={scrollTopDiscovery}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        icon: window.innerWidth > 1210 ? 'film' : 'chart line',
        content: window.innerWidth > 1210 ? 'Tendances Films' : '',
        key: 'trendingWeekMovie',
      },
      pane: 'trendingWeekMovie',
      render: () => (
        <Tab.Pane attached={false}>
          <Discovery
            key="trending-week-movie"
            mode="trending"
            type="movie"
            timeWindow="week"
            setSearchInput={setSearchInput}
            setOpenDiscovery={setOpenDiscovery}
            focusSearchInput={focusSearchInput}
            setDiscoveryPage={setDiscoveryPage}
            currentPage={trendingWeekMoviePage}
            totalPages={trendingWeekMovieTotalPages}
            scrollTop={scrollTopDiscovery}
            refreshWatchItems={getWatchElements}
            watchItems={state.watchItems}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        icon: 'film',
        content: window.innerWidth > 1210 ? 'Découverte Films' : '',
        key: 'discoveryWeekMovie',
      },
      pane: 'discoveryWeekMovie',
      render: () => (
        <Tab.Pane attached={false}>
          <Discovery
            key="discovery-week-movie"
            mode="discovery"
            type="movie"
            timeWindow="week"
            setSearchInput={setSearchInput}
            setOpenDiscovery={setOpenDiscovery}
            focusSearchInput={focusSearchInput}
            setDiscoveryPage={setDiscoveryPage}
            currentPage={discoveryWeekMoviePage}
            totalPages={discoveryWeekMovieTotalPages}
            scrollTop={scrollTopDiscovery}
            refreshWatchItems={getWatchElements}
            watchItems={state.watchItems}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        icon: window.innerWidth > 1210 ? 'tv' : 'chart line',
        content: window.innerWidth > 1210 ? 'Tendances Séries' : '',
        key: 'trendingWeekTv',
      },
      pane: 'trendingWeekTv',
      render: () => (
        <Tab.Pane attached={false}>
          <Discovery
            key="trending-week-tv"
            mode="trending"
            type="tv"
            timeWindow="week"
            setSearchInput={setSearchInput}
            setOpenDiscovery={setOpenDiscovery}
            focusSearchInput={focusSearchInput}
            setDiscoveryPage={setDiscoveryPage}
            currentPage={trendingWeekTvPage}
            totalPages={trendingWeekTvTotalPages}
            scrollTop={scrollTopDiscovery}
            refreshWatchItems={getWatchElements}
            watchItems={state.watchItems}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        icon: 'tv',
        content: window.innerWidth > 1210 ? 'Découverte Séries' : '',
        key: 'discoveryWeekTv',
      },
      pane: 'discoveryWeekTv',
      render: () => (
        <Tab.Pane attached={false}>
          <Discovery
            key="discovery-week-tv"
            mode="discovery"
            type="tv"
            timeWindow="week"
            setSearchInput={setSearchInput}
            setOpenDiscovery={setOpenDiscovery}
            focusSearchInput={focusSearchInput}
            setDiscoveryPage={setDiscoveryPage}
            currentPage={discoveryWeekTvPage}
            totalPages={discoveryWeekTvTotalPages}
            scrollTop={scrollTopDiscovery}
            refreshWatchItems={getWatchElements}
            watchItems={state.watchItems}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        icon: <Icon name="close" color="red" />,
        content: window.innerWidth > 1210 ? 'Fermer' : '',
        key: 'close',
        onClick: (e) => {
          e.preventDefault();
          e.stopPropagation();
          setOpenDiscovery(false);
        },
      },
      pane: 'close',
    },
  ];

  const getActiveIndex = () => {
    panes.forEach((pane, index) => {
      if (pane.pane === currentIndex) setActiveIndex(index);
    });
  };

  useEffect(() => {
    getActiveIndex();
  }, [currentIndex]);

  useEffect(() => {
    getWatchElements();
  }, []);

  return (
    <Modal
      size="large"
      open={openDiscovery}
      onClose={() => setOpenDiscovery(false)}
      onOpen={() => setOpenDiscovery(true)}
    >
      <div ref={scrollRef} />
      <Modal.Content>
        <Tab
          menu={{
            borderless: true,
            attached: false,
            tabular: false,
          }}
          panes={panes}
          activeIndex={activeIndex}
          onTabChange={handleTabChange}
        />
      </Modal.Content>
    </Modal>
  );
}

export default TabBarDiscovery;
