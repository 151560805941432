import React, { useEffect, useRef } from 'react';
import { Button, Header, Icon, Modal } from 'semantic-ui-react';

function DeleteModal({ setOpen, open, item, recent, handleDelete }) {
  const buttonRef = useRef(null);
  useEffect(() => {
    if (buttonRef.current !== null) buttonRef.current.focus();
  });

  return (
    <Modal
      basic
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="small"
    >
      <Header icon>
        <Icon name="trash alternate outline" color="red" />
        <br />
        Supprimer {item.file} ?
      </Header>
      <Modal.Content>
        {recent && (
          <>
            <p>ATTENTION !</p>
            <p>
              Afin de favoriser le partage et parce que ce{' '}
              {item.type === 'file' ? 'fichier' : 'dossier'} pourrait intéresser d&apos;autres
              personnes, il peut être judicieux de ne pas le supprimer tout de suite.
            </p>
            <p>Continuer malgré tout ?</p>
          </>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button basic color="red" inverted onClick={() => setOpen(false)}>
          <Icon name="remove" /> Annuler
        </Button>
        <Button
          color="red"
          inverted
          ref={buttonRef}
          onClick={() => {
            handleDelete([item]);
            setOpen(false);
          }}
        >
          <Icon name="trash alternate outline" /> Supprimer
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

function DeleteMultipleModal({ setOpen, open, items, handleDelete }) {
  const buttonRef = useRef(null);
  useEffect(() => {
    if (buttonRef.current !== null) buttonRef.current.focus();
  });

  return (
    <Modal
      basic
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="small"
    >
      <Header icon>
        <Icon name="trash alternate outline" color="red" />
        <br />
        Supprimer les éléments suivants ?
      </Header>
      <Modal.Content>
        {items.map((item) => (
          <span key={`delete-multiple-modal-${item.file}`}>
            <Icon name={item.type === 'dir' ? 'folder open outline' : 'file alternate outline'} />
            {item.file}
            <br />
          </span>
        ))}
      </Modal.Content>
      <Modal.Actions>
        <Button basic color="red" inverted onClick={() => setOpen(false)}>
          <Icon name="remove" /> Annuler
        </Button>
        <Button
          color="red"
          inverted
          ref={buttonRef}
          onClick={() => {
            handleDelete(items);
            setOpen(false);
          }}
        >
          <Icon name="trash alternate outline" /> Supprimer
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export { DeleteModal, DeleteMultipleModal };
