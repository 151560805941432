const parseProwlarrContent = (response) => {
  let items = [];
  if (response.data.result) {
    items = response.data.result;
  }
  return items.map((i) => {
    return {
      seeders: i.seeders,
      leechers: i.leechers,
      title: i.title,
      size: parseInt(i.size, 10),
      link: i.guid,
      infoUrl: i.infoUrl,
      indexerName: i.indexer,
      indexerId: i.indexerId,
      guid: i.downloadUrl,
      pubDate: i.publishDate,
      approved: i.approved,
    };
  });
};

export default parseProwlarrContent;
