import Axios from './Axios';

export const getTorrentList = () => Axios.get('/api/v1/torrents/list/');
export const torrentAction = (params) => Axios.post('/api/v1/torrents/action/', params); // hash, action, name
export const torrentAddMagnet = (params) => Axios.post('/api/v1/torrents/add/magnet/', params); // url, name
export const torrentAddUrl = (params) => Axios.post('/api/v1/torrents/add/url/', params); // url, name
export const torrentHistory = () => Axios.get('/api/v1/torrents/history/');
export const torrentSetCategory = (params) => Axios.post('/api/v1/torrents/setCategory/', params); // hash, category
export const torrentAddHashTracking = (params) => Axios.post('/api/v1/torrents/tracking/', params); // hash

export const torrentDeleteHashTracking = (params) =>
  Axios.delete('/api/v1/torrents/tracking/', { data: params }); // hash
