import React from 'react';
import { Button, Icon, Placeholder, Table } from 'semantic-ui-react';
import WatchListTableHeader from './WatchListTableHeader';

function WatchListMoviePlaceholderRow({ show = false }) {
  return (
    <Table.Row>
      <Table.Cell className="td-left">
        <Placeholder>
          <Placeholder.Header>
            <Placeholder.Line length="long" />
          </Placeholder.Header>
        </Placeholder>
      </Table.Cell>
      {show && (
        <Table.Cell className="td-center">
          <Placeholder>
            <Placeholder.Header>
              <Placeholder.Line />
            </Placeholder.Header>
          </Placeholder>
        </Table.Cell>
      )}
      <Table.Cell className="td-center">
        <Placeholder>
          <Placeholder.Header>
            <Placeholder.Line />
          </Placeholder.Header>
        </Placeholder>
      </Table.Cell>
      <Table.Cell className="td-center">
        <Icon name="eye slash outline" color="grey" />
      </Table.Cell>
      <Table.Cell className="td-center">
        <Icon name="download" color="grey" />
      </Table.Cell>
      <Table.Cell className="td-center">
        <Button
          size="tiny"
          color="grey"
          icon="search"
          label="Chercher"
          labelPosition="left"
          disabled
        />
      </Table.Cell>
      <Table.Cell className="td-right">
        <Icon name="close" color="grey" />
      </Table.Cell>
    </Table.Row>
  );
}

function WatchListPlaceholder() {
  return (
    <>
      <Table color="red">
        <WatchListTableHeader />
        <Table.Body>
          <WatchListMoviePlaceholderRow />
          <WatchListMoviePlaceholderRow />
          <WatchListMoviePlaceholderRow />
          <WatchListMoviePlaceholderRow />
          <WatchListMoviePlaceholderRow />
          <WatchListMoviePlaceholderRow />
        </Table.Body>
      </Table>
      <Table color="blue">
        <WatchListTableHeader show />
        <Table.Body>
          <WatchListMoviePlaceholderRow show />
          <WatchListMoviePlaceholderRow show />
          <WatchListMoviePlaceholderRow show />
          <WatchListMoviePlaceholderRow show />
          <WatchListMoviePlaceholderRow show />
          <WatchListMoviePlaceholderRow show />
        </Table.Body>
      </Table>
    </>
  );
}

export default WatchListPlaceholder;
