import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Header, Icon, Modal, TextArea } from 'semantic-ui-react';
import { torrentAddMagnet } from '../../services/Torrent';
import { notifError, notifSuccess, notifWarning } from '../Tools/NotificationToasts';

function MagnetModal({ setOpen, open }) {
  const [magnet, setMagnet] = useState('');

  const inputRef = useRef(null);
  useEffect(() => {
    if (inputRef.current !== null) inputRef.current.focus();
  });

  const handleAddMagnet = (p) => {
    if (p != null && p.length > 0) {
      torrentAddMagnet({ url: p, name: `Lien magnet ${p}` })
        .then((response) => {
          if (response.status === 200 && response.data.success) {
            setOpen(false);
            setMagnet('');
            notifSuccess('Torrent ajouté avec succès');
          } else notifError("Échec de l'ajout du torrent");
        })
        .catch((e) => {
          notifError(`Échec de l'ajout du torrent: ${e}`);
        });
    } else {
      notifWarning("Aucun téléchagement n'a été lancé");
      setOpen(false);
    }
  };

  return (
    <Modal
      basic
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="small"
    >
      <Header icon>
        <Icon name="magnet" color="teal" />
        <br />
        Ajouter un torrent via lien magnet
      </Header>
      <Modal.Content>
        Entrez votre lien magnet ci-dessous:
        <br />
        <br />
        <Form>
          <TextArea
            rows={2}
            ref={inputRef}
            value={magnet}
            placeholder="magnet:?"
            onChange={(event) => setMagnet(event.target.value)}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button basic color="grey" inverted onClick={() => setOpen(false)}>
          <Icon name="remove" /> Annuler
        </Button>
        <Button
          color="green"
          inverted
          onClick={() => {
            handleAddMagnet(magnet);
            setOpen(false);
          }}
        >
          <Icon name="cloud download" /> Télécharger
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default MagnetModal;
