import React from 'react';
import '../../assets/css/Cloud.css';
import { Icon, Loader, Segment, Tab } from 'semantic-ui-react';
import CloudTable from './CloudTable';

function CloudTab({ cloudContent, cloudLoading, cloudError }) {
  return (
    <Tab.Pane className="row content transparent" attached="top">
      {cloudLoading ? <Loader active /> : !cloudError && <CloudTable cloudContent={cloudContent} />}
      {cloudError && (
        <Segment textAlign="center">
          <br />
          <Icon name="exclamation triangle" color="orange" size="big" />
          <br />
          <br />
          Impossible de récupérer la liste des torrents pour le moment.
          <br />
          Nouvelle tentative...
          <br />
          <br />
        </Segment>
      )}
    </Tab.Pane>
  );
}

export default CloudTab;
