import Axios from './Axios';

export const getCalendar = () => Axios.get(`/api/v1/tvshows/calendar/`);
export const getSubscriptions = () => Axios.get('/api/v1/tvshows/subscriptions/');
export const getAutoDLHistory = () => Axios.get('/api/v1/tvshows/history/');
export const subscribeShow = (params) => Axios.post('/api/v1/tvshows/subscribe/', params); // is_user, subscriber_id, indexerid, input, enabled
export const getTrending = (type, timeWindow, page) =>
  Axios.get(`/api/v1/tvshows/trending/${type}/${timeWindow}/${page}/`);
export const getDiscovery = (type, _, page) =>
  Axios.get(`/api/v1/tvshows/discover/${type}/${page}/`);
export const getMovieVideos = (tmdbId) => Axios.get(`/api/v1/tvshows/getVideos/${tmdbId}/`);
export const getMediaDetails = (tmdbId, type) =>
  Axios.get(`/api/v1/tvshows/getDetails/${type}/${tmdbId}/`);
export const getWatchItems = () => Axios.get('/api/v1/tvshows/watch/');
export const upsertWatchItem = (params) => Axios.post('/api/v1/tvshows/watch/', params); // tmdbId, type, title, originalTitle, season, episode, seen, downloaded
export const deleteWatchItem = (params) => Axios.delete('/api/v1/tvshows/watch/', { data: params }); // tmdbId, type, season, episode
export const getSeason = (tmdbId, seasonNumber) =>
  Axios.get(`/api/v1/tvshows/getSeason/${tmdbId}/${seasonNumber}`);
export const searchMedia = (type, terms, page) =>
  Axios.get(`/api/v1/tvshows/search/${type}/${terms}/${page}/`);
export const getSubscribers = (indexerId) =>
  Axios.get(`/api/v1/tvshows/subscriptions/${indexerId}`);
export const searchSonarrMedia = (terms) =>
  Axios.get(`/api/v1/tvshows/sonarr/lookup?terms=${terms}`);
export const getSonarrQualityProfiles = () => Axios.get('/api/v1/tvshows/sonarr/qualityProfile');
export const addSonarrMedia = (params) => Axios.post('/api/v1/tvshows/sonarr/add', params); // title, tvvdbId, qualityProfile, tags
export const getSonarrTags = () => Axios.get('/api/v1/tvshows/sonarr/tag');
