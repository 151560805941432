import React, { useEffect } from 'react';
import { Button, Grid, Header, Icon, Segment } from 'semantic-ui-react';
import NavBarGuest from '../components/NavBar/NavBarGuest';
import BackgroundRandomizer from '../components/Tools/BackgroundRandomizer';
import { getLocalServerConfig } from '../services/Server';

function PendingPage() {
  const siteName = getLocalServerConfig().siteName || 'Ergo';
  useEffect(() => {
    document.title = `${siteName} | Demande d'accès envoyée`;
  }, []);

  return (
    <BackgroundRandomizer>
      <NavBarGuest />
      <main>
        <Grid
          className="no-margin-top"
          textAlign="center"
          style={{ height: '100vh' }}
          verticalAlign="middle"
        >
          <Grid.Column style={{ maxWidth: 900 }}>
            <Segment className="transparent-table">
              <Header as="h2" color="teal" icon>
                <Icon name="send" />
                Autorisation demandée
              </Header>
              <Header as="h3">
                Une validation manuelle est requise pour accéder à {siteName}.
                <br />
                Votre demande a été transmise et vous serez prévenu(e) dès que votre adresse email
                aura été autorisée.
              </Header>
              <br />
              <Button
                icon="home"
                label="Accueil"
                labelPosition="right"
                as="a"
                color="teal"
                size="large"
                href="/"
              />
            </Segment>
          </Grid.Column>
        </Grid>
      </main>
    </BackgroundRandomizer>
  );
}

export default PendingPage;
