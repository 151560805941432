import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Button, Header, Icon, Input, Modal } from 'semantic-ui-react';
import { getNickname } from '../../services/Auth';
import { updateNickname } from '../../services/Server';
import { notifError, notifSuccess, notifWarning } from '../Tools/NotificationToasts';

function ChangeNicknameModal({ setOpen, open }) {
  const [nickname, setNickname] = useState('');
  const [, updateState] = useState();
  const inputRef = useRef(null);
  const forceUpdate = useCallback(() => updateState({}), []);

  useEffect(() => {
    if (inputRef.current !== null) inputRef.current.focus();
  });

  const handleModifyNickname = (p) => {
    const regex = /^[a-zA-Z0-9-_ .]{2,20}$/;
    if (!regex.test(p)) {
      notifError('Le pseudo doit faire entre 2 et 20 caractères [azAZ09-_ .]');
      return;
    }
    if (p != null && p.length > 0) {
      updateNickname({ nickname: p })
        .then((response) => {
          if (response.status === 200 && response.data.success) {
            localStorage.setItem('nickname', p);
            setNickname('');
            setOpen(false);
            notifSuccess('Pseudo mis à jour');
            forceUpdate();
          } else notifError(`Échec de mise à jour du pseudo: ${response.data.message}`);
        })
        .catch((e) => {
          notifError(`Échec de mise à jour du pseudo: ${e.message}`);
        });
    } else {
      notifWarning('Modification annulée');
      setOpen(false);
    }
  };

  return (
    <Modal
      basic
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="small"
    >
      <Header icon>
        <Icon name="pencil alternate" color="teal" />
        <br />
        Modification de pseudo
      </Header>
      <Modal.Content>
        Le nouveau pseudo doit faire entre 2 et 20 caractères [azAZ09-_ .].
        <br />
        Il sera visible par les autres utilisateurs dans l&apos;historique de téléchargements.
        <br />
        <br />
        <Input
          fluid
          focus
          type="text"
          ref={inputRef}
          placeholder={getNickname()}
          value={nickname}
          onChange={(event) => setNickname(event.target.value)}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button basic color="grey" inverted onClick={() => setOpen(false)}>
          <Icon name="remove" /> Annuler
        </Button>
        <Button
          color="green"
          inverted
          onClick={() => {
            handleModifyNickname(nickname);
          }}
        >
          <Icon name="checkmark" /> Modifier
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default ChangeNicknameModal;
