import React from 'react';
import { Icon, Placeholder, Table } from 'semantic-ui-react';
import { Media } from '../Tools/AppMedia';

function SearchTablePlaceholderRowDesktop() {
  return (
    <Table.Row>
      <Table.Cell className="td-center">
        <Placeholder>
          <Placeholder.Line />
        </Placeholder>
      </Table.Cell>
      <Table.Cell className="td-left">
        <Placeholder>
          <Placeholder.Line length="full" />
        </Placeholder>
      </Table.Cell>
      <Table.Cell className="td-center">
        <Placeholder>
          <Placeholder.Line />
        </Placeholder>
      </Table.Cell>
      <Table.Cell className="td-center">
        <Placeholder>
          <Placeholder.Line />
        </Placeholder>
      </Table.Cell>
      <Table.Cell className="td-center">
        <Placeholder>
          <Placeholder.Line />
        </Placeholder>
      </Table.Cell>
      <Table.Cell className="td-right">
        <Placeholder>
          <Placeholder.Line />
        </Placeholder>
      </Table.Cell>
      <Table.Cell className="td-center">
        <Placeholder>
          <Placeholder.Line />
        </Placeholder>
      </Table.Cell>
      <Table.Cell className="td-center">
        <Placeholder>
          <Placeholder.Line />
        </Placeholder>
      </Table.Cell>
    </Table.Row>
  );
}

function SearchTablePlaceholderRowMobile() {
  return (
    <Table.Row>
      <Table.Cell>
        <div
          className="td-left"
          style={{
            float: 'left',
            position: 'relative',
            width: '100%',
            wordBreak: 'break-all',
          }}
        >
          <Placeholder>
            <Placeholder.Line length="very long" />
          </Placeholder>
        </div>
        <div style={{ float: 'left', position: 'relative', width: '100%' }}>
          <div style={{ float: 'left', position: 'relative', width: '80%' }}>
            <div style={{ float: 'left', position: 'relative', width: '100%' }}>
              <div style={{ float: 'left', position: 'relative', width: '50%' }}>
                <Placeholder>
                  <Placeholder.Line length="short" />
                </Placeholder>
              </div>
              <div
                className="td-right"
                style={{ float: 'left', position: 'relative', width: '50%' }}
              >
                <Placeholder>
                  <Placeholder.Line length="long" />
                </Placeholder>
              </div>
            </div>
            <div style={{ float: 'left', position: 'relative', width: '100%' }}>
              <div
                className="td-left"
                style={{ float: 'left', position: 'relative', width: '50%' }}
              >
                <Placeholder>
                  <Placeholder.Line length="short" />
                </Placeholder>
              </div>
              <div
                className="td-right"
                style={{ float: 'left', position: 'relative', width: '50%' }}
              >
                <Placeholder>
                  <Placeholder.Line length="short" />
                </Placeholder>
              </div>
            </div>
          </div>
          <div className="td-right" style={{ float: 'left', position: 'relative', width: '20%' }}>
            <Icon name="download" size="big" color="grey" />
          </div>
        </div>
      </Table.Cell>
    </Table.Row>
  );
}

function SearchTablePlaceholderMobile() {
  return (
    <Table color="teal" unstackable compact>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>
            <div className="mobile-100-relative">
              <div className="mobile-10-relative">Trier:</div>
              <div className="mobile-55-relative">
                <Icon name="spinner" loading />
                Recherche en cours
              </div>
              <div className="mobile-10-relative">Site</div>
              <div className="mobile-10-relative">
                <Icon name="calendar alternate outline" />
              </div>
              <div className="mobile-15-relative">Sources</div>
            </div>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <SearchTablePlaceholderRowMobile />
        <SearchTablePlaceholderRowMobile />
        <SearchTablePlaceholderRowMobile />
        <SearchTablePlaceholderRowMobile />
        <SearchTablePlaceholderRowMobile />
        <SearchTablePlaceholderRowMobile />
        <SearchTablePlaceholderRowMobile />
        <SearchTablePlaceholderRowMobile />
      </Table.Body>
    </Table>
  );
}

function SearchTablePlaceholderDesktop() {
  return (
    <Table color="teal">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell className="td-center">
            <Icon name="download" />
          </Table.HeaderCell>
          <Table.HeaderCell className="td-left">
            <Icon name="spinner" loading />
            Recherche en cours...
          </Table.HeaderCell>
          <Table.HeaderCell className="td-center">
            <Icon name="chain" />
          </Table.HeaderCell>
          <Table.HeaderCell className="td-center">Site</Table.HeaderCell>
          <Table.HeaderCell className="td-center">
            <Icon name="calendar alternate outline" />
          </Table.HeaderCell>
          <Table.HeaderCell className="td-right">Taille</Table.HeaderCell>
          <Table.HeaderCell className="td-center">Sources</Table.HeaderCell>
          <Table.HeaderCell className="td-center">Leechers</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <SearchTablePlaceholderRowDesktop />
        <SearchTablePlaceholderRowDesktop />
        <SearchTablePlaceholderRowDesktop />
        <SearchTablePlaceholderRowDesktop />
        <SearchTablePlaceholderRowDesktop />
        <SearchTablePlaceholderRowDesktop />
        <SearchTablePlaceholderRowDesktop />
        <SearchTablePlaceholderRowDesktop />
        <SearchTablePlaceholderRowDesktop />
        <SearchTablePlaceholderRowDesktop />
        <SearchTablePlaceholderRowDesktop />
        <SearchTablePlaceholderRowDesktop />
        <SearchTablePlaceholderRowDesktop />
        <SearchTablePlaceholderRowDesktop />
        <SearchTablePlaceholderRowDesktop />
      </Table.Body>
    </Table>
  );
}

function SearchTablePlaceholder() {
  return (
    <>
      <Media greaterThanOrEqual="lg">
        {(mediaClassNames, renderChildren) => {
          return renderChildren ? SearchTablePlaceholderDesktop() : null;
        }}
      </Media>
      <Media lessThan="lg">
        {(mediaClassNames, renderChildren) => {
          return renderChildren ? SearchTablePlaceholderMobile() : null;
        }}
      </Media>
    </>
  );
}

export default SearchTablePlaceholder;
