import React, { useState } from 'react';
import { Button, Card, Divider, Grid, Icon, Image, Label, Popup } from 'semantic-ui-react';
import TimeAgo from 'react-timeago';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
import frenchStrings from '../Tools/TimeAgoFrStrings';
import { firstCharUp } from '../Tools/Utils';
import MediaSchedulerAddModal from './MediaSchedulerAddModal';

const formatter = buildFormatter(frenchStrings);

function MediaSchedulerCard({
  item,
  setOpenMediaScheduler,
  schedulerMediaType,
  qualityProfiles,
  tags,
}) {
  const [openAddMediaScheduler, setOpenAddMediaScheduler] = useState(false);
  return (
    <Card key={item.id}>
      <Image src={item.remotePoster || `${window.origin}/placeholderPoster.png`} wrapped />
      <Card.Content>
        <Card.Header>
          <Grid>
            <Grid.Row centered>{item.title}</Grid.Row>
          </Grid>
        </Card.Header>
        <Card.Meta>
          <Grid>
            <Grid.Row centered>
              <Label horizontal color="grey">
                {item.seasons.length} Saisons
              </Label>
              {item.network && (
                <Label horizontal color="teal">
                  {item.network}
                </Label>
              )}
              <Label horizontal color={item.status === 'ended' ? 'red' : 'green'}>
                {firstCharUp(item.status)}
              </Label>
            </Grid.Row>
            <Grid.Row centered>
              {item.firsAired && (
                <span className="date">
                  <TimeAgo date={item.firstAired} formatter={formatter} />
                </span>
              )}
            </Grid.Row>
          </Grid>
          <br />
          <Divider fitted horizontal>
            {item.year || '?'}
          </Divider>
        </Card.Meta>
        <Popup
          trigger={
            <Card.Description>
              {item.overview ? (
                <>
                  {item.overview.slice(0, 120)}
                  {item.overview.length > 120 ? (
                    <>
                      {'... '}
                      <Icon name="mouse pointer" size="small" color="grey" />
                    </>
                  ) : (
                    ''
                  )}
                </>
              ) : (
                <Grid>
                  <Grid.Row centered>Aucun résumé disponible</Grid.Row>
                </Grid>
              )}
            </Card.Description>
          }
          mouseEnterDelay={500}
          content={item.overview || 'Aucun résumé disponible'}
          position="top center"
        />
      </Card.Content>
      <Card.Content extra>
        <center>
          {!item.id ? (
            <Button
              size="mini"
              color="green"
              icon="plus"
              label="Ajouter au calendrier auto"
              labelPosition="right"
              onClick={() => setOpenAddMediaScheduler(true)}
            />
          ) : (
            <Button
              size="mini"
              color="yellow"
              icon="plus"
              label="Déjà dans le calendrier"
              labelPosition="right"
              disabled
            />
          )}
        </center>
      </Card.Content>
      <MediaSchedulerAddModal
        open={openAddMediaScheduler}
        setOpen={setOpenAddMediaScheduler}
        setOpenMediaScheduler={setOpenMediaScheduler}
        item={item}
        schedulerMediaType={schedulerMediaType}
        qualityProfiles={qualityProfiles}
        tags={tags}
      />
    </Card>
  );
}

export default MediaSchedulerCard;
