import React, { useCallback, useEffect, useState } from 'react';
import { Button, Icon, Label, Loader, Tab, Table } from 'semantic-ui-react';
import DeviceTable from './DeviceTable';
import { getDeviceTasks } from '../../services/Devices';
import { Media } from '../Tools/AppMedia';
import DeviceAddLinkModal from '../Modal/DeviceAddLinkModal';
import FreeboxTabBar from './FreeboxTabBar';

function DeviceTab({ device, tasksError }) {
  const [open, setOpen] = useState(false);
  const [state, setState] = useState({
    tasksContent: [],
    tasksLoading: false,
    tasksError: false,
    sysInfo: { disk: {} },
    triggered: false,
  });

  const handleGetDeviceTasks = useCallback(
    (deviceId) => {
      if (!state.tasksLoading) {
        setState((prevState) => ({ ...prevState, tasksLoading: true }));
        getDeviceTasks(deviceId)
          .then((response) => {
            if (response.status === 200 && response.data.success)
              setState({
                tasksContent:
                  device.type === 'synology'
                    ? response.data.tasks.reverse()
                    : response.data.result.reverse(),
                tasksLoading: false,
                tasksError: false,
                sysInfo: device.type === 'freebox' ? { disk: response.data.disk } : { disk: {} },
                triggered: true,
              });
            else
              setState({
                tasksContent: [],
                tasksLoading: false,
                tasksError: true,
                sysInfo: { disk: {} },
                triggered: true,
              });
          })
          .catch(() => {
            setState({
              tasksContent: [],
              tasksLoading: false,
              tasksError: true,
              sysInfo: { disk: {} },
              triggered: true,
            });
          });
      }
    },
    [state.tasksLoading]
  );

  useEffect(() => {
    const deviceInterval = setInterval(() => {
      handleGetDeviceTasks(device.id);
    }, 5000);
    return () => {
      clearInterval(deviceInterval);
    };
  }, [handleGetDeviceTasks]);

  let freeSpace = '-';
  if (!tasksError) {
    freeSpace = state.sysInfo.disk.result
      ? `${(state.sysInfo.disk.result.partitions[0].free_bytes / 1073741824).toFixed(0)}/${(
          state.sysInfo.disk.result.partitions[0].total_bytes / 1073741824
        ).toFixed(0)} Go restants`
      : 'Espace indisponible';

    return (
      <>
        <Tab.Pane className="row content transparent" attached="top">
          {device.type === 'freebox' ? (
            <FreeboxTabBar
              device={device}
              tasksContent={state.tasksContent}
              tasksError={state.tasksError}
              triggered={state.triggered}
              freeSpace={freeSpace}
              setOpen={setOpen}
            />
          ) : (
            <DeviceTable
              device={device}
              tasksContent={state.tasksContent}
              loading={state.tasksLoading}
              error={state.tasksError}
              triggered={state.triggered}
            />
          )}
        </Tab.Pane>
        {device.type === 'synology' && (
          <Table compact="very" className="transparent-table" unstackable>
            <Table.Body>
              <Table.Row>
                <Media greaterThanOrEqual="lg">
                  {(mediaClassNames, renderChildren) => {
                    return renderChildren ? (
                      <Table.Cell>
                        <Label color="teal" size="large">
                          {device.name}
                        </Label>
                      </Table.Cell>
                    ) : null;
                  }}
                </Media>
                <Table.Cell className="td-center" singleLine>
                  <Button
                    size="small"
                    color="blue"
                    icon="add"
                    label="Ajouter un lien http/ftp"
                    labelPosition="left"
                    onClick={() => {
                      setOpen(true);
                    }}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Icon name="tasks" /> Tâches: {state.tasksContent.length}/256
                  {state.tasksLoading && state.triggered && <Loader active />}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        )}
        <DeviceAddLinkModal open={open} setOpen={setOpen} device={device} />
      </>
    );
  }
}
export default DeviceTab;
