import React from 'react';
import { Button, Icon, Label, Loader, Popup, Tab, Table } from 'semantic-ui-react';
import DeviceTable from './DeviceTable';
import { Media } from '../Tools/AppMedia';
import FreeboxFileExplorerTable from './FreeboxFileExplorerTable';

function FreeboxTabBar({
  device,
  tasksContent,
  tasksLoading,
  tasksError,
  triggered,
  freeSpace,
  setOpen,
}) {
  const tabs = [
    {
      menuItem: {
        key: 'tasks-list',
        icon: 'download',
        content: window.innerWidth > 460 ? 'Liste des téléchargements' : '',
      },
      render: () => (
        <>
          <Tab.Pane className="row content transparent" attached="top">
            <DeviceTable
              device={device}
              tasksContent={tasksContent}
              loading={tasksLoading}
              error={tasksError}
              triggered={triggered}
            />
          </Tab.Pane>
          <Table compact="very" unstackable>
            <Table.Body>
              <Media greaterThanOrEqual="lg">
                {(mediaClassNames, renderChildren) => {
                  return renderChildren ? (
                    <Table.Row>
                      <Table.Cell>
                        <Label size="large" color="teal">
                          {device.name}
                        </Label>
                      </Table.Cell>
                      <Table.Cell>
                        <Icon name="disk" /> {freeSpace}
                      </Table.Cell>
                      <Table.Cell className="td-center">
                        <Button
                          size="small"
                          color="blue"
                          icon="add"
                          label="Ajouter un lien http/ftp"
                          labelPosition="left"
                          onClick={() => setOpen(true)}
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <Icon name="tasks" /> Tâches: {tasksContent.length}/128
                        {tasksLoading && triggered && <Loader active />}
                      </Table.Cell>
                    </Table.Row>
                  ) : null;
                }}
              </Media>
              <Media lessThan="lg">
                {(mediaClassNames, renderChildren) => {
                  return renderChildren ? (
                    <Table.Row>
                      <Table.Cell className="td-center">
                        <Button
                          size="small"
                          color="blue"
                          icon="add"
                          label="Ajouter un lien"
                          labelPosition="left"
                          onClick={() => {
                            setOpen(true);
                          }}
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <Popup
                          content={
                            <>
                              <br />
                              <br />
                              <Icon name="disk" /> {freeSpace}
                              <br />
                            </>
                          }
                          on={['click', 'hover']}
                          position="top center"
                          header={
                            <Label size="large" color="teal">
                              {device.name}
                            </Label>
                          }
                          size="large"
                          trigger={<Icon name="info circle" color="teal" size="large" />}
                        />
                      </Table.Cell>
                      <Table.Cell>
                        Tâches: {tasksContent.length}/128
                        {tasksLoading && triggered && <Loader active />}
                      </Table.Cell>
                    </Table.Row>
                  ) : null;
                }}
              </Media>
            </Table.Body>
          </Table>
        </>
      ),
    },
    {
      menuItem: {
        key: 'file-explorer',
        icon: 'disk',
        content: window.innerWidth > 460 ? 'Explorateur de fichiers' : '',
      },
      render: () => (
        <Tab.Pane
          className="row content transparent"
          key={`device-file-explorer-tab-${device.id}`}
          attached="top"
        >
          <FreeboxFileExplorerTable device={device} />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <Tab
      className="row content"
      menu={{
        fluid: true,
        attached: 'bottom',
        widths: 2,
        className: 'row footer transparent-table',
      }}
      defaultActiveIndex={0}
      panes={tabs}
    />
  );
}

export default FreeboxTabBar;
