import React from 'react';
import { Icon, Table } from 'semantic-ui-react';
import { Media } from '../Tools/AppMedia';

function CloudTableHeader() {
  return (
    <Table.Header>
      <Media greaterThanOrEqual="lg">
        {(mediaClassNames, renderChildren) => {
          return renderChildren ? (
            <Table.Row className="tr-header">
              <Table.HeaderCell className="td-center">
                <Icon name="tasks" />
              </Table.HeaderCell>
              <Table.HeaderCell className="td-center">
                <Icon name="chart bar" />
              </Table.HeaderCell>
              <Table.HeaderCell className="td-left">Nom</Table.HeaderCell>
              <Table.HeaderCell className="td-center">
                <Icon name="trash alternate outline" />
              </Table.HeaderCell>
              <Table.HeaderCell className="td-center">
                <Icon name="clock outline" />
              </Table.HeaderCell>
              <Table.HeaderCell className="td-center">
                <Icon name="percent" />
              </Table.HeaderCell>
              <Table.HeaderCell className="td-center">
                <Icon name="exchange" rotated="clockwise" />
              </Table.HeaderCell>
              <Table.HeaderCell className="td-center">Partagé</Table.HeaderCell>
              <Table.HeaderCell className="td-center">Ratio</Table.HeaderCell>
              <Table.HeaderCell className="td-center">Seeders</Table.HeaderCell>
              <Table.HeaderCell className="td-center">Leechers</Table.HeaderCell>
              <Table.HeaderCell className="td-right">Taille</Table.HeaderCell>
              <Table.HeaderCell className="td-center">
                <Icon name="trash alternate outline" />
              </Table.HeaderCell>
            </Table.Row>
          ) : null;
        }}
      </Media>
      <Media lessThan="lg">
        {(mediaClassNames, renderChildren) => {
          return renderChildren ? (
            <Table.Row className="tr-header">
              <Table.HeaderCell>Liste des tâches de téléchargement</Table.HeaderCell>
            </Table.Row>
          ) : null;
        }}
      </Media>
    </Table.Header>
  );
}

export default CloudTableHeader;
