import React, { useEffect, useRef } from 'react';
import { Button, Header, Icon, Modal } from 'semantic-ui-react';

function CloudDeleteModal({ setOpen, open, item, recent, cloudAction }) {
  const buttonRef = useRef(null);

  useEffect(() => {
    if (buttonRef.current !== null) buttonRef.current.focus();
  });

  return (
    <Modal
      basic
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="small"
    >
      <Header icon>
        <Icon name="trash alternate outline" color="red" />
        <br />
        Supprimer {item.name} ?
      </Header>
      <Modal.Content>
        {recent && (
          <>
            <p>ATTENTION !</p>
            <p>
              Afin de favoriser le partage et parce que ce torrent pourrait intéresser d&apos;autres
              personnes, il peut être judicieux de ne pas le supprimer tout de suite.
            </p>
            <p>Continuer malgré tout ?</p>
          </>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button basic color="red" inverted onClick={() => setOpen(false)}>
          <Icon name="remove" /> Annuler
        </Button>
        <Button
          color="red"
          inverted
          ref={buttonRef}
          onClick={() => {
            cloudAction(item.hash, 'removedata', item.name);
            setOpen(false);
          }}
        >
          <Icon name="trash alternate outline" /> Supprimer
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default CloudDeleteModal;
