import React from 'react';
import { Container, Menu } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

function NavBarGuest() {
  return (
    <Menu className="transparent-table" fixed="top">
      <Container>
        <Menu.Item as="a" href="/" header>
          Ergo
        </Menu.Item>
        <Menu.Menu position="right">
          <Menu.Item as={Link} to="/contact">
            Nous contacter
          </Menu.Item>
        </Menu.Menu>
      </Container>
    </Menu>
  );
}

export default NavBarGuest;
