import React, { useState, useEffect } from 'react';
import TimeAgo from 'react-timeago';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
import {
  Button,
  Checkbox,
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Label,
  Popup,
  Segment,
  Table,
} from 'semantic-ui-react';
import frenchStrings from '../Tools/TimeAgoFrStrings';
import { deleteWatchItem, getWatchItems, upsertWatchItem } from '../../services/Tvshow';
import { notifError, notifSuccess } from '../Tools/NotificationToasts';
import DiscoveryDetailedCard from './DiscoveryDetailedCard';
import WatchListPlaceholder from './WatchListPlaceholder';
import WatchListTableHeader from './WatchListTableHeader';

const formatter = buildFormatter(frenchStrings);

function WatchElement({
  elem,
  upsertElement,
  deleteElement,
  setSearchInput,
  setOpenDiscovery,
  focusSearchInput,
  watchItems,
  refreshWatchItems,
}) {
  const [open, setOpen] = useState(false);

  const buildSearch = () => {
    if (elem.season !== -1 && elem.episode !== -1) {
      return `S${elem.season < 9 && '0'}${elem.season}E${elem.episode < 9 && '0'}${elem.episode}`;
    }
    if (elem.season !== -1) {
      return `S${elem.season < 9 && '0'}${elem.season}`;
    }
    return '';
  };

  return (
    <Table.Row>
      <Table.Cell>
        <span className="clickable" onClick={() => setOpen(true)}>
          {elem.title}{' '}
          <Popup
            hideOnScroll
            trigger={<Icon color="grey" name="external alternate" size="small" />}
            content="Voir la fiche"
            position="top center"
          />
        </span>
        <DiscoveryDetailedCard
          tmdbId={elem.tmdbId}
          mediaType={elem.type}
          open={open}
          setOpen={setOpen}
          watchItems={watchItems}
          refreshWatchItems={refreshWatchItems}
        />
      </Table.Cell>
      {['TV', 'SEASON', 'EPISODE'].includes(elem.type) && (
        <Table.Cell className="td-center">
          <Label>{buildSearch() || 'Intégrale'}</Label>
        </Table.Cell>
      )}
      <Table.Cell className="td-center">
        <Popup
          hideOnScroll
          trigger={<TimeAgo className="clickable" date={elem.created_at} formatter={formatter} />}
          content={`Ajouté dans ma liste le ${new Date(elem.created_at).toLocaleDateString()}`}
          position="top center"
          mouseEnterDelay={250}
        />
      </Table.Cell>
      <Table.Cell className="td-center">
        <Popup
          hideOnScroll
          trigger={
            <Checkbox
              className="clickable"
              checked={elem.seen}
              onClick={() =>
                upsertElement(
                  elem.tmdbId,
                  elem.type,
                  elem.title,
                  elem.original_title,
                  elem.season,
                  elem.episode,
                  !elem.seen,
                  elem.downloaded
                )
              }
            />
          }
          content={elem.seen ? 'Marquer Non Vu' : 'Marquer Vu'}
          position="top center"
        />
      </Table.Cell>
      <Table.Cell className="td-center">
        <Popup
          hideOnScroll
          trigger={
            <Checkbox
              className="clickable"
              checked={elem.downloaded}
              onClick={() =>
                upsertElement(
                  elem.tmdbId,
                  elem.type,
                  elem.title,
                  elem.original_title,
                  elem.season,
                  elem.episode,
                  elem.seen,
                  !elem.downloaded
                )
              }
            />
          }
          content={elem.downloaded ? 'Marquer Non Récupéré' : 'Marquer Récupéré'}
          position="top center"
        />
      </Table.Cell>
      <Table.Cell className="td-center">
        {elem.original_title !== elem.title ? (
          <Popup
            hideOnScroll
            trigger={
              <Button
                size="tiny"
                color="teal"
                icon="search"
                label="Chercher"
                labelPosition="left"
              />
            }
            content={
              <Grid>
                <Grid.Row centered>
                  <Header
                    as="h3"
                    content="Quel titre utiliser ?"
                    subheader="Dans certains cas le titre original retourne plus de résultats"
                  />
                  <Grid.Row>
                    <Button
                      color="teal"
                      content="Titre Français"
                      onClick={() => {
                        let search = elem.title;
                        if (['TV', 'SEASON', 'EPISODE'].includes(elem.type) && elem.season !== -1)
                          search += ` ${buildSearch()}`;
                        setSearchInput(search);
                        setOpenDiscovery(false);
                        focusSearchInput();
                      }}
                    />
                  </Grid.Row>
                  <Grid.Row>
                    <Divider />
                    <Button
                      color="teal"
                      content="Titre Original"
                      onClick={() => {
                        let search = elem.original_title;
                        if (['TV', 'SEASON', 'EPISODE'].includes(elem.type) && elem.season !== -1)
                          search += ` ${buildSearch()}`;
                        setSearchInput(search);
                        setOpenDiscovery(false);
                        focusSearchInput();
                      }}
                    />
                  </Grid.Row>
                </Grid.Row>
              </Grid>
            }
            on="click"
            position="left center"
          />
        ) : (
          <Button
            size="tiny"
            color="teal"
            icon="search"
            label="Chercher"
            labelPosition="left"
            onClick={() => {
              let search = elem.title;
              if (['TV', 'SEASON', 'EPISODE'].includes(elem.type) && elem.season !== -1)
                search += ` ${buildSearch()}`;
              setSearchInput(search);
              setOpenDiscovery(false);
              focusSearchInput();
            }}
          />
        )}
      </Table.Cell>
      <Table.Cell className="td-right">
        <Popup
          hideOnScroll
          trigger={
            <Icon
              className="clickable"
              name="trash alternate outline"
              color="red"
              onClick={() => deleteElement(elem.tmdbId, elem.type, elem.season, elem.episode)}
            />
          }
          content="Supprimer de ma liste"
          position="left center"
        />
      </Table.Cell>
    </Table.Row>
  );
}

function WatchList({ setSearchInput, setOpenDiscovery, focusSearchInput }) {
  const [state, setState] = useState({
    shows: [],
    films: [],
    loading: true,
    error: false,
  });

  const getWatchElements = () => {
    getWatchItems()
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          const movies = [];
          const tvs = [];
          response.data.result.forEach((elem) => {
            if (['TV', 'SEASON', 'EPISODE'].includes(elem.type)) tvs.push(elem);
            if (elem.type === 'MOVIE') movies.push(elem);
          });
          tvs.sort((a, b) => {
            return a.seen && b.seen ? 0 : a.seen ? 1 : -1;
          });
          movies.sort((a, b) => {
            return a.seen && b.seen ? 0 : a.seen ? 1 : -1;
          });
          tvs.sort((a, b) => {
            return a.downloaded && b.downloaded ? 0 : a.downloaded ? 1 : -1;
          });
          movies.sort((a, b) => {
            return a.downloaded && b.downloaded ? 0 : a.downloaded ? 1 : -1;
          });
          setState({
            shows: tvs,
            films: movies,
            loading: false,
            error: false,
          });
        } else {
          setState({
            shows: [],
            films: [],
            loading: false,
            error: true,
          });
        }
      })
      .catch(() => {
        setState({
          shows: [],
          films: [],
          loading: false,
          error: true,
        });
      });
  };

  const upsertWatchElement = (
    tmdbId,
    type,
    title,
    originalTitle,
    season,
    episode,
    seen,
    downloaded
  ) => {
    upsertWatchItem({ tmdbId, type, title, originalTitle, season, episode, seen, downloaded })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          notifSuccess('Élément modifié');
          getWatchElements();
        } else notifError('Échec de modification de l&apos;élément');
      })
      .catch((e) => {
        notifError(`Échec de modification de l'élément: ${e}`);
      });
  };

  const deleteWatchElement = (tmdbId, type, season, episode) => {
    deleteWatchItem({ tmdbId, type, season, episode })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          notifSuccess('Élément supprimé');
          getWatchElements();
        } else notifError('Échec de suppression de l&apos;élément');
      })
      .catch((e) => {
        notifError(`Échec de suppression de l'élément: ${e}`);
      });
  };

  useEffect(() => {
    getWatchElements();
  }, []);

  return (
    <Container fluid>
      {state.loading && <WatchListPlaceholder />}
      {!state.loading && !state.error && state.shows.length === 0 && state.films.length === 0 && (
        <Segment textAlign="center">
          <Icon name="info" color="teal" size="big" />
          <br />
          <br />
          Aucun élément dans votre liste pour le moment
          <br />
          Rendez-vous dans les onglets suivants pour découvrir de nouveaux films ou séries
        </Segment>
      )}
      {!state.loading && !state.error && state.films.length > 0 && (
        <Table color="red">
          <WatchListTableHeader />
          <Table.Body>
            {state.films.map((film) => {
              return (
                <WatchElement
                  upsertElement={upsertWatchElement}
                  deleteElement={deleteWatchElement}
                  setSearchInput={setSearchInput}
                  setOpenDiscovery={setOpenDiscovery}
                  focusSearchInput={focusSearchInput}
                  key={`${film.type}-${film.tmdbId}`}
                  elem={film}
                  watchItems={state.films}
                  refreshWatchItems={getWatchElements}
                />
              );
            })}
          </Table.Body>
        </Table>
      )}
      {!state.loading && !state.error && state.shows.length > 0 && (
        <Table color="blue">
          <WatchListTableHeader show />
          <Table.Body>
            {state.shows.map((show) => {
              return (
                <WatchElement
                  upsertElement={upsertWatchElement}
                  deleteElement={deleteWatchElement}
                  setSearchInput={setSearchInput}
                  setOpenDiscovery={setOpenDiscovery}
                  focusSearchInput={focusSearchInput}
                  key={`${show.type}-${show.tmdbId}-${show.season}-${show.episode}`}
                  elem={show}
                  watchItems={state.shows}
                  refreshWatchItems={getWatchElements}
                />
              );
            })}
          </Table.Body>
        </Table>
      )}
    </Container>
  );
}

export default WatchList;
