import React from 'react';
import { Dropdown, Label } from 'semantic-ui-react';
import TimeAgo from 'react-timeago';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
import frenchStrings from 'react-timeago/lib/language-strings/fr';

const formatter = buildFormatter(frenchStrings);

function HistoryList({ notifications }) {
  if (notifications.length < 1)
    return <Dropdown.Item key={0}>Aucune notification pour le moment</Dropdown.Item>;
  return notifications.map((notification) => (
    <Dropdown.Item key={notification.id}>
      <Label color="teal" size="small">
        <TimeAgo date={notification.created_at} formatter={formatter} />
      </Label>
      {notification.message}
    </Dropdown.Item>
  ));
}

function NavBarHistory({ notifications }) {
  return (
    <Dropdown item simple direction="left" className="clickable" icon="history">
      <Dropdown.Menu>
        <Dropdown.Header icon="tags" content="Historique des évènements" />
        <HistoryList notifications={notifications} />
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default NavBarHistory;
