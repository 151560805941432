import React, { useState, useEffect } from 'react';
import { Tab } from 'semantic-ui-react';
import DirectoryTab from '../Directory/DirectoryTab';
import CalendarTab from '../Calendar/CalendarTab';
import CloudTab from '../Cloud/CloudTab';
import DeviceTab from '../Device/DeviceTab';
import SubscriptionTab from '../Subscription/SubscriptionTab';
import { getLocalServerConfig } from '../../services/Server';
import TabBarSearch from './TabBarSearch';

function TabBar({
  activeIndex,
  activeSearchIndex,
  handleTabChange,
  devices,
  searchTabs,
  deleteSearchTab,
  handleSearchTabChange,
  cloudContent,
  cloudLoading,
  cloudError,
  setOpenDiscovery,
  setOpenMediaScheduler,
  setSearchInput,
  focusSearchInput,
  currentDirectory,
  directoryContent,
  getDirectory,
}) {
  const tabs = [];
  const [tabIndex, setActiveTabIndex] = useState(activeIndex);

  if (getLocalServerConfig().torrentSearchEnabled)
    tabs.push({
      menuItem: {
        key: 'search',
        icon: 'search',
        content: window.innerWidth > 1210 ? 'Rechercher' : '',
      },
      pane: 'search',
      render: () => (
        <TabBarSearch
          searchTabs={searchTabs}
          deleteSearchTab={deleteSearchTab}
          activeSearchIndex={activeSearchIndex}
          handleSearchTabChange={handleSearchTabChange}
          setOpenDiscovery={setOpenDiscovery}
          setSearchInput={setSearchInput}
          focusSearchInput={focusSearchInput}
        />
      ),
    });

  if (getLocalServerConfig().sonarrEnabled)
    tabs.push({
      menuItem: {
        key: 'sorties',
        icon: 'calendar alternate',
        content: window.innerWidth > 1210 ? 'Sorties' : '',
      },
      pane: 'sorties',
      render: () => (
        <CalendarTab
          setSearchInput={setSearchInput}
          focusSearchInput={focusSearchInput}
          handleTabChange={handleTabChange}
          setOpenMediaScheduler={setOpenMediaScheduler}
        />
      ),
    });

  if (getLocalServerConfig().torrentEnabled !== 'disabled')
    tabs.push({
      pane: 'encours',
      render: () => (
        <CloudTab cloudContent={cloudContent} cloudLoading={cloudLoading} cloudError={cloudError} />
      ),
    });

  tabs.push(
    {
      menuItem: {
        key: 'downloads',
        icon: 'download',
        content: window.innerWidth > 1210 ? 'Téléchargements' : '',
        onClick: () => getDirectory,
      },
      pane: 'downloads',
      render: () => (
        <Tab.Pane className="row content transparent" attached="top">
          <DirectoryTab
            currentDirectory={currentDirectory}
            directoryContent={directoryContent}
            getDirectory={getDirectory}
            devices={devices}
            cloudContent={cloudContent.filter((i) => i.progress !== 1 || i.state === 'moving')}
          />
        </Tab.Pane>
      ),
    },
    ...devices.map((device) => {
      return {
        menuItem: {
          key: `device-${device.id}`,
          icon: 'server',
          content: window.innerWidth > 1210 ? device.name : '',
        },
        pane: `device-${device.id}`,
        render: () => <DeviceTab key={`device-tab-${device.id}`} device={device} />,
      };
    })
  );

  if (getLocalServerConfig().sonarrEnabled && getLocalServerConfig().subscriptionsEnabled)
    tabs.push({
      menuItem: {
        key: 'mesabonnements',
        icon: 'calendar check',
        content: window.innerWidth > 1210 ? 'Mes abonnements' : '',
      },
      pane: 'mesabonnements',
      render: () => (
        <SubscriptionTab devices={devices} setOpenMediaScheduler={setOpenMediaScheduler} />
      ),
    });

  const getActiveIndex = () => {
    tabs.forEach((pane, index) => {
      if (pane.pane === activeIndex) setActiveTabIndex(index);
    });
  };

  useEffect(() => {
    getActiveIndex();
  }, [activeIndex]);

  return (
    <Tab
      className="row content"
      renderActiveOnly
      menu={{
        attached: 'bottom',
        widths: tabs.length,
        className: 'row footer transparent-table',
      }}
      activeIndex={tabIndex}
      onTabChange={handleTabChange}
      panes={tabs}
    />
  );
}

export default TabBar;
