import React, { useEffect } from 'react';
import { Button, Grid, Header, Icon, Segment } from 'semantic-ui-react';
import NavBarGuest from '../components/NavBar/NavBarGuest';
import BackgroundRandomizer from '../components/Tools/BackgroundRandomizer';
import { getLocalServerConfig } from '../services/Server';

function UnavailablePage() {
  const siteName = getLocalServerConfig().siteName || 'Ergo';
  useEffect(() => {
    document.title = `${siteName} | Plateforme Indisponible`;
  }, []);

  return (
    <BackgroundRandomizer>
      <NavBarGuest />
      <Grid
        className="no-margin-top"
        textAlign="center"
        style={{ height: '100vh' }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 900 }}>
          <Segment className="transparent-table">
            <Header as="h1" icon color="teal">
              <Icon name="warning" circular />
              Plateforme Indisponible
            </Header>
            <Header as="h3">
              Il semblerait que {siteName} soit temporairement indisponible.
              <br />
              Merci de réessayer dans quelques instants. N&apos;hésitez pas à nous contacter si le
              problème persiste.
              <br />
              <br />
            </Header>
          </Segment>
          <Button
            icon="home"
            label="Accueil"
            labelPosition="right"
            as="a"
            color="teal"
            size="big"
            href="/"
          />
        </Grid.Column>
      </Grid>
    </BackgroundRandomizer>
  );
}

export default UnavailablePage;
