import React, { useEffect, useState } from 'react';
import { Accordion, Button, Grid, Icon, Placeholder } from 'semantic-ui-react';
import { getSeason } from '../../services/Tvshow';
import { notifError } from '../Tools/NotificationToasts';
import WatchListButton from './WatchListButton';

function EpisodesAccordion({
  tmdbId,
  title,
  originalTitle,
  seasonNumber,
  watchItems,
  refreshWatchItems,
}) {
  const [state, setState] = useState({
    show: false,
    activeIndex: 0,
    episodes: null,
    loading: true,
    error: false,
  });

  const getSeasonDetails = () => {
    getSeason(tmdbId, seasonNumber)
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          setState((prevState) => ({
            ...prevState,
            activeIndex: -1,
            episodes: response.data.result.data.episodes,
            loading: false,
            error: false,
          }));
        } else {
          setState((prevState) => ({
            ...prevState,
            activeIndex: -1,
            episodes: null,
            loading: false,
            error: true,
          }));
          notifError('Échec de chargement des épisodes');
        }
      })
      .catch((e) => {
        notifError(`Échec de chargement des épisodes: ${e}`);
        setState((prevState) => ({
          ...prevState,
          activeIndex: -1,
          episodes: null,
          loading: false,
          error: true,
        }));
      });
  };

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = state.activeIndex === index ? -1 : index;
    setState((prevState) => ({ ...prevState, activeIndex: newIndex }));
  };

  const handleShow = (show) => {
    setState((prevState) => ({ ...prevState, show }));
  };

  useEffect(() => {
    if (state.show && state.episodes === null) getSeasonDetails();
  }, [state.show]);

  return state.show ? (
    <>
      {!state.loading && !state.error && state.episodes.length > 0 && (
        <Accordion fluid styled>
          {state.episodes.map((episode) => {
            let added = false;
            watchItems.forEach((item) => {
              if (
                item.tmdbId === tmdbId &&
                item.type === 'EPISODE' &&
                item.season === seasonNumber &&
                item.episode === episode.episode_number
              )
                added = true;
            });
            return (
              <span key={`episode-${tmdbId}-${seasonNumber}-${episode.episode_number}`}>
                <Accordion.Title
                  active={state.activeIndex === episode.episode_number}
                  index={episode.episode_number}
                  onClick={handleClick}
                >
                  <Icon name="dropdown" />
                  Épisode {episode.episode_number}: {episode.name}
                </Accordion.Title>
                <Accordion.Content active={state.activeIndex === episode.episode_number}>
                  <Grid stackable>
                    <Grid.Row>
                      <Grid.Column width={13}>
                        {episode.overview || 'Aucun résumé disponible'}
                      </Grid.Column>
                      <Grid.Column verticalAlign="middle" width={2}>
                        <WatchListButton
                          id={tmdbId}
                          title={title}
                          originalTitle={originalTitle}
                          type="EPISODE"
                          added={added}
                          season={seasonNumber}
                          episode={episode.episode_number}
                          refreshWatchItems={refreshWatchItems}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Accordion.Content>
              </span>
            );
          })}
        </Accordion>
      )}
      {state.loading && (
        <Placeholder>
          <Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Paragraph>
        </Placeholder>
      )}
      {state.error && !state.loading && (
        <>
          Échec de chargement des épisodes de la saison {seasonNumber}{' '}
          <Button color="orange" size="mini" onClick={getSeasonDetails}>
            <Icon name="refresh" />
            Ré-essayer
          </Button>
        </>
      )}
    </>
  ) : (
    <Button
      icon="arrow right"
      color="teal"
      size="mini"
      label="Afficher les épisodes"
      labelPosition="left"
      onClick={() => handleShow(true)}
    />
  );
}

export default EpisodesAccordion;
