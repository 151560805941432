import React, { forwardRef } from 'react';
import { Icon, Input, Popup, Search } from 'semantic-ui-react';
import NavBarSearchProviders from './NavBarSearchProviders';
import { deleteSearchCompletion, getSearchCompletion } from '../../services/Search';
import { notifError, notifSuccess } from '../Tools/NotificationToasts';

const NavBarSearchInput = forwardRef(
  (
    {
      setSearchInput,
      providers,
      selectedProvider,
      getProvidersList,
      providersUpdatedAt,
      providersLoading,
      handleChangeProvider,
      refreshProviders,
      handleTabChange,
      searchCallback,
      searchInput,
      mobile = false,
    },
    ref
  ) => {
    const [state, setState] = React.useState({
      completion: [],
      loading: false,
    });

    const getCompletion = (query) => {
      setState((prevState) => ({
        ...prevState,
        loading: true,
      }));
      getSearchCompletion(query)
        .then((response) => {
          if (response.status === 200 && response.data.success) {
            setState((prevState) => ({
              ...prevState,
              completion: response.data.result.map((res) => {
                return { title: res.query };
              }),
            }));
          }
        })
        .catch((e) => {
          console.log(`Échec d'autocompletion: ${e}`);
        })
        .finally(() => {
          setState((prevState) => ({
            ...prevState,
            loading: false,
          }));
        });
    };

    const deleteSuggestion = (query) => {
      deleteSearchCompletion(query)
        .then((response) => {
          if (response.status === 200 && response.data.success) {
            notifSuccess(`Suggestion supprimée: ${query}`);
          } else notifError(`Échec de la suppression`);
        })
        .catch((e) => {
          console.log(`Échec de la suppression: ${e}`);
        });
    };

    const completionRenderer = ({ title }) => (
      <div style={{ width: '100%' }}>
        <div
          style={{ float: 'left', position: 'relative', width: '95%' }}
          className="font-weight-bolder"
        >
          {title}
        </div>
        <div style={{ float: 'left', position: 'relative', width: '5%' }}>
          <Popup
            content="Supprimer la suggestion"
            position="bottom center"
            align="right"
            trigger={
              <Icon
                style={{ opacity: 0.2 }}
                name="delete"
                color="grey"
                onClick={(e) => {
                  e.stopPropagation();
                  deleteSuggestion(title);
                }}
              />
            }
          />
        </div>
      </div>
    );

    const handleCompletion = (event) => {
      setSearchInput(event.target.value);
      if (event.target.value) getCompletion(event.target.value);
      else
        setState((prevState) => ({
          ...prevState,
          completion: [],
        }));
    };

    const handleSearch = (event) => {
      if (event.key === 'Enter' && event.target.value.length > 1) {
        event.target.blur();
        searchCallback(event.target.value);
      }
    };

    return (
      <Search
        style={{ width: mobile ? '100%' : '60%' }}
        showNoResults={false}
        onSearchChange={handleCompletion}
        minCharacters={1}
        results={state.completion}
        loading={state.loading}
        resultRenderer={completionRenderer}
        onResultSelect={(e, data) => setSearchInput(data.result.title)}
        onSelectionChange={(e, data) => setSearchInput(data.result.title)}
        input={
          <Input
            ref={ref}
            fluid
            action={
              <NavBarSearchProviders
                providers={providers}
                selectedProvider={selectedProvider}
                getProvidersList={getProvidersList}
                providersUpdatedAt={providersUpdatedAt}
                providersLoading={providersLoading}
                handleChangeProvider={handleChangeProvider}
                refreshProviders={refreshProviders}
              />
            }
            autoComplete="off"
            size="large"
            iconPosition="left"
            icon="search"
            type="text"
            onClick={() => {
              handleTabChange('', 'search');
            }}
            onKeyUp={handleSearch}
            placeholder="Recherche..."
            value={searchInput}
          />
        }
      />
    );
  }
);

export default NavBarSearchInput;
