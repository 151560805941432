import React, { useEffect, useState } from 'react';
import { Icon, Label, Modal, Table } from 'semantic-ui-react';
import TimeAgo from 'react-timeago';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
import frenchStrings from '../Tools/TimeAgoFrStrings';
import { getAutoDLHistory } from '../../services/Tvshow';

const formatter = buildFormatter(frenchStrings);

function SubscriptionHistoryModal({ setOpen, open }) {
  const [state, setState] = useState({
    history: [],
    error: false,
  });

  const autoDLHistory = () => {
    getAutoDLHistory()
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          setState({ history: response.data.result, error: false });
        } else setState({ history: [], error: true });
      })
      .catch(() => {
        setState({ history: [], error: true });
      });
  };

  useEffect(() => {
    autoDLHistory();
  }, []);

  return (
    <Modal closeIcon size="large" open={open} onClose={() => setOpen(false)}>
      <Modal.Header>
        <Icon name="history" /> Historique téléchargements automatiques
      </Modal.Header>
      <Modal.Content scrolling>
        {state.error ? (
          <p>
            Erreur lors de la récupération de l&apos;historique
            <br />
            Veuillez réessayer ultérieurement
          </p>
        ) : (
          <Table unstackable selectable>
            <Table.Body>
              {state.history.map((item) => {
                return (
                  <Table.Row key={`cloud-history-item-${encodeURI(item.file + item.name)}`}>
                    <Table.Cell style={{ wordBreak: 'break-all' }}>
                      {item.file}
                      <br />
                      <Label as="a" color="blue" size="small" ribbon>
                        {item.name}
                      </Label>
                      <Label as="a" color="teal" size="small">
                        <TimeAgo date={item.updated_at} formatter={formatter} />
                      </Label>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        )}
      </Modal.Content>
    </Modal>
  );
}

export default SubscriptionHistoryModal;
