import React from 'react';
import { Button, Table } from 'semantic-ui-react';
import { Media } from '../Tools/AppMedia';
import CalendarTableHeader from './CalendarTableHeader';
import CalendarTableRow from './CalendarTableRow';

function CalendarTable({ items, setSearchInput, focusSearchInput, handleTabChange }) {
  return (
    <Table className="transparent-table" unstackable compact celled selectable>
      <Table.Body>
        {items[0].length > 0 && (
          <>
            <CalendarTableHeader age="missed" count={items[0].length} />
            {items[0].map((item) => (
              <CalendarTableRow
                setSearchInput={setSearchInput}
                focusSearchInput={focusSearchInput}
                handleTabChange={handleTabChange}
                key={`${item.tvdbId}${item.seasonNumber}${item.episodeNumber}`}
                item={item}
              />
            ))}
          </>
        )}
        {items[1].length > 0 && (
          <>
            <CalendarTableHeader age="today" count={items[1].length} />
            {items[1].map((item) => (
              <CalendarTableRow
                setSearchInput={setSearchInput}
                focusSearchInput={focusSearchInput}
                handleTabChange={handleTabChange}
                key={`${item.tvdbId}${item.seasonNumber}${item.episodeNumber}`}
                item={item}
              />
            ))}
          </>
        )}
        {items[2].length > 0 && (
          <>
            <CalendarTableHeader age="soon" count={items[2].length} />
            {items[2].map((item) => (
              <CalendarTableRow
                setSearchInput={setSearchInput}
                focusSearchInput={focusSearchInput}
                handleTabChange={handleTabChange}
                key={`${item.tvdbId}${item.seasonNumber}${item.episodeNumber}`}
                item={item}
              />
            ))}
          </>
        )}
        {items[3].length > 0 && (
          <>
            <CalendarTableHeader age="later" count={items[3].length} />
            {items[3].map((item) => (
              <CalendarTableRow
                setSearchInput={setSearchInput}
                focusSearchInput={focusSearchInput}
                handleTabChange={handleTabChange}
                key={`${item.tvdbId}${item.seasonNumber}${item.episodeNumber}`}
                item={item}
              />
            ))}
          </>
        )}
        {items[0].length === 0 &&
          items[1].length === 0 &&
          items[2].length === 0 &&
          items[3].length === 0 && (
            <>
              <CalendarTableHeader age="undetermined" count={0} />
              <Media greaterThanOrEqual="lg">
                {(mediaClassNames, renderChildren) => {
                  return renderChildren ? (
                    <Table.Row className="calendar-table-row">
                      <Table.Cell className="td-center">
                        <img
                          className="calendar-table-cell"
                          alt=""
                          src={`${window.origin}/placeholder.png`}
                          width={140}
                          height={35}
                        />
                      </Table.Cell>
                      <Table.Cell>Le calendrier est vide pour le moment</Table.Cell>
                      <Table.Cell className="td-center">
                        S<span className="font-weight-bolder">00</span>E
                        <span className="font-weight-bolder">00</span>
                      </Table.Cell>
                      <Table.Cell className="td-center">
                        <Button
                          size="mini"
                          color="grey"
                          icon="search"
                          label="Chercher"
                          labelPosition="left"
                          disabled
                        />
                      </Table.Cell>
                      <Table.Cell className="td-center">-</Table.Cell>
                      <Table.Cell className="td-center">-</Table.Cell>
                    </Table.Row>
                  ) : null;
                }}
              </Media>
              <Media lessThan="lg">
                {(mediaClassNames, renderChildren) => {
                  return renderChildren ? (
                    <Table.Row className="tr-header">
                      <Table.Cell>Le calendrier est vide pour le moment</Table.Cell>
                    </Table.Row>
                  ) : null;
                }}
              </Media>
            </>
          )}
      </Table.Body>
    </Table>
  );
}

export default CalendarTable;
