import React, { useEffect, useRef, useState } from 'react';
import { Base64 } from 'js-base64';
import { Icon, Popup } from 'semantic-ui-react';
import { Tooltip } from 'react-tooltip';
import { notifInfo, notifSuccess, notifWarning } from '../Tools/NotificationToasts';
import { addDeviceTasks } from '../../services/Devices';

function DirectoryTableHeaderDeviceCell({ device, items, directoryCallback, setOpenPopup }) {
  const mounted = useRef(false);
  const [state, setState] = useState({
    pending: false,
  });

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const handleAddDeviceTask = () => {
    if (mounted.current) setState({ pending: true });
    if (device.type === 'synology') notifInfo(`Connexion à ${device.name}...`);

    const links = items.map((item) => Base64.encode(encodeURIComponent(item.filepath), true));
    const filenames = items.map((item) => Base64.encode(encodeURIComponent(item.file), true));
    addDeviceTasks({
      device_id: device.id,
      links,
      filenames,
    })
      .then((response) => {
        if (response.data.success) notifSuccess(`Téléchargement démarré sur ${device.name}`);
        else notifWarning(`Échec de téléchargement vers ${device.name}: ${response.data.message}`);
        if (mounted.current) {
          setOpenPopup(false);
          directoryCallback();
        }
      })
      .catch((e) => {
        notifWarning(`Échec de téléchargement vers ${device.name}: ${e.message}`);
      })
      .finally(() => {
        if (mounted.current) setState({ pending: false });
      });
  };

  return (
    <>
      <br />
      <span
        className="clickable"
        onClick={() => {
          handleAddDeviceTask();
        }}
      >
        {state.pending ? (
          <Icon name="spinner" color="green" loading />
        ) : (
          <Icon color="teal" name="download" />
        )}{' '}
        {device.name}
      </span>
      <br />
    </>
  );
}

function DirectoryTableHeaderDevicesPopup({
  devices,
  items,
  directoryCallback,
  trigger,
  noCheckedFolder,
  age,
}) {
  const [openPopup, setOpenPopup] = useState(false);
  return (
    devices.length > 0 &&
    (items.length === 0 ? (
      <span>
        <Icon id={`download-row-header-${age}`} name="download" color="black" />
        <Tooltip
          anchorId={`download-row-header-${age}`}
          place="right"
          style={{ zIndex: 1 }}
          content="Télécharger vers un appareil"
        />
      </span>
    ) : (
      <Popup
        on={['click']}
        disabled={!noCheckedFolder}
        position="right center"
        hideOnScroll
        header="Télécharger vers:"
        open={openPopup}
        onOpen={() => setOpenPopup(true)}
        onClose={() => setOpenPopup(false)}
        trigger={trigger}
        content={devices.map((device) => (
          <DirectoryTableHeaderDeviceCell
            key={`bulk-dl-cell-${device.id}-${age}`}
            device={device}
            directoryCallback={directoryCallback}
            items={items}
            setOpenPopup={setOpenPopup}
          />
        ))}
      />
    ))
  );
}

export default DirectoryTableHeaderDevicesPopup;
