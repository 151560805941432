import Axios from './Axios';

// Devices

export const getDeviceListSettings = () => Axios.get('/api/v1/settings/devices/');
export const getDeviceProperties = (deviceId) => Axios.get(`/api/v1/settings/devices/${deviceId}`);
export const setDeviceProperties = (params) =>
  Axios.post(`/api/v1/settings/devices/${params.id}`, params);
export const toggleDeviceState = (params) =>
  Axios.post(`/api/v1/settings/devices/${params.id}/setState/`, params);
export const testDeviceNotification = (deviceId) =>
  Axios.post(`/api/v1/settings/notifications/device/test/${deviceId}`);

// Users

export const getAllUsers = () => Axios.get('/api/v1/settings/users/');
export const getUserProperties = (userId) => Axios.get(`/api/v1/settings/users/${userId}`);
export const setUserProperties = (params) =>
  Axios.post(`/api/v1/settings/users/edit/${params.user_id}`, params);
export const updateUserRole = (params) => Axios.post('/api/v1/settings/users/role/', params); // user_id, role
export const testUserNotification = () => Axios.post(`/api/v1/settings/notifications/user/test/`);

// Server settings

export const getSettings = () => Axios.get('/api/v1/settings/config/');
export const setSettings = (params) => Axios.post('/api/v1/settings/config/', params);
