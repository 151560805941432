import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Navigate, useLocation } from 'react-router';
import { ToastContainer } from 'react-toastify';

import { Button, Checkbox, Form, Grid, Header, Icon, Segment, Select } from 'semantic-ui-react';
import { getRole } from '../services/Auth';
import { addDevice } from '../services/Devices';
import NavBarAuth from '../components/NavBar/NavBarAuth';
import BackgroundRandomizer from '../components/Tools/BackgroundRandomizer';
import { notifError } from '../components/Tools/NotificationToasts';
import InactivityHandler from '../components/Tools/InactivityHandler';
import { getLocalServerConfig } from '../services/Server';

const deviceTypes = [
  { key: 'freebox', value: 'freebox', text: 'Freebox' },
  { key: 'synology', value: 'synology', text: 'Synology' },
];

const protocolTypes = [
  { key: 'https', value: 'https', text: 'https:// (secure)' },
  { key: 'http', value: 'http', text: 'http:// (insecure)' },
];

function DevicesNewPage() {
  const location = useLocation();
  const [state, setState] = useState({
    item: {
      name: '',
      type: 'freebox',
      protocol: 'https',
      address: '',
      port: 443,
      login: '-',
      password: '',
      notification_token: '',
      enabled: true,
    },
    error: false,
    reason: '',
  });

  useEffect(() => {
    document.title = `${getLocalServerConfig().siteName || 'Ergo'} | Ajouter un appareil`;
  }, []);

  const handleEnabled = () => {
    const { item } = state;
    item.enabled = !item.enabled;
    setState((prevState) => ({ ...prevState, ...item }));
  };

  const handleInputChange = (key, val) => {
    const { item } = state;
    item[key] = val;
    setState((prevState) => ({ ...prevState, ...item }));
  };

  const handleSave = () => {
    addDevice({
      name: state.item.name,
      enabled: state.item.enabled,
      login: state.item.login,
      password: state.item.password,
      port: state.item.port,
      address: state.item.address,
      protocol: state.item.protocol,
      type: state.item.type,
      notification_token: state.item.notification_token,
    })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          setState((prevState) => ({ ...prevState, redirect: true }));
        } else {
          console.log(response);
          notifError(`Echec de l'ajout: ${response.data}`);
        }
      })
      .catch((e) => {
        console.log(e);
        notifError(`Echec de l'ajout: ${e.message}`);
      });
  };

  return (
    <InactivityHandler>
      <BackgroundRandomizer>
        {state.redirect && (
          <Navigate
            to={
              location.state !== null
                ? location.state.from
                : getRole() > 1
                  ? '/settings'
                  : '/devices'
            }
          />
        )}
        <div className="box">
          <NavBarAuth />
          <ToastContainer />
          <Grid
            textAlign="center"
            style={{ overflowY: 'scroll', height: '100vh' }}
            verticalAlign="middle"
          >
            <Grid.Column style={{ maxWidth: 600 }}>
              <Segment className="transparent-table">
                <Header as="h2" color="teal" icon textAlign="center">
                  <Icon name="pencil" circular />
                  Ajouter un nouvel appareil
                </Header>
                {state.error ? (
                  <Segment color="red">
                    <span>
                      Une erreur est survenue : {state.reason}
                      <br />
                    </span>
                  </Segment>
                ) : null}
                <Form>
                  <Form.Field>
                    <label>Nom (au moins 2 caractères)*</label>
                    <input
                      aria-labelledby="Nom"
                      label="Nom"
                      placeholder="Nom"
                      name="name"
                      value={state.item.name}
                      onChange={(val) => {
                        handleInputChange('name', val.target.value);
                      }}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Type</label>
                    <Select
                      name="type"
                      placeholder="Type d'appareil"
                      options={deviceTypes}
                      value={state.item.type}
                      onChange={(ev, val) => {
                        handleInputChange('type', val.value);
                      }}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Protocole</label>
                    <Select
                      name="protocol"
                      placeholder="Protocole"
                      options={protocolTypes}
                      value={state.item.protocol}
                      onChange={(ev, val) => {
                        handleInputChange('protocol', val.value);
                      }}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>
                      Adresse (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://fr.wikipedia.org/wiki/Fully_qualified_domain_name"
                      >
                        FQDN
                      </a>{' '}
                      ou IP fixe)*
                    </label>
                    <input
                      autoComplete="new-password"
                      placeholder="mabox.freebox.fr"
                      name="address"
                      value={state.item.address}
                      onChange={(val) => {
                        handleInputChange('address', val.target.value);
                      }}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Port (1 - 65535)*</label>
                    <input
                      placeholder="43785"
                      name="port"
                      type="number"
                      min="1"
                      max="65535"
                      value={state.item.port}
                      onChange={(val) => {
                        handleInputChange('port', val.target.value);
                      }}
                    />
                  </Form.Field>
                  {state.item.type !== 'freebox' && (
                    <Form.Field>
                      <label>Login*</label>
                      <input
                        autoComplete="new-password"
                        placeholder="Login"
                        name="login"
                        value={state.item.login}
                        onChange={(val) => {
                          handleInputChange('login', val.target.value);
                        }}
                      />
                    </Form.Field>
                  )}
                  <Form.Field>
                    <label>
                      {state.item.type === 'freebox' ? 'Token API Freebox*' : 'Password*'}
                    </label>
                    <input
                      autoComplete="new-password"
                      placeholder={
                        state.item.type === 'freebox'
                          ? '0t\\wttyeshr9fuhergs'
                          : 'S5eg8yw34gUE45ghidtrhtgwwg\\$'
                      }
                      name="password"
                      onChange={(val) => {
                        handleInputChange('password', val.target.value);
                      }}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>
                      Topic de notification{' '}
                      <a target="_blank" rel="noopener noreferrer" href="https://www.ntfy.sh/">
                        Ntfy
                      </a>
                    </label>
                    <input
                      placeholder="O.Ie..................."
                      name="notification_token"
                      autoComplete="new-password"
                      value={
                        state.item.notification_token !== null ? state.item.notification_token : ''
                      }
                      onChange={(val) => {
                        handleInputChange('notification_token', val.target.value);
                      }}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Activé</label>
                    <Checkbox
                      slider
                      name="enabled"
                      checked={state.item.enabled}
                      onClick={() => handleEnabled()}
                    />
                  </Form.Field>
                </Form>
                <p>* champs obligatoires</p>
              </Segment>
              <Button
                size="large"
                label="Enregistrer"
                color="teal"
                labelPosition="right"
                icon="save"
                onClick={handleSave}
              />
              <Button
                size="large"
                label="Annuler"
                labelPosition="right"
                icon="close"
                as={Link}
                to={
                  location.state !== null
                    ? location.state.from
                    : getRole() > 1
                      ? '/settings'
                      : '/devices'
                }
              />
            </Grid.Column>
          </Grid>
        </div>
      </BackgroundRandomizer>
    </InactivityHandler>
  );
}

export default DevicesNewPage;
