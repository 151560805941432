import React, { useEffect, useState } from 'react';
import { Button, Header, Icon, Loader, Segment, Tab, Table } from 'semantic-ui-react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { getDeviceListSettings } from '../../services/Settings';
import { getLocalServerConfig } from '../../services/Server';
import DevicesTabRow from './DevicesTabRow';

function DevicesTab() {
  const location = useLocation();
  const [state, setState] = useState({
    devices: [],
    loading: true,
    error: false,
  });

  const getDevices = () => {
    getDeviceListSettings()
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          setState({ devices: response.data.result, loading: false, error: false });
        } else {
          console.log('Could not get devices at the moment');
          console.log(response.status);
          setState({ devices: [], loading: false, error: true });
        }
      })
      .catch((e) => {
        console.log(`Could not get devices: ${e.message}`);
        setState({ devices: [], loading: false, error: true });
      });
  };

  useEffect(() => {
    getDevices();
  }, []);

  const buildDeviceRow = state.devices.map((device) => {
    return (
      <DevicesTabRow key={device.id} device={device} location={location} getDevices={getDevices} />
    );
  });

  return (
    <Tab.Pane className="row content transparent" attached="top">
      {state.loading && <Loader active />}
      <Segment className="transparent-table" textAlign="center">
        <Header as="h2" icon color="teal">
          <Icon name="server" circular />
          <Header.Content>Appareils</Header.Content>
        </Header>
        <br />
        <Button
          icon="plus"
          label="Ajouter un appareil"
          labelPosition="left"
          color="green"
          as={Link}
          to={{ pathname: '/devices/new', state: { from: location.pathname } }}
        />
      </Segment>
      {!state.loading && state.devices.length > 0 && (
        <Segment className="transparent-table" style={{ overflowX: 'auto', height: '100vh' }}>
          <Table basic="very" unstackable selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>ID</Table.HeaderCell>
                <Table.HeaderCell>Propriétaire</Table.HeaderCell>
                <Table.HeaderCell>Activé</Table.HeaderCell>
                <Table.HeaderCell>Connecté</Table.HeaderCell>
                <Table.HeaderCell>Type</Table.HeaderCell>
                <Table.HeaderCell>Nom</Table.HeaderCell>
                <Table.HeaderCell>Login</Table.HeaderCell>
                <Table.HeaderCell>Adresse</Table.HeaderCell>
                <Table.HeaderCell>
                  Topic de notification (
                  <a target="_blank" rel="noopener noreferrer" href="https://ntfy.sh/">
                    Ntfy
                  </a>
                  )
                </Table.HeaderCell>
                <Table.HeaderCell>Modifier</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>{buildDeviceRow}</Table.Body>
          </Table>
        </Segment>
      )}
      {state.error && (
        <Segment textAlign="center">
          <Icon name="exclamation" circular color="red" />
          <br />
          <br />
          Nous ne pouvons pas accéder à la liste des appareils pour le moment.
          <br />
          <br />
          <Button color="orange" onClick={getDevices}>
            Réessayer
          </Button>
        </Segment>
      )}
      {!state.loading && !state.error && state.devices.length < 1 && (
        <Segment textAlign="center">
          <Icon circular name="warning" color="teal" />
          <br />
          <br />
          Aucun appareil associé à {getLocalServerConfig().siteName || 'Ergo'}
          <br />
          <br />
        </Segment>
      )}
    </Tab.Pane>
  );
}

export default DevicesTab;
