import React, { useState, useEffect } from 'react';
import { Button, Card, Container, Grid, Header, Icon, Segment } from 'semantic-ui-react';
import { getTrending, getDiscovery } from '../../services/Tvshow';
import DiscoveryCard from './DiscoveryCard';
import DiscoveryCardsPlaceholder from './DiscoveryCardsPlaceholder';
import DiscoveryPagination from './DiscoveryPagination';

function Discovery({
  mode,
  type,
  timeWindow,
  setDiscoveryPage,
  currentPage,
  totalPages,
  setSearchInput,
  setOpenDiscovery,
  focusSearchInput,
  scrollTop,
  refreshWatchItems,
  watchItems,
}) {
  const [state, setState] = useState({
    elements: [],
    watchItems: [],
    loading: true,
    error: false,
  });

  const getElements = (desiredPage) => {
    setState((prevState) => ({
      ...prevState,
      elements: [],
      loading: true,
    }));
    (mode === 'trending' ? getTrending : getDiscovery)(type, timeWindow, desiredPage)
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          setDiscoveryPage(
            mode,
            type,
            timeWindow,
            desiredPage,
            response.data.result.data.total_pages
          );
          setState((prevState) => ({
            ...prevState,
            elements: response.data.result.data.results,
            loading: false,
            error: false,
          }));
        } else
          setState((prevState) => ({
            ...prevState,
            loading: false,
            error: true,
          }));
      })
      .catch(() => {
        setState((prevState) => ({
          ...prevState,
          loading: false,
          error: true,
        }));
      });
  };

  useEffect(() => {
    getElements(currentPage);
  }, []);

  return (
    <Container fluid>
      {state.loading && <DiscoveryCardsPlaceholder />}
      {!state.error ? (
        state.elements.length > 0 ? (
          <>
            {window.innerWidth < 1210 && (
              <Grid>
                <Grid.Row centered>
                  <Header>
                    {mode === 'trending' ? 'Tendances ' : 'Découverte '}
                    {type === 'movie' ? 'Films' : 'Séries TV'}
                  </Header>
                </Grid.Row>
              </Grid>
            )}
            <DiscoveryPagination
              currentPage={currentPage}
              totalPages={totalPages}
              getElements={getElements}
              scrollTop={scrollTop}
            />
            <Card.Group centered>
              {state.elements
                .filter((elem) => {
                  return !elem.adult && elem.poster_path;
                })
                .map((elem) => {
                  let added = false;
                  watchItems.forEach((item) => {
                    if (item.tmdbId === elem.id && item.type === type.toUpperCase()) added = true;
                  });
                  return (
                    <DiscoveryCard
                      type={type}
                      key={mode + type + elem.id}
                      media={elem}
                      setSearchInput={setSearchInput}
                      setOpenDiscovery={setOpenDiscovery}
                      focusSearchInput={focusSearchInput}
                      added={added}
                      watchItems={watchItems}
                      refreshWatchItems={refreshWatchItems}
                    />
                  );
                })}
            </Card.Group>
            <br />
            <DiscoveryPagination
              currentPage={currentPage}
              totalPages={totalPages}
              getElements={getElements}
              scrollTop={scrollTop}
            />
          </>
        ) : (
          !state.loading && (
            <Segment textAlign="center">
              <Icon name="info" color="teal" size="big" />
              <br />
              <br />
              {type === 'movie' ? 'Aucun film ' : 'Aucune série '} dans cette liste pour le moment
            </Segment>
          )
        )
      ) : (
        <Segment textAlign="center">
          <Icon name="exclamation triangle" color="orange" size="big" />
          <br />
          <br />
          Impossible de récupérer la liste des {type === 'movie' ? 'films ' : 'séries '} pour le
          moment
          <br />
          <br />
          <Button onClick={() => getElements(currentPage)} color="teal">
            Réessayer
          </Button>
        </Segment>
      )}
      <Grid>
        <Grid.Row centered>
          <Button
            size="small"
            color="red"
            icon="close"
            label="Fermer Discovery"
            labelPosition="left"
            onClick={() => setOpenDiscovery(false)}
          />
        </Grid.Row>
      </Grid>
    </Container>
  );
}

export default Discovery;
