import React from 'react';
import { Table } from 'semantic-ui-react';
import DirectoryTableHeader from './DirectoryTableHeader';
import DirectoryTable from './DirectoryTable';
import { DeleteMultipleModal } from '../Modal/DeleteModal';
import { ZipMultipleModal } from '../Modal/ZipModal';
import DirectoryCloudTable from '../Cloud/DirectoryCloudTable';
import DirectoryCloudTableHeader from '../Cloud/DirectoryCloudTableHeader';
import MkdirModal from '../Modal/MkdirModal';

function DirectoryTableGroup({
  items,
  devices,
  cloudContent,
  currentDirectory,
  openZip,
  setOpenZip,
  openDelete,
  setOpenDelete,
  openMkdir,
  setOpenMkdir,
  checkedItems,
  checkItemCallback,
  deleteCallback,
  directoryCallback,
  zipCallback,
}) {
  return (
    <>
      <Table className="transparent-table" unstackable compact selectable striped celled>
        <Table.Body>
          {cloudContent.length > 0 && (
            <>
              <DirectoryCloudTableHeader count={cloudContent.length} />
              <DirectoryCloudTable cloudContent={cloudContent} />
            </>
          )}
          {items.new.length > 0 && (
            <>
              <DirectoryTableHeader
                age="new"
                count={items.new.length}
                checkedItemsList={checkedItems}
                checkedItems={checkedItems.length > 0}
                setOpenDelete={setOpenDelete}
                setOpenZip={setOpenZip}
                setOpenMkdir={setOpenMkdir}
                devices={devices}
                directoryCallback={directoryCallback}
              />
              <DirectoryTable
                age="new"
                devices={devices}
                items={items.new}
                checkedItems={checkedItems}
                checkItemCallback={checkItemCallback}
                deleteCallback={deleteCallback}
                zipCallback={zipCallback}
                directoryCallback={directoryCallback}
                currentDirectory={currentDirectory}
              />
            </>
          )}
          {items.recent.length > 0 && (
            <>
              <DirectoryTableHeader
                age="recent"
                count={items.recent.length}
                checkedItemsList={checkedItems}
                checkedItems={checkedItems.length > 0}
                setOpenDelete={setOpenDelete}
                setOpenZip={setOpenZip}
                setOpenMkdir={setOpenMkdir}
                devices={devices}
                directoryCallback={directoryCallback}
              />
              <DirectoryTable
                age="recent"
                devices={devices}
                items={items.recent}
                checkedItems={checkedItems}
                checkItemCallback={checkItemCallback}
                deleteCallback={deleteCallback}
                zipCallback={zipCallback}
                directoryCallback={directoryCallback}
                currentDirectory={currentDirectory}
              />
            </>
          )}
          {items.old.length > 0 && (
            <>
              <DirectoryTableHeader
                age="old"
                count={items.old.length}
                checkedItemsList={checkedItems}
                checkedItems={checkedItems.length > 0}
                setOpenDelete={setOpenDelete}
                setOpenZip={setOpenZip}
                setOpenMkdir={setOpenMkdir}
                devices={devices}
                directoryCallback={directoryCallback}
              />
              <DirectoryTable
                age="old"
                devices={devices}
                items={items.old}
                checkedItems={checkedItems}
                checkItemCallback={checkItemCallback}
                deleteCallback={deleteCallback}
                zipCallback={zipCallback}
                directoryCallback={directoryCallback}
                currentDirectory={currentDirectory}
              />
            </>
          )}
          {items.error.length > 0 && (
            <>
              <DirectoryTableHeader
                age="error"
                count={items.error.length}
                checkedItemsList={checkedItems}
                checkedItems={checkedItems.length > 0}
                setOpenDelete={setOpenDelete}
                setOpenZip={setOpenZip}
                setOpenMkdir={setOpenMkdir}
                devices={devices}
                directoryCallback={directoryCallback}
              />
              <DirectoryTable
                age="error"
                devices={devices}
                items={items.error}
                checkedItems={checkedItems}
                checkItemCallback={checkItemCallback}
                deleteCallback={deleteCallback}
                zipCallback={zipCallback}
                directoryCallback={directoryCallback}
                currentDirectory={currentDirectory}
              />
            </>
          )}
          {items.new.length === 0 &&
            items.recent.length === 0 &&
            items.old.length === 0 &&
            items.error.length === 0 && (
              <>
                <DirectoryTableHeader
                  age="empty"
                  count={0}
                  checkedItemsList={checkedItems}
                  checkedItems={checkedItems.length > 0}
                  setOpenDelete={setOpenDelete}
                  setOpenZip={setOpenZip}
                  setOpenMkdir={setOpenMkdir}
                  devices={devices}
                  directoryCallback={directoryCallback}
                />
                <DirectoryTable
                  age="empty"
                  devices={devices}
                  items={items.new}
                  checkedItems={checkedItems}
                  checkItemCallback={checkItemCallback}
                  deleteCallback={deleteCallback}
                  zipCallback={zipCallback}
                  directoryCallback={directoryCallback}
                  currentDirectory={currentDirectory}
                />
              </>
            )}
        </Table.Body>
      </Table>
      <DeleteMultipleModal
        open={openDelete}
        items={checkedItems}
        setOpen={setOpenDelete}
        handleDelete={deleteCallback}
      />
      <ZipMultipleModal
        open={openZip}
        items={checkedItems}
        setOpen={setOpenZip}
        handleZipFolder={zipCallback}
      />
      <MkdirModal
        open={openMkdir}
        setOpen={setOpenMkdir}
        currentDirectory={currentDirectory}
        directoryCallback={directoryCallback}
      />
    </>
  );
}

export default DirectoryTableGroup;
