import React, { useEffect, useState } from 'react';
import { Loader, Button, Form, Grid, Header, Icon, Segment } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';
import NavBarAuth from '../components/NavBar/NavBarAuth';
import NavBarGuest from '../components/NavBar/NavBarGuest';
import { AuthConsumer } from '../contexts/AuthContext';
import { getLocalServerConfig, resetPassword } from '../services/Server';
import { passwordRegex } from '../components/Tools/Utils';
import BackgroundRandomizer from '../components/Tools/BackgroundRandomizer';

function ResetPasswordPage() {
  const { resetToken } = useParams();
  const [state, setState] = useState({
    result: '',
    password: '',
    passwordValidation: '',
    sending: false,
    done: false,
  });

  useEffect(() => {
    document.title = `${getLocalServerConfig().siteName || 'Ergo'} | Mot de passe oublié`;
  });

  const handleInputChange = (name, value) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmitForm = () => {
    const { password, passwordValidation } = state;

    if (password !== passwordValidation) {
      setState((prevState) => ({ ...prevState, result: 'Les mots de passe ne correspondent pas' }));
      return false;
    }

    if (!passwordRegex.test(password)) {
      setState((prevState) => ({
        ...prevState,
        result: 'Le mot de passe ne respecte pas les critères de sécurité',
      }));
      return false;
    }

    setState((prevState) => ({ ...prevState, result: '', sending: true }));
    resetPassword({ resetToken, password })
      .then((response) => {
        if (response.status === 200 && response.data.success)
          setState((prevState) => ({ ...prevState, result: '', sending: false, done: true }));
        else
          setState((prevState) => ({
            ...prevState,
            result: `Échec de la demande: ${response.data.message}`,
            sending: false,
          }));
      })
      .catch((e) => {
        console.log(e);
        setState((prevState) => ({
          ...prevState,
          result: `Échec de la demande: ${e}`,
          sending: false,
        }));
      });
    return true;
  };

  return (
    <AuthConsumer>
      {({ isAuth }) => (
        <BackgroundRandomizer>
          {isAuth ? <NavBarAuth /> : <NavBarGuest />}
          <Grid
            textAlign="center"
            style={{ overflowY: 'scroll', height: '100vh' }}
            verticalAlign="middle"
          >
            <Grid.Column style={{ maxWidth: 900 }}>
              <Segment className="transparent-table">
                <br />
                <Header as="h1" color="teal">
                  <Icon name={state.done ? 'check' : 'key'} /> Réinitialisation du mot de passe
                </Header>
                <br />
                {!state.sending && !state.done && (
                  <>
                    <Header as="h3">
                      Votre nouveau mot de passe doit respecter les critères suivants:
                    </Header>
                    <Header as="h4">
                      - longueur minimale de 8 caractères
                      <br />
                      - au moins un chiffre
                      <br />
                      - une majuscule et une minuscule
                      <br />- au moins un caractère spécial
                    </Header>
                  </>
                )}
                <Form>
                  {!state.sending && !state.done && (
                    <>
                      <Form.Group widths="equal">
                        <Form.Input
                          icon="key"
                          iconPosition="left"
                          onChange={(value) => handleInputChange('password', value.target.value)}
                          fluid
                          type="password"
                          value={state.password}
                          label="Votre nouveau mot de passe"
                          placeholder="Votre nouveau mot de passe"
                        />
                      </Form.Group>
                      <Form.Group widths="equal">
                        <Form.Input
                          icon="key"
                          iconPosition="left"
                          onChange={(value) =>
                            handleInputChange('passwordValidation', value.target.value)
                          }
                          fluid
                          type="password"
                          value={state.passwordValidation}
                          label="Votre nouveau mot de passe"
                          placeholder="Votre nouveau mot de passe"
                        />
                      </Form.Group>
                    </>
                  )}
                  {state.done && (
                    <>
                      <Header as="h4">Votre mot de passe a été modifié avec succès.</Header>
                      <br />
                      <Button
                        icon="home"
                        label="Accueil"
                        labelPosition="right"
                        color="teal"
                        size="big"
                        as="a"
                        href="/"
                      />
                    </>
                  )}
                  {state.result !== '' && (
                    <>
                      <div>
                        <span className="text-danger">{state.result}</span>
                      </div>
                      <br />
                    </>
                  )}
                  {state.sending && (
                    <>
                      <Loader active content="Chargement" />
                      <br />
                      <br />
                      <br />
                    </>
                  )}
                  {!state.sending && !state.done && (
                    <Button
                      icon="send"
                      label="Envoyer"
                      labelPosition="right"
                      color="teal"
                      size="big"
                      onClick={handleSubmitForm}
                    />
                  )}
                </Form>
              </Segment>
            </Grid.Column>
          </Grid>
        </BackgroundRandomizer>
      )}
    </AuthConsumer>
  );
}

export default ResetPasswordPage;
