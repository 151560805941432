import React, { useEffect, useState } from 'react';
import { Container, Grid, Header, Modal, Placeholder, Rating } from 'semantic-ui-react';
import { getMediaDetails } from '../../services/Tvshow';
import { notifError } from '../Tools/NotificationToasts';
import SeasonsTable from './SeasonsTable';
import DiscoveryCardDimmer from './DiscoveryCardDimmer';

function DiscoveryDetailedCard({
  tmdbId,
  mediaType,
  open,
  setOpen,
  watchItems,
  refreshWatchItems,
}) {
  const [state, setState] = useState({
    details: [],
    loading: true,
    error: false,
  });

  const getDetails = () => {
    let type;
    switch (mediaType) {
      case 'MOVIE':
        type = 'movie';
        break;
      case 'tv':
      case 'TV':
      case 'SEASON':
      case 'EPISODE':
        type = 'tv';
        break;
      default:
        type = 'movie';
    }
    getMediaDetails(tmdbId, type)
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          setState({
            details: response.data.result.data,
            loading: false,
            error: false,
          });
        } else {
          setState({
            details: [],
            loading: false,
            error: true,
          });
          notifError('Échec de chargement de la fiche');
        }
      })
      .catch((e) => {
        notifError(`Échec de chargement de la fiche: ${e}`);
        setState({
          details: [],
          loading: false,
          error: true,
        });
      });
  };

  useEffect(() => {
    if (open) getDetails();
  }, [open]);

  const title = state.details.title || state.details.name;
  const originalTitle = state.details.original_title || state.details.original_name;

  return (
    <Modal
      size="large"
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      closeIcon
    >
      {state.loading && (
        <>
          <Modal.Header>
            <Placeholder>
              <Placeholder.Header>
                <Placeholder.Line />
              </Placeholder.Header>
            </Placeholder>
          </Modal.Header>
          <Modal.Content>
            <Grid stackable columns={2} divided>
              <Grid.Row>
                <Grid.Column>
                  <Placeholder style={{ height: 479, width: 342 }}>
                    <Placeholder.Image />
                  </Placeholder>
                </Grid.Column>
                <Grid.Column>
                  <Placeholder>
                    <Placeholder.Paragraph>
                      <Placeholder.Line />
                      <Placeholder.Line />
                      <Placeholder.Line />
                      <Placeholder.Line />
                      <Placeholder.Line />
                      <Placeholder.Line />
                      <Placeholder.Line />
                      <Placeholder.Line />
                      <Placeholder.Line />
                      <Placeholder.Line />
                      <Placeholder.Line />
                      <Placeholder.Line />
                    </Placeholder.Paragraph>
                  </Placeholder>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Modal.Content>
        </>
      )}
      {!state.loading && !state.error && (
        <>
          <Modal.Header>{title}</Modal.Header>
          <Modal.Content>
            <Grid stackable columns={2} divided>
              <Grid.Row>
                <Grid.Column>
                  {state.details.poster_path ? (
                    <Container style={{ width: 342 }}>
                      <DiscoveryCardDimmer
                        id={tmdbId}
                        type={mediaType}
                        title={state.details.title || state.details.name}
                        posterPath={state.details.poster_path}
                        detailed={false}
                      />
                    </Container>
                  ) : (
                    <Placeholder style={{ height: 479, width: 342 }}>
                      <Placeholder.Image />
                    </Placeholder>
                  )}
                </Grid.Column>
                {['tv', 'TV', 'SEASON', 'EPISODE'].includes(mediaType) && (
                  <Grid.Column>
                    <Header as="h3">Résumé</Header>
                    {state.details.overview}
                    <Header as="h3">Détails</Header>
                    Note:{' '}
                    <Rating
                      icon="star"
                      rating={state.details.vote_average / 2}
                      maxRating={5}
                      disabled
                    />
                    ({state.details.vote_count} votes)
                    <br />
                    {title !== originalTitle && (
                      <>
                        Titre original: {originalTitle}
                        <br />
                      </>
                    )}
                    Commencé le {new Date(state.details.first_air_date).toLocaleDateString()}
                    <br />
                    Production: {state.details.in_production ? 'En cours de tournage' : 'Terminé'}
                    <br />
                    Nombre de saisons: {state.details.number_of_seasons}
                    <br />
                    Nombre d&apos;épisodes: {state.details.number_of_episodes}
                  </Grid.Column>
                )}
                {['movie', 'MOVIE'].includes(mediaType) && (
                  <Grid.Column>
                    <Header as="h3">Résumé</Header>
                    {state.details.overview}
                    <Header as="h3">Détails</Header>
                    Note:{' '}
                    <Rating
                      icon="star"
                      rating={state.details.vote_average / 2}
                      maxRating={5}
                      disabled
                    />
                    ({state.details.vote_count} votes)
                    <br />
                    {title !== originalTitle && (
                      <>
                        Titre original: {originalTitle}
                        <br />
                      </>
                    )}
                    Date de sortie: {new Date(state.details.release_date).toLocaleDateString()}
                    <br />
                    Durée: {state.details.runtime} minutes
                  </Grid.Column>
                )}
              </Grid.Row>
            </Grid>
            {['tv', 'TV', 'SEASON', 'EPISODE'].includes(mediaType) && (
              <SeasonsTable
                tmdbId={tmdbId}
                mediaType={mediaType}
                watchItems={watchItems}
                refreshWatchItems={refreshWatchItems}
              />
            )}
          </Modal.Content>
        </>
      )}
    </Modal>
  );
}

export default DiscoveryDetailedCard;
