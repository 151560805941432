import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Route, Routes, Navigate, useLocation } from 'react-router';
import { AuthProvider } from '../../contexts/AuthContext';
import HomePage from '../../pages/HomePage';
import ContactPage from '../../pages/ContactPage';
import AccountPage from '../../pages/AccountPage';
import ExpiredPage from '../../pages/ExpiredPage';
import PendingPage from '../../pages/PendingPage';
import BannedPage from '../../pages/BannedPage';
import UnavailablePage from '../../pages/UnavailablePage';
import NotFoundPage from '../../pages/NotFoundPage';
import DevicesPage from '../../pages/DevicesPage';
import SettingsPage from '../../pages/SettingsPage';
import SettingsEditDevicePage from '../../pages/SettingsEditDevicePage';
import DevicesNewPage from '../../pages/DevicesNewPage';
import SettingsEditUserPage from '../../pages/SettingsEditUserPage';
import ForgotPasswordPage from '../../pages/ForgotPasswordPage';
import ResetPasswordPage from '../../pages/ResetPasswordPage';
import RegistrationPage from '../../pages/RegistrationPage';
import EmailValidationPage from '../../pages/EmailValidationPage';
import MagicLoginPage from '../../pages/MagicLoginPage';
import StreamingPage from '../../pages/StreamingPage';
import LoginPage from '../../pages/LoginPage';
import { isLoggedIn } from '../../services/Axios';

function Router() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route
            path="/"
            element={
              <RequireAuth>
                <HomePage />
              </RequireAuth>
            }
          />
          <Route
            path="/devices"
            element={
              <RequireAuth>
                <DevicesPage />
              </RequireAuth>
            }
          />
          <Route
            path="/devices/new"
            element={
              <RequireAuth>
                <DevicesNewPage />
              </RequireAuth>
            }
          />
          <Route
            path="/settings"
            element={
              <RequireAuth>
                <SettingsPage />
              </RequireAuth>
            }
          />
          <Route
            path="/settings/devices/edit/:id"
            element={
              <RequireAuth>
                <SettingsEditDevicePage />
              </RequireAuth>
            }
          />
          <Route
            path="/settings/users/edit/:id"
            element={
              <RequireAuth>
                <SettingsEditUserPage />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/account"
            element={
              <RequireAuth>
                <AccountPage />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/stream"
            element={
              <RequireAuth>
                <StreamingPage />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/expired"
            element={
              <RequireAuth>
                <ExpiredPage />
              </RequireAuth>
            }
          />
          <Route path="/pending" element={<PendingPage />} />
          <Route path="/banned" element={<BannedPage />} />
          <Route path="/unavailable" element={<UnavailablePage />} />
          <Route path="/register" element={<RegistrationPage />} />
          <Route path="/magiclink/:magicToken" element={<MagicLoginPage />} />
          <Route exact path="/forgotpassword" element={<ForgotPasswordPage />} />
          <Route path="/email-validation/:resetToken/:email" element={<EmailValidationPage />} />
          <Route path="/resetpassword/:resetToken" element={<ResetPasswordPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route
            path="*"
            element={
              <RequireAuth>
                <NotFoundPage />
              </RequireAuth>
            }
          />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}

function RequireAuth({ children }) {
  const location = useLocation();
  const isAuthenticated = isLoggedIn();
  return isAuthenticated ? (
    children
  ) : location.pathname === '/' ? (
    <LoginPage state={{ from: location }} />
  ) : (
    <Navigate to="/" />
  );
}

export default Router;
