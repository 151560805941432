import React, { useEffect, useRef } from 'react';
import { Button, Header, Icon, Modal } from 'semantic-ui-react';
import { notifInfo } from '../Tools/NotificationToasts';

function DeviceTaskDeleteModal({
  setOpen,
  open,
  action,
  deviceId,
  taskId,
  handleDeleteTaskAction,
}) {
  const buttonRef = useRef(null);
  useEffect(() => {
    if (buttonRef.current !== null) buttonRef.current.focus();
  });

  return (
    <Modal
      basic
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="small"
    >
      <Header icon>
        <Icon name="trash alternate outline" color="red" />
        <br />
        Supprimer cette tâche ?
      </Header>
      <Modal.Content />
      <Modal.Actions>
        <Button basic color="red" inverted onClick={() => setOpen(false)}>
          <Icon name="remove" /> Annuler
        </Button>
        <Button
          color="red"
          inverted
          ref={buttonRef}
          onClick={() => {
            notifInfo('Suppression de la tâche...');
            handleDeleteTaskAction(deviceId, taskId, action);
            setOpen(false);
          }}
        >
          <Icon name="trash alternate outline" /> Supprimer
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default DeviceTaskDeleteModal;
