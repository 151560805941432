import React, { useEffect, useRef } from 'react';
import { Button, Header, Icon, Modal } from 'semantic-ui-react';

function DeviceDeleteModal({ setOpen, open, deviceName, deviceId, handleDelete }) {
  const buttonRef = useRef(null);

  useEffect(() => {
    if (buttonRef.current !== null) buttonRef.current.focus();
  });

  return (
    <Modal
      basic
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="small"
    >
      <Header icon>
        <Icon name="trash alternate outline" color="red" />
        <br />
        Supprimer définitivement {deviceName} ?
      </Header>
      <Modal.Content>
        La configuration et l&apos;historique de téléchargement de cet appareil seront supprimés.
      </Modal.Content>
      <Modal.Actions>
        <Button basic color="red" inverted onClick={() => setOpen(false)}>
          <Icon name="remove" /> Annuler
        </Button>
        <Button
          color="red"
          inverted
          ref={buttonRef}
          onClick={() => {
            handleDelete(deviceId, deviceName);
            setOpen(false);
          }}
        >
          <Icon name="trash alternate outline" /> Supprimer
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default DeviceDeleteModal;
