import React, { useEffect, useState } from 'react';
import { Icon, Label, Modal, Table } from 'semantic-ui-react';
import TimeAgo from 'react-timeago';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
import frenchStrings from '../Tools/TimeAgoFrStrings';
import { torrentHistory } from '../../services/Torrent';

const formatter = buildFormatter(frenchStrings);

function CloudHistoryModal({ setOpen, open, setSearchInput, focusSearchInput }) {
  const [state, setState] = useState({
    history: [],
    error: false,
  });

  const getTorrentHistory = () => {
    torrentHistory()
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          setState({ history: response.data.result, error: false });
        } else setState({ history: [], error: true });
      })
      .catch(() => {
        setState({ history: [], error: true });
      });
  };

  useEffect(() => {
    getTorrentHistory();
  }, []);

  return (
    <Modal
      closeIcon
      size="large"
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
    >
      <Modal.Header>
        <Icon name="history" /> Historique Cloud
      </Modal.Header>
      <Modal.Content scrolling>
        {state.error ? (
          <p>
            Erreur lors de la récupération de l&apos;historique
            <br />
            Veuillez réessayer ultérieurement
          </p>
        ) : (
          <Table unstackable selectable>
            <Table.Body>
              {state.history.map((item) => {
                return (
                  <Table.Row
                    key={`cloud-history-item-${item.downloader_id + encodeURI(item.file)}`}
                  >
                    <Table.Cell style={{ wordBreak: 'break-all' }}>
                      {item.file}
                      <br />
                      <Label color="blue" size="small" ribbon>
                        {item.user.nickname}
                      </Label>
                      <Label color="teal" size="small" basic>
                        <TimeAgo date={item.updated_at} formatter={formatter} />
                      </Label>{' '}
                      <Label color="grey" size="small" basic>
                        Téléchargé {item.count} fois
                      </Label>
                      <Label
                        className="clickable"
                        color="green"
                        size="small"
                        onClick={() => {
                          setOpen(false);
                          setSearchInput(
                            item.file.replace('Lien magnet', '').replace('.torrent', '').trim()
                          );
                          focusSearchInput();
                        }}
                      >
                        <Icon name="search" />
                        Chercher
                      </Label>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        )}
      </Modal.Content>
    </Modal>
  );
}

export default CloudHistoryModal;
