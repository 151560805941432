import React, { useState } from 'react';
import { Radio } from 'semantic-ui-react';
import { subscribeShow } from '../../services/Tvshow';
import { notifError, notifSuccess } from '../Tools/NotificationToasts';

function SubscriptionTableSwitch({
  enabled,
  subscriberId,
  isUser,
  indexerId,
  subscriptionCallback,
}) {
  const [loading, setLoading] = useState(false);

  const handleSubscribeAction = () => {
    setLoading(true);
    subscribeShow({
      subscriber_id: subscriberId,
      is_user: isUser,
      indexerid: indexerId,
    })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          subscriptionCallback(indexerId, subscriberId, isUser === 'true', !enabled);
          notifSuccess(`Abonnement ${!enabled ? 'activé' : 'désactivé'}`);
        } else notifError("Échec de modification de l'abonnement");
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        notifError(`Échec de modification de l'abonnement: ${e}`);
      });
  };

  return loading ? (
    <Radio toggle disabled checked />
  ) : (
    <Radio
      toggle
      checked={enabled}
      onChange={() => {
        handleSubscribeAction();
      }}
    />
  );
}

export default SubscriptionTableSwitch;
