import React from 'react';
import { Header, Icon, Label, Segment, Tab } from 'semantic-ui-react';
import SearchTable from './SearchTable';
import SearchTablePlaceholder from './SearchTablePlaceholder';

function SearchTab({ terms, searchLoading, searchError, searchData, setOpenDiscovery }) {
  return (
    <Tab.Pane className="row content transparent" attached="top">
      {searchLoading ? (
        <SearchTablePlaceholder />
      ) : searchError ? (
        <Segment color="orange" textAlign="center" placeholder>
          <Header icon>
            <Icon name="exclamation triangle" color="orange" />
            Une erreur est survenue lors de la recherche
          </Header>
          Merci de ré-essayer ultérieurement
        </Segment>
      ) : searchData.length === 0 ? (
        <Segment textAlign="center" color="yellow" placeholder>
          <Header icon>
            <Icon name="info circle" color="yellow" />
            Aucun résultat trouvé
          </Header>
          lors de la recherche de {terms}
          <Label
            basic
            color={
              [
                'red',
                'orange',
                'yellow',
                'olive',
                'green',
                'teal',
                'blue',
                'violet',
                'purple',
                'pink',
                'brown',
                'grey',
                'black',
              ][Math.floor(Math.random() * 13)]
            }
            attached="bottom"
            className="clickable"
            onClick={() => setOpenDiscovery(true)}
          >
            Besoin d&apos;inspiration ? Rendez-vous dans Discovery <Icon name="fire" />
          </Label>
        </Segment>
      ) : (
        <SearchTable terms={terms} data={searchData} />
      )}
    </Tab.Pane>
  );
}

export default SearchTab;
