import { Base64 } from 'js-base64';

export const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*[^A-Za-z0-9]).{8,50}$/;
export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const rmBrackets = (name) => {
  return name
    .trim()
    .replace(/ *\[[^\]]*]/, '')
    .trim()
    .replace(/ *\[[^\]]*]/, '')
    .trim();
};

export const firstCharUp = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const humanFileSize = (size) => {
  const i = Math.floor(Math.log(size) / Math.log(1024));
  return `${(size / 1024 ** i).toFixed(2) * 1} ${['b', 'kb', 'Mb', 'Gb', 'Tb'][i]}`;
};

export const millisecondsToStr = (milliseconds) => {
  function numberEnding(number) {
    return number > 1 ? 's' : '';
  }

  let temp = Math.floor(milliseconds / 1000);
  const years = Math.floor(temp / 31536000);
  if (years) {
    return `${years} an${numberEnding(years)}`;
  }
  const days = Math.floor((temp %= 31536000) / 86400);
  if (days) {
    return `${days} jr${numberEnding(days)}`;
  }
  const hours = Math.floor((temp %= 86400) / 3600);
  if (hours) {
    return `${hours} hr${numberEnding(hours)}`;
  }
  const minutes = Math.floor((temp %= 3600) / 60);
  if (minutes) {
    return `${minutes} min${numberEnding(minutes)}`;
  }
  const seconds = temp % 60;
  if (seconds) {
    return `${seconds} sec${numberEnding(seconds)}`;
  }
  return '< 1 sec'; // 'just now'
};

export const oneDayAgo = (date) => {
  const day = 1000 * 60 * 60 * 24;
  const dayAgo = Date.now() - day;
  return date > dayAgo;
};

export const parentDirectory = (path) => {
  const plain = atob(path);
  let previous = plain.substring(0, plain.lastIndexOf('/'));
  if (previous === '') previous = '/';
  return Base64.encode(previous);
};

export const cleanDiacritic = (string) => {
  return string
    .replace('’', "'")
    .normalize('NFD')
    .replace(/\p{Diacritic}/gu, '');
};
