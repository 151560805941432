import React from 'react';
import { Grid, Card } from 'semantic-ui-react';
import MediaCardPlaceholder from '../MediaCard/MediaCardPlaceholder';

function MediaSchedulerCardsPlaceholder() {
  return (
    <Grid>
      <Card.Group centered>
        <MediaCardPlaceholder type="scheduler" />
        <MediaCardPlaceholder type="scheduler" />
        <MediaCardPlaceholder type="scheduler" />
        <MediaCardPlaceholder type="scheduler" />
        <MediaCardPlaceholder type="scheduler" />
        <MediaCardPlaceholder type="scheduler" />
        <MediaCardPlaceholder type="scheduler" />
        <MediaCardPlaceholder type="scheduler" />
        <MediaCardPlaceholder type="scheduler" />
      </Card.Group>
    </Grid>
  );
}

export default MediaSchedulerCardsPlaceholder;
