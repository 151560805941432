import React, { useEffect, useState } from 'react';
import trianglify from 'trianglify';

function BackgroundRandomizer({ onDragEnter, children }) {
  const [currentBg, setBg] = useState(null);

  const changeBackground = () => {
    setBg(
      btoa(
        trianglify({
          width: window.innerWidth,
          height: window.innerHeight,
        }).toSVGTree()
      )
    );
  };

  useEffect(() => {
    changeBackground();
  }, []);

  return (
    <div
      className="box background-image"
      style={{ backgroundImage: `url(data:image/svg+xml;base64,${currentBg})` }}
      onDragEnter={onDragEnter}
    >
      {children}
    </div>
  );
}

export default BackgroundRandomizer;
