import React, { useCallback, useEffect, useState } from 'react';
import { Loader, Button, Grid, Header, Icon, Segment } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';
import NavBarAuth from '../components/NavBar/NavBarAuth';
import NavBarGuest from '../components/NavBar/NavBarGuest';
import { AuthConsumer } from '../contexts/AuthContext';
import { emailValidation, getLocalServerConfig } from '../services/Server';
import { emailRegex } from '../components/Tools/Utils';
import BackgroundRandomizer from '../components/Tools/BackgroundRandomizer';

function EmailValidationPage() {
  const { email, resetToken } = useParams();
  const [state, setState] = useState({
    result: '',
    sending: false,
    done: false,
  });

  const handleValidation = useCallback(() => {
    if (email === '') {
      setState((prevState) => ({ ...prevState, result: "L'email ne peut pas être vide" }));
      return false;
    }
    if (!emailRegex.test(email)) {
      setState((prevState) => ({ ...prevState, result: 'Email incorrect' }));
      return false;
    }

    setState((prevState) => ({ ...prevState, result: '', sending: true }));
    emailValidation({ resetToken, email })
      .then((response) => {
        if (response.status === 200 && response.data.success)
          setState((prevState) => ({ ...prevState, result: '', sending: false, done: true }));
        else
          setState((prevState) => ({
            ...prevState,
            result: `Échec de la demande: ${response.data.message}`,
            sending: false,
          }));
      })
      .catch((e) => {
        console.log(e);
        setState((prevState) => ({
          ...prevState,
          result: `Échec de la demande: ${e}`,
          sending: false,
        }));
      });
    return true;
  }, [email, resetToken]);

  useEffect(() => {
    document.title = `${
      getLocalServerConfig().siteName || 'Ergo'
    } | Vérification de l'adresse email`;
    handleValidation();
  }, [handleValidation]);

  return (
    <AuthConsumer>
      {({ isAuth }) => (
        <BackgroundRandomizer>
          {isAuth ? <NavBarAuth /> : <NavBarGuest />}
          <Grid
            textAlign="center"
            style={{ overflowY: 'scroll', height: '100vh' }}
            verticalAlign="middle"
          >
            <Grid.Column style={{ maxWidth: 900 }}>
              <Segment className="transparent-table">
                <br />
                <Header as="h2" color="teal">
                  <Icon name={state.done ? 'check' : 'mail'} /> Vérification de votre adresse email
                </Header>
                <br />
                {state.done && (
                  <>
                    <Header as="h4">Votre adresse email a été vérifée avec succès.</Header>
                    <Header.Subheader>
                      Si votre compte a été validé par un administrateur, vous devriez pouvoir vous
                      connecter.
                    </Header.Subheader>
                    <br />
                    <Button color="teal" size="big" as="a" href="/">
                      <Icon name="home" /> Accueil
                    </Button>
                    <br />
                    <br />
                    <br />
                  </>
                )}
                {state.result !== '' && (
                  <>
                    <div>
                      <span className="text-danger">{state.result}</span>
                    </div>
                    <br />
                    <br />
                    <Button
                      icon="home"
                      label="Accueil"
                      labelPosition="right"
                      color="teal"
                      size="large"
                      as="a"
                      href="/"
                    />
                  </>
                )}
                {state.sending && (
                  <>
                    <Loader active content="Chargement" />
                    <br />
                    <br />
                    <br />
                  </>
                )}
              </Segment>
            </Grid.Column>
          </Grid>
        </BackgroundRandomizer>
      )}
    </AuthConsumer>
  );
}

export default EmailValidationPage;
