import React, { useState, useMemo } from 'react';
import { useNavigate } from 'react-router';
import Axios from '../services/Axios';
import { deleteCookie, getToken, logout } from '../services/Auth';

const AuthContext = React.createContext();

function AuthProvider({ children }) {
  const navigate = useNavigate();
  const [state, setState] = useState({
    user: null,
    isAuth: !!getToken(),
    errMessage: '',
  });

  const login = (user, authorization, errMessage) => {
    Axios.defaults.headers.common = {
      Authorization: `Bearer ${authorization}`,
    };
    setState({
      user,
      isAuth: true,
      errMessage,
    });
  };

  const doLogout = () => {
    setState({});
    logout().finally(() => {
      localStorage.clear();
      deleteCookie('token', '/');
      deleteCookie('io', '/');
      delete Axios.defaults.headers.common;
      navigate('/');
    });
  };

  const memoizedProp = useMemo(
    () => ({
      ...state,
      login,
      logout: doLogout,
    }),
    [state, login, doLogout]
  );

  return <AuthContext.Provider value={memoizedProp}>{children}</AuthContext.Provider>;
}

const AuthConsumer = AuthContext.Consumer;

export { AuthContext, AuthProvider, AuthConsumer };
