import React, { useEffect, useRef } from 'react';
import { Button, Header, Icon, Modal } from 'semantic-ui-react';

function ZipModal({ setOpen, open, item, handleZipFolder }) {
  const buttonRef = useRef(null);
  useEffect(() => {
    if (buttonRef.current !== null) buttonRef.current.focus();
  });

  return (
    <Modal
      basic
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="small"
    >
      <Header icon>
        <Icon name="file archive outline" color={item.zipped ? 'yellow' : 'green'} />
        <br />
        Zipper le dossier {item.file} ?
      </Header>
      <Modal.Content>
        {item.zipped
          ? 'Il existe déjà une version Zip de ce dossier, êtes vous sûr(e) de vouloir continuer ?'
          : 'Vous êtes sur le point de créer une archive Zip de ce dossier.'}
      </Modal.Content>
      <Modal.Actions>
        <Button basic color="red" inverted onClick={() => setOpen(false)}>
          <Icon name="remove" /> Annuler
        </Button>
        <Button
          color={item.zipped ? 'yellow' : 'green'}
          inverted
          ref={buttonRef}
          onClick={() => {
            handleZipFolder([item]);
            setOpen(false);
          }}
        >
          <Icon name="file archive outline" /> Zipper
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

function ZipMultipleModal({ setOpen, open, items, handleZipFolder }) {
  const buttonRef = useRef(null);
  useEffect(() => {
    if (buttonRef.current !== null) buttonRef.current.focus();
  });

  return (
    <Modal
      basic
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="small"
    >
      <Header icon>
        <Icon
          name="file archive outline"
          color={items.length === 1 && items[0].zipped ? 'yellow' : 'green'}
        />
        <br />
        Zipper les éléments suivants dans une archive ?
      </Header>
      <Modal.Content>
        {items.map((item) => (
          <span key={`zip-multiple-modal-${item.file}`}>
            <Icon name={item.type === 'dir' ? 'folder open outline' : 'file alternate outline'} />
            {item.file}
            <br />
          </span>
        ))}
        {items.length === 1 && items[0].zipped && (
          <>
            <br />
            Il existe déjà une version Zip de cet élément, êtes vous sûr(e) de vouloir continuer ?
          </>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button basic color="red" inverted onClick={() => setOpen(false)}>
          <Icon name="remove" /> Annuler
        </Button>
        <Button
          color={items.length === 1 && items[0].zipped ? 'yellow' : 'green'}
          inverted
          ref={buttonRef}
          onClick={() => {
            handleZipFolder(items);
            setOpen(false);
          }}
        >
          <Icon name="file archive outline" /> Zipper
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export { ZipModal, ZipMultipleModal };
