import React from 'react';
import { Icon, Label, Table } from 'semantic-ui-react';
import { Media } from '../Tools/AppMedia';

function DirectoryCloudTableHeader({ count }) {
  return (
    <>
      <Media greaterThanOrEqual="lg">
        {(mediaClassNames, renderChildren) => {
          return renderChildren ? (
            <Table.Row className="tr-header">
              <Table.Cell className="td-center" collapsing>
                <Icon name="tasks" />
              </Table.Cell>
              <Table.Cell className="td-center" collapsing>
                <Icon name="info" />
              </Table.Cell>
              <Table.Cell className="td-center" collapsing />
              <Table.Cell className="td-left">
                <Label horizontal color="green">
                  En téléchargement ({count})
                </Label>
              </Table.Cell>
              <Table.Cell className="td-center" collapsing>
                <Icon name="percent" />
              </Table.Cell>
              <Table.Cell className="td-center" collapsing>
                <Icon name="exchange" rotated="clockwise" />
              </Table.Cell>
              <Table.Cell className="td-center" collapsing>
                <Icon name="clock outline" />
              </Table.Cell>
              <Table.Cell className="td-right" collapsing>
                Taille
              </Table.Cell>
              <Table.Cell className="td-center" collapsing>
                <Icon name="trash alternate outline" />
              </Table.Cell>
            </Table.Row>
          ) : null;
        }}
      </Media>
      <Media lessThan="lg">
        {(mediaClassNames, renderChildren) => {
          return renderChildren ? (
            <Table.Row className="tr-header">
              <Table.Cell className="td-left">
                <Label horizontal color="green">
                  En téléchargement ({count})
                </Label>
              </Table.Cell>
              <Table.Cell collapsing />
              <Table.Cell collapsing />
            </Table.Row>
          ) : null;
        }}
      </Media>
    </>
  );
}

export default DirectoryCloudTableHeader;
