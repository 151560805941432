import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Header, Icon, Modal, TextArea } from 'semantic-ui-react';
import { sendBug } from '../../services/Server';
import { notifError, notifSuccess, notifWarning } from '../Tools/NotificationToasts';

function BugModal({ setOpen, open }) {
  const [bug, setBug] = useState('');
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current !== null) inputRef.current.focus();
  });

  const handleNotifyBug = (message) => {
    if (message != null && message.length > 0) {
      sendBug({ bug: message })
        .then((response) => {
          if (response.status === 200 && response.data.success) {
            setOpen(false);
            setBug('');
            notifSuccess('Message envoyé avec succès');
          } else notifError('Échec de notification du bug');
        })
        .catch((e) => {
          notifError(`Échec de notification du bug: ${e}`);
        });
    } else {
      notifWarning("Aucun message n'a été envoyé");
      setOpen(false);
    }
  };

  return (
    <Modal
      basic
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="small"
    >
      <Header icon>
        <Icon name="bug" color="orange" />
        <br />
        Signaler un bug ou proposer une amélioration
      </Header>
      <Modal.Content>
        Expliquez le bug rencontré ou l&apos;amélioration souhaitée en quelques mots :
        <br />
        <br />
        <Form>
          <TextArea
            rows={3}
            ref={inputRef}
            value={bug}
            onChange={(event) => setBug(event.target.value)}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button basic color="grey" inverted onClick={() => setOpen(false)}>
          <Icon name="remove" /> Annuler
        </Button>
        <Button
          color="green"
          inverted
          onClick={() => {
            handleNotifyBug(bug);
            setOpen(false);
          }}
        >
          <Icon name="paper plane" /> Envoyer
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default BugModal;
