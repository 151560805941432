import React, { useState } from 'react';
import { Button, Popup } from 'semantic-ui-react';
import { deleteWatchItem, upsertWatchItem } from '../../services/Tvshow';
import { cleanDiacritic } from '../Tools/Utils';
import { notifError } from '../Tools/NotificationToasts';

function WatchListButton({
  id,
  title,
  originalTitle,
  type,
  added,
  refreshWatchItems,
  season = -1,
  episode = -1,
}) {
  const [loading, setLoading] = useState(false);

  const toggleWatch = () => {
    const mediaType = type.toUpperCase();
    setLoading(true);
    if (!added) {
      upsertWatchItem({
        tmdbId: id,
        type: mediaType,
        title: cleanDiacritic(title),
        originalTitle: cleanDiacritic(originalTitle),
        season,
        episode,
        seen: false,
        downloaded: false,
      })
        .then((response) => {
          if (response.status === 200 && response.data.success) refreshWatchItems();
          else notifError("Échec d'ajout dans la liste");
        })
        .catch((e) => {
          notifError(`Échec d'ajout dans la liste: ${e}`);
        })
        .finally(() => setLoading(false));
    } else {
      deleteWatchItem({ tmdbId: id, type: mediaType, season, episode })
        .then((response) => {
          if (response.status === 200 && response.data.success) refreshWatchItems();
          else notifError('Échec de suppression dans la liste');
        })
        .catch((e) => {
          notifError(`Échec de retrait dans la liste: ${e}`);
        })
        .finally(() => setLoading(false));
    }
  };

  return loading ? (
    <Button loading size="mini" color="teal" icon="circle notch" />
  ) : (
    <Popup
      hideOnScroll
      trigger={
        <Button
          size="mini"
          color={added ? 'yellow' : 'green'}
          icon={added ? 'minus' : 'plus'}
          label={added ? 'Ajouté' : 'Ajouter'}
          labelPosition="right"
          onClick={toggleWatch}
        />
      }
      mouseEnterDelay={150}
      content={added ? 'Retirer de ma liste' : 'Ajouter à ma liste'}
      position="top center"
    />
  );
}

export default WatchListButton;
