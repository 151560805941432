import React from 'react';
import { Grid, Pagination } from 'semantic-ui-react';

function DiscoveryPagination({ currentPage, totalPages, getElements, scrollTop }) {
  return (
    <Grid>
      <Grid.Row centered>
        <Pagination
          secondary
          boundaryRange={window.innerWidth > 1210 ? 2 : 0}
          siblingRange={1}
          ellipsisItem={window.innerWidth > 1210 ? '...' : null}
          activePage={currentPage}
          totalPages={totalPages}
          onPageChange={(e, { activePage }) => {
            getElements(activePage);
            scrollTop();
          }}
        />
      </Grid.Row>
    </Grid>
  );
}

export default DiscoveryPagination;
