import React, { useState, useEffect } from 'react';
import { Button, Container, Grid, Header, Icon, Image, List, Placeholder } from 'semantic-ui-react';
import { getMediaDetails } from '../../services/Tvshow';
import { notifError } from '../Tools/NotificationToasts';
import { getLocalServerConfig } from '../../services/Server';
import WatchListButton from './WatchListButton';
import EpisodesAccordion from './EpisodesAccordion';

function SeasonsTable({ tmdbId, mediaType, watchItems, refreshWatchItems }) {
  const [state, setState] = useState({
    show: false,
    details: null,
    loading: true,
    error: false,
  });

  const getDetails = () => {
    let type;
    switch (mediaType) {
      case 'movie':
      case 'MOVIE':
        type = 'movie';
        break;
      default:
        type = 'tv';
    }
    getMediaDetails(tmdbId, type)
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          setState({
            show: true,
            details: response.data.result.data,
            loading: false,
            error: false,
          });
        } else {
          setState({
            show: true,
            details: null,
            loading: false,
            error: true,
          });
          notifError('Échec de chargement de la fiche');
        }
      })
      .catch((e) => {
        notifError(`Échec de chargement de la fiche: ${e}`);
        setState({
          show: true,
          details: null,
          loading: false,
          error: true,
        });
      });
  };

  const handleShow = (show) => {
    setState((prevState) => ({ ...prevState, show }));
  };

  useEffect(() => {
    if (state.show && state.details === null) getDetails();
  }, [state.show]);

  return (
    <Container>
      {state.show ? (
        <>
          {!state.loading && !state.error && (
            <>
              <Grid>
                <Grid.Row centered>
                  <Button color="teal" onClick={() => handleShow(false)}>
                    <Icon name="arrow up" />
                    Liste des Saisons / Épisodes
                  </Button>
                </Grid.Row>
              </Grid>
              {state.details.seasons.length > 0 ? (
                <List celled>
                  {state.details.seasons.map((season) => {
                    let added = false;
                    watchItems.forEach((item) => {
                      if (
                        item.tmdbId === state.details.id &&
                        item.type === 'SEASON' &&
                        item.season === season.season_number &&
                        item.episode === -1
                      )
                        added = true;
                    });
                    return (
                      <List.Item
                        key={`season-modal-${tmdbId}-${season.id}-${season.season_number}`}
                      >
                        <Grid stackable columns={3}>
                          <Grid.Row>
                            <Grid.Column verticalAlign="middle" width={2}>
                              {season.poster_path && (
                                <Image
                                  src={`${getLocalServerConfig().tmdbBaseUrl}w92${
                                    season.poster_path
                                  }`}
                                />
                              )}
                            </Grid.Column>
                            <Grid.Column width={12}>
                              <br />
                              <Header as="h3">{season.name}</Header>
                              <br />
                              <Header as="h5">
                                {season.air_date
                                  ? `Diffusée le ${new Date(season.air_date).toLocaleDateString()}`
                                  : 'Pas encore diffusé'}
                              </Header>{' '}
                              <br />
                              {season.overview || 'Pas de résumé'}
                              <br />
                              <br />
                              {season.episode_count > 0 ? (
                                <EpisodesAccordion
                                  tmdbId={tmdbId}
                                  title={state.details.name}
                                  originalTitle={state.details.original_name}
                                  seasonNumber={season.season_number}
                                  refreshWatchItems={refreshWatchItems}
                                  watchItems={watchItems}
                                />
                              ) : (
                                'Aucun épisode'
                              )}
                              <br />
                              <br />
                            </Grid.Column>
                            <Grid.Column verticalAlign="middle" width={2}>
                              <WatchListButton
                                id={tmdbId}
                                title={state.details.name}
                                originalTitle={state.details.original_name}
                                type="SEASON"
                                added={added}
                                season={season.season_number}
                                episode={-1}
                                refreshWatchItems={refreshWatchItems}
                              />
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </List.Item>
                    );
                  })}
                </List>
              ) : (
                <Header as="h3">Aucune saison disponible.</Header>
              )}
            </>
          )}
          {state.error && !state.loading && (
            <Button color="orange" onClick={() => getDetails()}>
              <Icon name="refresh" />
              Ré-essayer
            </Button>
          )}
          {state.loading && (
            <Placeholder>
              <Placeholder.Paragraph>
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
              </Placeholder.Paragraph>
            </Placeholder>
          )}
        </>
      ) : (
        <Grid>
          <Grid.Row centered>
            <Button color="teal" onClick={() => handleShow(true)}>
              <Icon name="arrow down" />
              Liste des Saisons / Épisodes
            </Button>
          </Grid.Row>
        </Grid>
      )}
    </Container>
  );
}

export default SeasonsTable;
