import React, { useContext, useEffect, useState } from 'react';
import '../assets/css/LoginPage.css';
import { Navigate, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { Base64 } from 'js-base64';
import {
  Loader,
  Popup,
  Label,
  Button,
  Form,
  Card,
  Grid,
  Header,
  Icon,
  Segment,
} from 'semantic-ui-react';
import { AuthContext } from '../contexts/AuthContext';
import { createMagicLink, getMagicLink, initializeSession, Login } from '../services/Auth';
import { getServerStatus } from '../services/Server';
import NavBarGuest from '../components/NavBar/NavBarGuest';
import { emailRegex, passwordRegex } from '../components/Tools/Utils';
import BackgroundRandomizer from '../components/Tools/BackgroundRandomizer';

const { REACT_APP_BUILD_ID: buildId } = process.env;

function LoginPage() {
  const navigate = useNavigate();
  const { login, logout } = useContext(AuthContext);

  let pollMagicLoginStatusInterval = 0;

  const [state, setState] = useState({
    siteName: 'Ergo',
    loginValidation: false,
    passwordValidation: false,
    loginError: '',
    login: '',
    loginInProgress: false,
    loginType: 'password',
    password: '',
    magicLoginPending: false,
    magicLoginSent: false,
    messageVisible: false,
    serverStatus: true,
    retryTick: 10,
    pending: false,
    banned: false,
    error: false,
  });

  const refreshServerStatus = () => {
    getServerStatus()
      .then((response) => {
        setState((prevState) => ({
          ...prevState,
          siteName: response.data.siteName,
          serverStatus: response.data.success,
          retryTick: 10,
        }));
      })
      .catch(() => {
        setState((prevState) => ({ ...prevState, serverStatus: false, retryTick: 10 }));
      });
  };

  useEffect(() => {
    document.title = `${state.siteName} | Connexion`;
  }, [state.siteName]);

  useEffect(() => {
    refreshServerStatus();
  }, []);

  useEffect(() => {
    const intervalStatus = setInterval(refreshServerStatus, 11000);
    return () => {
      clearInterval(intervalStatus);
    };
  }, [refreshServerStatus]);

  useEffect(() => {
    const retryTick = () => {
      if (state.retryTick > 0)
        setState((prevState) => ({ ...prevState, retryTick: state.retryTick - 1 }));
    };
    const intervalTick = setInterval(retryTick, 1000);
    return () => {
      clearInterval(intervalTick);
    };
  }, [state.retryTick]);

  const handleInputChange = (value, input) => {
    let validate = true;
    if (input === 'login') {
      if (!emailRegex.test(value)) validate = false;
      setState((prevState) => ({ ...prevState, [input]: value, loginValidation: validate }));
    }
    if (input === 'password') {
      if (!passwordRegex.test(value)) validate = false;
      setState((prevState) => ({ ...prevState, [input]: value, passwordValidation: validate }));
    }
  };

  const handleSwitchLoginType = () => {
    setState((prevState) => ({
      ...prevState,
      loginType: state.loginType === 'password' ? 'magiclink' : 'password',
    }));
  };

  const getAudience = () => {
    return Base64.encode(
      `${`${navigator.userAgent}`.substring(0, 200)} - [${new Date().getTime().toString()}]`,
      true
    );
  };

  const handleLogin = () => {
    if (!(state.loginValidation && state.passwordValidation && !state.loginInProgress)) return;
    setState((prevState) => ({ ...prevState, loginInProgress: true }));
    const tokenBlob = new Blob(
      [
        JSON.stringify(
          { email: state.login, password: state.password, sig: getAudience() },
          null,
          2
        ),
      ],
      { type: 'application/json' }
    );
    Login(tokenBlob).then((result) => {
      if (result.authResponse !== undefined && result.authResponse.status === 200) {
        login(
          result.authResponse.data,
          result.authResponse.headers.authorization,
          result.authResponse.statusText
        );
      } else if (result.data !== undefined) {
        if (result.status === 403)
          setState((prevState) => ({
            ...prevState,
            error: false,
            banned: true,
            pending: false,
            loginInProgress: false,
          }));
        if (result.status === 429)
          setState((prevState) => ({
            ...prevState,
            error: false,
            banned: false,
            pending: false,
            loginInProgress: false,
            loginError: 'Trop de rêquetes, réessayez dans 2 minutes',
          }));
        if (result.status === 401) {
          if (result.data.role !== undefined && result.data.role === 0)
            setState((prevState) => ({
              ...prevState,
              error: false,
              banned: false,
              pending: true,
              loginInProgress: false,
            }));
          else
            setState((prevState) => ({
              ...prevState,
              error: false,
              loginInProgress: false,
              loginError: result.data.message,
            }));
        }
      } else {
        setState((prevState) => ({
          ...prevState,
          error: true,
          banned: false,
          pending: false,
          loginInProgress: false,
        }));
        logout();
      }
      navigate('/');
    });
  };

  const pollMagicLoginStatus = (requestId, audience) => {
    getMagicLink(requestId, audience)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.success && response.data.granted) {
            clearInterval(pollMagicLoginStatusInterval);
            initializeSession(response.data.result, response.headers.authorization);
            login(response.data.result, response.headers.authorization, '');
            navigate('/');
          }
          if (!response.data.success && response.data.granted === undefined) {
            clearInterval(pollMagicLoginStatusInterval);
            setState((prevState) => ({
              ...prevState,
              magicLoginSent: false,
              magicLoginPending: false,
              loginError: response.data.message,
            }));
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleMagicLogin = () => {
    setState((prevState) => ({ ...prevState, magicLoginPending: true, loginError: '' }));
    createMagicLink({ email: state.login })
      .then((response) => {
        if (response.status === 200) {
          if (response.data.success) {
            setState((prevState) => ({
              ...prevState,
              magicLoginSent: true,
              loginError: '',
            }));
            pollMagicLoginStatusInterval = setInterval(
              (requestId, aud) => pollMagicLoginStatus(requestId, aud),
              3000,
              response.data.requestId,
              getAudience()
            );
          } else
            setState((prevState) => ({
              ...prevState,
              magicLoginSent: false,
              magicLoginPending: false,
              loginError: response.data.message,
            }));
        } else
          setState((prevState) => ({
            ...prevState,
            magicLoginSent: false,
            magicLoginPending: false,
            loginError: 'Une erreur est survenue pendant la requête. Réessayez ultérieurement',
          }));
      })
      .catch((e) => {
        console.log(e);
        setState((prevState) => ({
          ...prevState,
          magicLoginSent: false,
          magicLoginPending: false,
          loginError: 'Une erreur est survenue pendant la requête. Réessayez ultérieurement',
        }));
      });
  };

  const buildPasswordLoginBlock = (
    <div>
      <Form>
        <Card className="transparent-table" fluid>
          <Card.Content>
            <Card.Description>
              <Header as="h1" color="teal" textAlign="center">
                <span className="desktop-only">Bonjour et bienvenue sur </span>
                {state.siteName}
              </Header>
              <Form.Field>
                <label>Email</label>
                <Form.Input
                  autoComplete="email"
                  size="large"
                  icon="mail"
                  iconPosition="left"
                  type="text"
                  placeholder="adresse@email.fr"
                  error={
                    !state.loginValidation && state.login.length !== 0 && 'Adresse email incorrecte'
                  }
                  value={state.login}
                  onChange={(e) => handleInputChange(e.currentTarget.value, 'login')}
                />
              </Form.Field>
              <Form.Field>
                <label>Mot de passe</label>
                <Form.Input
                  autoComplete="current-password"
                  size="large"
                  icon="key"
                  iconPosition="left"
                  type="password"
                  placeholder="Mot de passe"
                  error={
                    !state.passwordValidation &&
                    state.password.length !== 0 &&
                    'Mot de passe incorrect'
                  }
                  onChange={(e) => handleInputChange(e.currentTarget.value, 'password')}
                />
              </Form.Field>
              {state.loginError.length !== 0 && (
                <div className="text-center">
                  <Label color="red" horizontal>
                    {state.loginError}
                  </Label>
                  <br />
                </div>
              )}
              <Button
                onClick={handleLogin}
                size="medium"
                disabled={
                  !(state.loginValidation && state.passwordValidation && !state.loginInProgress)
                }
                color={state.loginValidation && state.passwordValidation ? 'blue' : 'teal'}
                type="submit"
                icon="key"
                label="Connexion"
                labelPosition="right"
              />
            </Card.Description>
          </Card.Content>
          <Button.Group basic>
            <Button as={Link} to="/forgotpassword">
              <Header as="h5">Mot de passe oublié</Header>
            </Button>
            <Button as={Link} to="/register">
              <Header as="h5">Demander un accès</Header>
            </Button>
          </Button.Group>
        </Card>
        <br />
      </Form>
      <Popup
        content="Reçevoir un lien unique de connexion par email"
        disabled={state.loginValidation && state.passwordValidation}
        position="bottom center"
        wide
        trigger={
          <Button
            onClick={handleSwitchLoginType}
            size="large"
            color="purple"
            icon="magic"
            label="Connexion via Magic Link"
            labelPosition="right"
          />
        }
      />
    </div>
  );

  const buildMagicLoginBlock = (
    <div>
      <Form>
        <Card className="transparent-table" fluid>
          <Card.Content>
            <Card.Description>
              {!state.magicLoginPending && (
                <Header as="h1" color="teal" textAlign="center">
                  <span className="desktop-only">Bonjour et bienvenue sur </span>
                  {state.siteName}
                </Header>
              )}
              {state.magicLoginPending ? (
                <>
                  {state.magicLoginSent && (
                    <Header as="h2">
                      <Icon name="mail" /> Email de connexion envoyé à {state.login}
                    </Header>
                  )}
                  <Loader active inline="centered" size="medium" />
                  {state.magicLoginSent && (
                    <h5 className="text-center text-dark">
                      En attente de validation du lien (valide 5 minutes)
                    </h5>
                  )}
                </>
              ) : (
                <Form.Field>
                  <label>Email</label>
                  <Form.Input
                    autoComplete="email"
                    size="large"
                    icon="mail"
                    iconPosition="left"
                    type="text"
                    placeholder="adresse@email.fr"
                    value={state.login}
                    error={
                      !state.loginValidation &&
                      state.login.length !== 0 &&
                      'Adresse email incorrecte'
                    }
                    onChange={(e) => handleInputChange(e.currentTarget.value, 'login')}
                  />
                </Form.Field>
              )}
              {state.loginError.length !== 0 && (
                <div className="text-center">
                  <Label color="red" horizontal>
                    {state.loginError}
                  </Label>
                  <br />
                </div>
              )}
              {!state.magicLoginPending && (
                <Button
                  onClick={handleMagicLogin}
                  size="medium"
                  disabled={!state.loginValidation}
                  color={state.loginValidation ? 'purple' : 'teal'}
                  type="submit"
                  icon="magic"
                  label="Envoyer un magic link"
                  labelPosition="right"
                />
              )}
            </Card.Description>
          </Card.Content>
        </Card>
      </Form>
      {!state.magicLoginPending && (
        <>
          <br />
          <br />
          <Button
            onClick={handleSwitchLoginType}
            size="large"
            color="blue"
            icon="key"
            label="Connexion via mot de passe"
            labelPosition="right"
          />
          <br />
        </>
      )}
    </div>
  );

  return state.pending ? (
    <Navigate to="/pending" />
  ) : state.banned ? (
    <Navigate to="/banned" />
  ) : state.error ? (
    <Navigate to="/unavailable" />
  ) : (
    <BackgroundRandomizer>
      <NavBarGuest />
      <Grid
        className="no-margin-top"
        textAlign="center"
        style={{ height: '100vh' }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 800 }}>
          {state.serverStatus && state.loginType === 'password' && buildPasswordLoginBlock}
          {state.serverStatus && state.loginType === 'magiclink' && buildMagicLoginBlock}
          {!state.serverStatus && (
            <div>
              <h4>
                <Icon name="exclamation triangle" color="orange" /> La plateforme n&apos;est pas
                accessible pour le moment
                <br /> Nouvelle tentative{' '}
                {state.retryTick !== 0 ? (
                  <>
                    dans <span className="">{state.retryTick}</span> secondes...
                  </>
                ) : (
                  'en cours...'
                )}
              </h4>
            </div>
          )}
          {state.messageVisible && (
            <Segment inverted color="orange">
              En raison d&apos;un changement de serveur, le site sera partiellement inaccessible
              durant ces X prochains jours.
            </Segment>
          )}
          <Header as="h4" color="teal">
            v. {buildId}
          </Header>
        </Grid.Column>
      </Grid>
    </BackgroundRandomizer>
  );
}

export default LoginPage;
