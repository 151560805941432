import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Navigate, useParams } from 'react-router';
import { ToastContainer } from 'react-toastify';
import {
  Button,
  Dimmer,
  Form,
  Grid,
  Header,
  Icon,
  Loader,
  Segment,
  Select,
} from 'semantic-ui-react';
import { getUserProperties, setUserProperties } from '../services/Settings';

import NavBarAuth from '../components/NavBar/NavBarAuth';
import { getRole, getUserId } from '../services/Auth';
import BackgroundRandomizer from '../components/Tools/BackgroundRandomizer';
import { notifError } from '../components/Tools/NotificationToasts';
import InactivityHandler from '../components/Tools/InactivityHandler';
import { getLocalServerConfig } from '../services/Server';

const roleTypes = [
  { key: -1, value: -1, text: 'Bloqué' },
  { key: 0, value: 0, text: 'En attente validation' },
  { key: 1, value: 1, text: 'Utilisateur standard' },
  { key: 2, value: 2, text: 'Superadmin' },
];

const displayTypes = [
  { key: 'group', value: 'group', text: 'Groupé par âge, dossier en tête, A-Z' },
];

function SettingsEditUserPage() {
  const { id } = useParams();
  const siteName = getLocalServerConfig().siteName || 'Ergo';
  const [state, setState] = useState({
    item: {},
    loading: true,
    error: false,
    reason: '',
  });

  const getItem = useCallback(() => {
    getUserProperties(id)
      .then((response) => {
        if (response.status === 200 && response.data.success)
          setState({
            loading: false,
            item: response.data.result,
            error: false,
            reason: '',
          });
        else
          setState({
            loading: false,
            item: {},
            error: true,
            reason:
              response.status +
              (response.data.message !== undefined ? ` ${response.data.message}` : ''),
          });
      })
      .catch((e) => {
        setState({
          loading: false,
          item: {},
          error: true,
          reason: e.message,
        });
      });
  }, [id]);

  useEffect(() => {
    document.title = `${siteName} | Modifier l'utilisateur`;
    getItem();
  }, [getItem]);

  const handleInputChange = (key, val) => {
    const { item } = state;
    item[key] = val;
    setState((prevState) => ({ ...prevState, ...item }));
  };

  const handleSave = () => {
    setUserProperties({
      user_id: state.item.id,
      nickname: state.item.nickname,
      role: state.item.role,
      display_type: state.item.display_type,
      notification_token: state.item.notification_token,
    })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          setState((prevState) => ({ ...prevState, redirect: true }));
        } else {
          console.log(response.data);
          notifError(`Echec de l'enregistrement: ${response.data}`);
        }
      })
      .catch((e) => {
        console.log(e.message);
        notifError(`Echec de l'enregistrement: ${e.message}`);
      });
  };

  return (
    <InactivityHandler>
      <BackgroundRandomizer>
        {state.redirect && <Navigate to={getRole() > 1 ? '/settings' : '/devices'} />}
        <div className="box">
          <NavBarAuth />
          <ToastContainer />
          <Grid
            textAlign="center"
            style={{ overflow: 'scroll', height: '100vh' }}
            verticalAlign="middle"
          >
            <Grid.Column style={{ maxWidth: 600 }}>
              <Segment className="transparent-table">
                <Header as="h2" color="teal" icon textAlign="center">
                  <Icon name="pencil" circular />
                  Modification de l&apos;utilisateur {state.item.name}
                </Header>
                {state.error ? (
                  <Segment color="red">
                    <span>
                      Une erreur est survenue : {state.reason}
                      <br />
                    </span>
                  </Segment>
                ) : null}
                {state.loading ? (
                  <Segment>
                    <Dimmer active inverted>
                      <Loader active size="small" />
                    </Dimmer>
                    <span>
                      <br />
                    </span>
                  </Segment>
                ) : (
                  <Form>
                    <Form.Field>
                      <label>Pseudo sur {siteName}</label>
                      <input
                        aria-labelledby="Surnom"
                        label="Surnom"
                        placeholder="Surnom"
                        name="nickname"
                        autoComplete="new-password"
                        value={state.item.nickname}
                        onChange={(val) => {
                          handleInputChange('nickname', val.target.value);
                        }}
                      />
                    </Form.Field>
                    <Form.Field>
                      <label>Email</label>
                      {state.item.email}
                    </Form.Field>
                    {getRole() > 1 && (
                      <Form.Field>
                        <label>Rôle</label>
                        Le changement de rôle nécessitera une reconnexion de l&apos;utilisateur
                        <Select
                          name="Rôle"
                          placeholder="Rôle"
                          options={roleTypes}
                          value={state.item.role}
                          disabled={state.item.id === getUserId()}
                          onChange={(ev, val) => {
                            handleInputChange('role', val.value);
                          }}
                        />
                      </Form.Field>
                    )}
                    <Form.Field>
                      <label>Mode d&apos;affichage du contenu des répertoires</label>
                      <Select
                        name="Mode d'affichage"
                        placeholder="Mode d'affichage"
                        options={displayTypes}
                        value={state.item.display_type}
                        onChange={(ev, val) => {
                          handleInputChange('display_type', val.value);
                        }}
                        disabled
                      />
                    </Form.Field>
                    <Form.Field>
                      <label>Topic de notification Ntfy</label>
                      <input
                        placeholder="xFjhr..................."
                        name="notification_token"
                        autoComplete="new-password"
                        value={
                          state.item.notification_token !== null
                            ? state.item.notification_token
                            : ''
                        }
                        onChange={(val) => {
                          handleInputChange('notification_token', val.target.value);
                        }}
                      />
                    </Form.Field>
                    <Form.Field>
                      <label>Date d&apos;inscription</label>
                      {new Date(state.item.created_at).toLocaleString('fr-FR', {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                      })}
                    </Form.Field>
                    <Form.Field>
                      <label>Dernière modification / connexion</label>
                      {new Date(state.item.updated_at).toLocaleString('fr-FR', {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                      })}
                    </Form.Field>
                  </Form>
                )}
              </Segment>
              <Button
                size="large"
                label="Enregistrer"
                color="teal"
                labelPosition="right"
                icon="save"
                onClick={handleSave}
              />
              <Button
                label="Annuler"
                labelPosition="right"
                size="large"
                icon="close"
                as={Link}
                to={getRole() > 1 ? '/settings' : '/devices'}
              />
            </Grid.Column>
          </Grid>
        </div>
      </BackgroundRandomizer>
    </InactivityHandler>
  );
}

export default SettingsEditUserPage;
